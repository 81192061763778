import React, {useRef, useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Thin, UserModel} from '../../types';

import Modal from '../../components/Modal';
import SubmitButton from '../../components/SubmitButton';
import useModal from '../../hooks/useModal';
import submitReport from '../../services/actions/report';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFlag} from '@fortawesome/free-solid-svg-icons';

interface ReportButtonProps {
    reportee: Thin<UserModel>
}

export const ReportButton: React.FC<ReportButtonProps> = ({ reportee }) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [status, setStatus] = useState<{
        success: boolean,
        message: string,
    }>();

    const [successModalVis, setSuccessModalVis] = useModal(
        <Modal
            doNotHideOnClick={[buttonRef]}
            key={`report-${reportee?.id}`}
        >
            <div className="report-modal">
                <div className="report-modal-body">
                    <h2 className="report-modal-title">REPORT RECEIVED</h2>
                    <div>
                        Thank you for your report. We will review your report and take action if necessary.
                    </div>
                    <SubmitButton
                        onClick={() => {
                            setSuccessModalVis(false);
                        }}
                        style={{
                            backgroundColor: 'lightgrey',
                            marginTop: '10px'
                        }}
                    >
                        Close
                    </SubmitButton>
                </div>
            </div>
        </Modal>
    );

    const [, setModalVis] = useModal(
        <Modal
            key={`report-${reportee?.id}-modal`}
            doNotHideOnClick={[buttonRef]}
        >
            <div className="report-modal">
                <div className="report-modal-body">
                    <h2 className="report-modal-title">REPORT USER: {reportee ? reportee.username : ''}</h2>
                    <div>
                        Reasons for reporting a user may include, but are not limited to:
                        <ul>
                            <li>Discrimination</li>
                            <li>Spam</li>
                            <li>Harassment</li>
                            <li>Inappropriate Content</li>
                        </ul>
                    </div>
                    <Formik
                        initialValues={{
                            reason: '',
                        }}
                        validationSchema={Yup.object({
                            reason: Yup.string()
                                .required('Please state a reason.')
                                .min(50, 'Reason must be at least 50 characters long.')
                        })}
                        onSubmit={values => {
                            submitReport(values.reason, reportee)
                                .then((res) => {
                                    if (res.tag === 'OK') {
                                        setSuccessModalVis(true);
                                        setStatus(undefined);
                                    }
                                    else {
                                        if (res.error?.statusCode === 400) {
                                            setStatus({
                                                success: false,
                                                message: 'You have already reported this user.',
                                            });
                                        } else {
                                            setStatus({
                                                success: false,
                                                message: 'Something went wrong :( Please try again later.',
                                            });
                                        }
                                    }
                                });

                        }}
                        onReset={() => {
                            setStatus(undefined)
                            setModalVis(false);
                        }}
                    >
                        <Form
                            onChange={() => {
                                setStatus(undefined);
                            }}>
                            <div className="report-modal-reason">
                                <Field
                                    name="reason"
                                    id="reason"
                                    component="textarea"
                                    placeholder="Please tell us why you are reporting this user."
                                    rows={5}
                                />
                                <ErrorMessage name="reason" render={
                                    msg => <div className="error-message">{msg}</div>
                                } />
                            </div>
                            <SubmitButton
                                type="reset"
                                style={{
                                    backgroundColor: 'lightgrey',
                                }}
                            >
                                Cancel
                            </SubmitButton>
                            <SubmitButton type="submit">
                                Report
                            </SubmitButton>
                            {status && <div className='error-message'>
                                {status.message}
                            </div>}
                        </Form>
                    </Formik>
                </div>
            </div>
        </Modal>
    );

    return <button
        className="report-button"
        data-testid="report-button"
        onClick={() => setModalVis(true)}
        ref={buttonRef}
    >
        <FontAwesomeIcon icon={faFlag} />
    </button>
}

export default ReportButton;