import React from 'react';

interface User {
    username?: string,
    email?: string,
    name?: string,
    bio?: string
}

interface AccountInfoFieldProps {
    label: string,
    value: string | undefined
}

const AccountInfoField: React.FC<AccountInfoFieldProps> = ({ label, value }) => {
    return <div className="account-info-field">
        <label className="account-info-field-label">{label}</label>
        <div className="account-info-field-value">
            {value}
        </div>
    </div>
}

const AccountInfo: React.FC<User> = ({ ...user }) => {
    return <div className="account-info-container">
        <AccountInfoField label="USERNAME" value={user.username} />
        <AccountInfoField label="EMAIL" value={user.email} />
        <AccountInfoField label="NAME" value={user.name} />
    </div>
}

export default AccountInfo;