import React from 'react';
import Display from '../../components/Display';

const FitgHelpDisplay: React.FC = () => (
    <Display className="help" title="HELP">
        <div className="help-body">
            {'As you fill in this form, a question preview will update in real-time! How handy :)'}
            <br />
            {'The section called \'body\' is a little trickier though... You have to enter a special '}
            {'type of formatted text. It\'s not that bad though! Just wrap sections that you want '}
            {'displayed as slots in curly braces. Like this:'}
            <div className="code-block">
                {'a byte has {8} bits, while a nibble has {4}.'}
            </div>
            {'Enter the above as your body, and see what you get!'}
        </div>
    </Display>
)

export default FitgHelpDisplay;