import React from 'react';
import LabelledField from '../../components/LabelledField';
import SubmitButton from '../../components/SubmitButton';

const MetaFields: React.FC = () => (
    <>
        <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)', margin: '0px 0px 10px 0px' }} />
        <LabelledField
            name="topic"
            labelText="Topic Name"
            labelSubtext={'Enter a valid topic identifier'}
            placeholder="Topic"
            className="monospace-input"
        />
        <LabelledField
            name="tags"
            labelText="Tags"
            labelSubtext={'Enter tags, separated by spaces'}
            placeholder="Tags"
            className="monospace-input"
        />
        <SubmitButton className='create-button'>Create</SubmitButton>
    </>
)

export default MetaFields;