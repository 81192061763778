import axios from "axios";
import {DeleteReportParams, Response} from "../../types";
import {apiUri, getDefaultAxiosConfig, queryParamsToString} from "../axiosUtils";

/**
 * 
 * @param params object with repoerterId and reporteeId
 * @returns ApiResult with success or failure
 */
export async function deleteReport(params: DeleteReportParams) {
    const { data } = await axios.delete<Response<void>>(
        apiUri('report', queryParamsToString(params)),
        getDefaultAxiosConfig<void>()
    );

    return Response.assumeSuccess(data);
}