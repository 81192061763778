import React from 'react';

export type SetDelayedStateAction<T> = (value: T) => void; 

/**
 * This mimics `useState` but returns a setter that can take an optional
 * delay as argument. If no delay is set, this behaves exactly like `useState`,
 * if a delay is set, the value is only updated after `delay` has elapsed.
 * Any interrupting set calls will override previously buffered calls.
 * 
 * ```ts
 * const [state, setDelayedState] = useDelayedState(0);
 * setDelayedState(() => 15, 100);
 * setDelayedState(() => 5, 50); // probably cancels the above set
 * // we can expect `state` to be `5` after 50ms and it should never be
 * // 15. We can't guarantee this though.
 * ```
 * 
 * Currently, you always have to pass a function to the setter, I am not smart
 * enough to know how React is able to distinguish between setter functions
 * and values, so I am not allowing it
 * 
 * TODO: tests -- THIS IS BROKEN
 */
function useDelayedState<T>(initial: T, delayInMs: number = 0): [T, SetDelayedStateAction<T>] {
    const [state, setState] = React.useState<T>(initial);
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout>();

    return [state, (value: T) => {
        if (timeoutId){
            clearTimeout(timeoutId);
        }
        setTimeoutId(setTimeout(() => {
            setState(value)
        }, delayInMs))
        
    }];
}   

export default useDelayedState;