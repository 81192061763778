import React from 'react';

const LoginSignupFormTitle: React.FC<React.PropsWithChildren> = ({ children }) =>
    <>
        <div className="login-signup-form-title-container">
            <div className="login-signup-form-title-text">
                {children}
            </div>
        </div>
    </>;


export default LoginSignupFormTitle;