import {Params} from 'react-router-dom';
import React from 'react';

export const PathContext = React.createContext<PathMeta>({
    getTitle: () => 'N/A',
})

export interface PathMeta {
    /** Gets the path name -- might need to use kwargs, hence why this is a function */
    getTitle: (location: Readonly<Params<string>>) => string,
}