import axios from "axios";
import {apiUri, getDefaultAxiosConfig, queryParamsToString} from "../axiosUtils";
import {GetSearchQueryParams, Response, SearchResponseData} from "../../types";

/**
 * 
 * @param key The keyword to search for
 * @param skip Number of results to skip
 * @param take Number of results to take
 * @returns ApiResult with lists of users, questions and topics returned by server
 */
export async function getSearchResults(params: GetSearchQueryParams) {
    const { data } = await axios.get<Response<SearchResponseData>>(
        apiUri('search', queryParamsToString(params)),
        getDefaultAxiosConfig<void>()
    );

    return Response.assumeSuccess(data);
}