import axios from "axios";
import {
    ApiResult,
    ErrorResponseBody,
    PaginatedQueryParams,
    Response,
    Result,
    TopicAppealModel,
    TopicAppealResponseData
} from "../../types";
import Serde from "../../types/serde";
import {requireProperties} from "../../util";
import {apiUri, getDefaultAxiosConfig, queryParamsToString} from "../axiosUtils";

/**
 * Get's all the topic appeals and filters out any incomplete topic appeals
 * @returns 
 */
export async function getTopicAppeals(
    query: PaginatedQueryParams
): Promise<ApiResult<{ topicAppeals: TopicAppealModel[], count: number }>> {
    const { data } = await axios.get<Response<TopicAppealResponseData>>(
        apiUri('topic-appeal') + queryParamsToString(query),
        getDefaultAxiosConfig<void>()
    );
    const result = Response.assumeSuccess(data);

    if (result.tag === 'ERR') {
        return Result.err(result.error);
    }
    if (!result.value?.appeals || !result.value?.count) {
        return Result.err(ErrorResponseBody.notFound());
    }
    const required = result.value.appeals
        .filter(appeal => requireProperties(appeal, TopicAppealModel.KEYS)) as Serde.DeAttr<TopicAppealModel, 'createdAt'>[];
    const deserialized = required
        .map(appeal => Serde.deserializeAttributes(appeal, ['createdAt']));

    return Result.ok({
        topicAppeals: deserialized,
        count: result.value.count,
    });
}