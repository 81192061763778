import axios from 'axios';
import {ApiResult, PostFollowTopicBody, Response} from '../../types';
import {apiUri, getDefaultAxiosConfig} from '../axiosUtils';

export async function followTopic(body: PostFollowTopicBody): Promise<ApiResult<void>> {
    const { data } = await axios.post<Response<void>>(
        apiUri('follow/topic'),
        body,
        getDefaultAxiosConfig(),
    );

    return Response.assumeSuccess(data);
}