import React from 'react';

/**
 * return the current window size, can be used as a dependency for an effect.
 * e.g...
 * 
 * ```
 * const [wWidth, wHeight] = useWindowDimensions();
 * return wWidth > 1000 ? <div>big!</div> : <div>small</div>;
 * ```
 */
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState([0, 0]);

    React.useEffect(() => {
        const setCurrent = () => {
            setWindowDimensions([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', setCurrent);
        setCurrent();
    
        return () => window.removeEventListener('resize', setCurrent);
    }, []);

    return windowDimensions;
}

export default useWindowDimensions;