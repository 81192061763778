import axios from "axios";
import React, {useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import useUserContext from '../../hooks/useUserContext';
import {apiUri, getDefaultAxiosConfig} from '../../services/axiosUtils';
import {ApiResult, Response, Thin, UserModel} from '../../types';
import {handleLogout} from "../../components/Header/UserPreview";
import './Account.scss';

import Modal from '../../components/Modal';
import SubmitButton from '../../components/SubmitButton';
import useModal from "../../hooks/useModal";

/**
 * makes delete request to /api/account to delete user account
 * @returns data = response from server = {success, message}
 */
async function deleteAccount(): Promise<ApiResult<Thin<UserModel>>> {
    const { data } = await axios.delete(
        apiUri('account'),
        getDefaultAxiosConfig()
    );
    return Response.assumeSuccess(data as Response<UserModel>);
}


const DeleteAccount: React.FC = () => {
    const buttonRef = useRef<HTMLDivElement>(null);

    const userContext = useUserContext();
    const navigate = useNavigate();

    const [status, setStatus] = useState<{
        success: boolean,
        message: string,
    }>();

    const [modalVis, setModalVis] = useModal(<Modal key="delete-account-modal" doNotHideOnClick={[buttonRef]}>
        <div className="delete-account-modal">
            <div className="delete-account-modal-body">
                Are you sure you want to delete your account?
                <br /> <br />
                Any questions and topics you have created will be deleted, except for topics
                that have another moderator.
            </div>
            <SubmitButton
                style={{
                    backgroundColor: 'lightgrey',
                }}
                onClick={() => {
                    setStatus(undefined)
                    setModalVis(false);
                }}
            >
                Cancel
            </SubmitButton>
            <SubmitButton
                style={{
                    backgroundColor: 'darkred',
                }}
                onClick={() => {
                    deleteAccount()
                        .then((res) => {
                            if (res.tag === 'OK') {
                                setModalVis(false);
                                handleLogout(userContext);
                                navigate('/');


                            }
                            else {
                                setStatus({
                                    success: false,
                                    message: 'Something went wrong. Please try again later.'
                                });
                            }
                        })
                }}
            >Confirm
            </SubmitButton>
            {status && <div
                className="status-message"
                style={{
                    color: status.success ? 'green' : 'red',
                }}
            >
                {status.message}
            </div>}
        </div>
    </Modal>);

    return <div className="delete-account-container">
        <div style={{
            margin: '0px 0px 20px 0px',
        }}>
            Any questions and topics you have created will be deleted.
        </div>
        <div
            onClick={() => {
                setModalVis(true);
                setStatus(undefined);
            }}
            ref={buttonRef}
        >
            <SubmitButton
                style={{
                    backgroundColor: 'darkred',
                }}
            >
                Delete Account
            </SubmitButton>
        </div>
    </div>

}

export default DeleteAccount;