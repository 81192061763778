import React from 'react';
import {UserContext} from '../../context';
import useUserContext from '../../hooks/useUserContext';
import {updateStatus} from '../../services/actions';
import {ApiResult, Thin, UserModel, UserResponseData, UserStatus} from '../../types';
import {requireProperties} from '../../util';
import Modal, {ModalProps} from '../Modal';

/**
 * Saves updated user status to context and local storage
 * @param user Currently logged in user
 * @param context userConetext
 */
export function updateUserInfoBrowser(user: UserModel, context: UserContext) {
    if (!context.loggedInUserMeta) {
        return;
    }
    context.updateLoggedInUser({ ...context.loggedInUserMeta,  user });
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('userMeta', JSON.stringify(context.loggedInUserMeta));
}

/**
 * @returns a list of statuses with onClicks to change status
 */
const StatusOptions: React.FC = () => {
    const userContext = useUserContext();
    const statuses: UserStatus[] = ["ONLINE", "AWAY", "DND", "OFFLINE"];

    /**
     * Sends request to change status and updates locally stored user
     * information on successful response
     * @param status Status to change to
     * // TODO: show error message if status could not be updated
     */
    async function handleStatusChange(status: UserStatus) {
        const res: ApiResult<Thin<UserResponseData>> = await updateStatus(
            status as UserStatus
        );

        if (res.tag === 'OK') {
            const user: Thin<UserModel> = res.value?.user;
            if (user) {
                const requiredUser: UserModel | null = requireProperties(user, UserModel.KEYS);
                if (requiredUser) {
                    updateUserInfoBrowser(requiredUser, userContext);
                }
            }
        } else {
        }
    };

    const StatusOption: React.FC<{ status: UserStatus, text: string }> = ({
        status,
        text,
    }) => (
        <div
            key={status}
            className='status-option'
            onClick={async () => { await handleStatusChange(status) }}
        >
            <div className="status-icon-positioner">
                <div className="status-icon" style={{ backgroundColor: UserStatus.color(status) }} />
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
            }}>
                <div className="status-label">{text}</div>
                {
                    userContext.loggedInUser?.status === status
                    ? <div>🐱</div>
                    : null
                }
            </div>
        </div>
    )
    
    return <div className="status-options">
        <StatusOption status="ONLINE" text="Online" />
        <StatusOption status="AWAY" text="Away" />
        <StatusOption status="OFFLINE" text="Offline" />
        <StatusOption status="DND" text="Do not Disturb" />
    </div>
};

/**
 * @returns Modal with Status options
 */
const StatusModal: React.FC<ModalProps> = ({ style, ...props }) => {
    return <Modal
        style={{ maxWidth: '300px', width: '100%' }}
        title="SET STATUS"
        {...props}
    >
        <StatusOptions />
    </Modal>
}

export default StatusModal;