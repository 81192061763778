import axios from "axios";
import {Response, Thin, UserModel} from "../../types";
import {apiUri, getDefaultAxiosConfig} from "../axiosUtils";

/**
 * makes a post request to /api/report to report a user
 * @param reason Reason for the report
 * @param reportee The user who is being reported
 * @returns data = response from server
 */
export default async function submitReport(reason: string, reportee: Thin<UserModel>) {
    const { data } = await axios.post<Response<void>>(
        apiUri('report'),
        { reason: reason, reporteeId: reportee?.id },
        getDefaultAxiosConfig()
    );

    return Response.assumeSuccess(data);
}