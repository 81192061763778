import React from 'react';
import Modal, {ModalProps} from '../Modal';
import Login from './Login';
import Signup from './Signup';
import Radio from '../Radio';

/**
 * 
 * @param param0 Modal props 
 * @returns A modal with login and signup components
 */
export const LoginSignupModal: React.FC<ModalProps> = ({ style, ...props }) => {
    const [modalContent, setModalContent] = React.useState(<></>);

    return <Modal
        style={{ minWidth: '10vw' }}
        {...props}
    >
        {modalContent}
        <div className="radio-positioner">
            <Radio
                options={['log-in', 'sign-up']}
                style={{ maxWidth: '200px' }}
                onChange={(state: string) => {
                    switch (state) {
                        case 'log-in':
                            setModalContent(<Login />);
                            break;
                        case 'sign-up':
                            setModalContent(<Signup />);
                            break;
                    }
                }}
            />
        </div>
    </Modal>;
}

export default LoginSignupModal;