import React from 'react';

/**
 * Acquire the width of an input field's content. To do this, we render a fixed position span with 
 * the inputs content in the top-left corner. Hence, we need to return a react node with this span
 * so we can render it. That's why the return type is
 * ```
 * [number, React.ReactNode | null]
 * ```
 * 
 * ## Example Usage
 * 
 * ```
 * const inputRef = React.useRef(null);
 * const [inputWidth, inputWidthMatchingSpan] = useInputWidth(inputRef);
 * 
 * return <>
 *     {inputWidthMatchingSpan}
 *     <input ref={inputRef} style={{ width: inputWidth }} />
 * </>
 * ```
 * 
 * Note that this is not guaranteed to always work. It has not been tested for inputs that change 
 * font-size or a variety of other niche use cases.
 * 
 * Also, you will notice some jitter -- this is because we need to 
 *    - wait for the input value to change
 *    - update the span component
 *    - compute the span's size
 *    - update the input component
 * 
 * We can't do this without re-rendering at least once. A workaround would be to `calc($inputWidth + 1.5ch)` 
 * or something on the input's width.
 */
export default function useInputWidth(ref: React.MutableRefObject<HTMLInputElement | null>): [number, React.ReactNode | null] {
    const spanRef = React.useRef<HTMLSpanElement>(null);
    const [span, setSpan] = React.useState<React.ReactNode>(<></>);
    const [width, setWidth] = React.useState(0);

    React.useEffect(() => {
        if (!ref.current) {
            return;
        }

        setSpan(
            <span 
                ref={spanRef}
                aria-hidden="true"
                style={{
                    opacity: 0,
                    pointerEvents: 'none',
                    position: 'fixed',
                    top: '50vh',
                    left: '50vw',
                    fontFamily: ref.current.style.fontFamily,
                    fontWeight: ref.current.style.fontWeight,
                    fontSize: ref.current.style.fontSize,
                }}
            >
                {ref.current.value.replace(/ /g, '\u00A0')}
            </span>
        );
    }, [ref.current, ref.current?.className, ref.current?.value]);

    React.useEffect(() => {
        if (!spanRef.current) {
            return;
        }
        setWidth(spanRef.current.offsetWidth || 0);
    }, [span]);

    return [width, span];
}