import React from 'react';
import ModalContext from '../context/ModalContext';
import {UseStateReturnType} from '../types';

/**
 * Accessible modal 
 * - injects modal into `<body>`
 * - `aria-hidden` tags on `<main>` and `<div id="modal">`
 * - `aria-describedby` 
 * 
 * If your `node`s are 'equal' then you'll need to add a `key` prop to them that is unique
 * Equal means that they are both the same component type
 */
export default function useModal(node: React.ReactNode): UseStateReturnType<boolean> {
    const {
        visHandles: [modalVis, setModalVis],
        modalContentHandles: [, setModalContent],
    } = React.useContext(ModalContext);

    return [modalVis, newModalVis => {
        setModalContent(<>{node}</>);
        setModalVis(newModalVis);
    }];
}