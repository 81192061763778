import {useContext} from "react";
import {UserContext} from "../context";


/**
 * ```
 * const userContext = useUserContext();
 * ```
 * Component must be a child of UserContextProvider to be able to access context
 * @returns object of type userContext 
 * context={loggedInUser, authToken, updateLoggedInUser, updateAuthToken}
 */
const useUserContext = () => {
    const context = useContext(UserContext);

    return context
};

export default useUserContext;