import React, {useEffect} from 'react';
import Collapsible from 'react-collapsible';
import './Account.scss';
import EditProfilePic from './EditProfilePic';
import AccountInfo from './AccountInfo';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';
import DeleteAccount from './DeleteAccount';
import {UserModel} from '../../types';
import useUserContext from '../../hooks/useUserContext';
import {useNavigate} from 'react-router-dom';
import DisplayPositioner from '../../components/DisplayPositioner';
import BadRequestErrorDisplay from '../../components/BadRequestErrorDisplay';

interface AccountFormDisplayProps {
    title: string,
}

type AccountFormTriggerProps = React.PropsWithChildren<{ opened: boolean }> & React.HTMLAttributes<HTMLButtonElement>;

const AccountFormTrigger: React.FC<AccountFormTriggerProps> = ({ children, opened, ...props }) => {
    return <button className="account-form-trigger" variant={opened ? 'opened' : 'closed'}>
        <div className="account-form-trigger-title">
            {children}
        </div>
        <div className="account-form-trigger-icon">
            {/* {opened ? '[ click to close ]' : '' } */}
        </div>
    </button>
}

const AccountFormDisplay: React.FC<React.PropsWithChildren<AccountFormDisplayProps>> = ({ children, title }) => {
    const [opened, setOpened] = React.useState(false);

    return <div className="account-form-display-container">
        <Collapsible
            transitionTime={150}
            onOpening={() => setOpened(true)}
            onClosing={() => setOpened(false)}
            trigger={
                <AccountFormTrigger opened={opened}>
                    {title}
                </AccountFormTrigger>
            }
        >
            <div className="account-form-display">
                {children}
            </div>
        </Collapsible>
    </div>;
}

export const AccountForm: React.FC<Omit<UserModel, 'password'>> = (user) => {
    return <>
        <div className="account-user-display-container">
            <EditProfilePic {...user} />
            <AccountInfo {...user} />
        </div>
        <div style={{ marginBottom: '40px' }}>
            <AccountFormDisplay title="EDIT PROFILE">
                <EditProfile {...user} />
            </AccountFormDisplay>
            <AccountFormDisplay title="CHANGE PASSWORD">
                <ChangePassword />
            </AccountFormDisplay>
            <AccountFormDisplay title="DELETE ACCOUNT">
                <DeleteAccount />
            </AccountFormDisplay>
        </div>
    </>
}


const Account: React.FC = () => {
    const userContext = useUserContext();
    const navigate = useNavigate()
    const user = userContext.loggedInUser;
    useEffect(() => {
        if (!user) {
            navigate('/');
        } 
        
    }, []);

    return <DisplayPositioner>{
        user ? <AccountForm {...user} /> 
            : <BadRequestErrorDisplay errorCode={418} />
    }</DisplayPositioner>
}

export default Account;
