import React from 'react';
import './Toggle.scss';

const Toggle: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({ ...props }) => (
    <div className="toggle-container">
        <input type="checkbox" className="toggle" {...props} />
        <div className="toggle-appearance">
            <div />
        </div>
    </div>
)

export default Toggle;