import React from 'react';
import {PaginatedQueryParams} from '../../types';

export type DepaginatedQueryParams<Q> = Omit<Q, 'skip' | 'take'> & Partial<PaginatedQueryParams>;

export type PaginatedProps<D, Q> = {
    count: number,
    children: (responseData: D) => React.ReactNode | string | React.ReactNode[] | null,
    get: (query: Q) => Promise<D>,
    pageIndex: number,
    resultsPerPage: number,
    queryParams: DepaginatedQueryParams<Q>,
}

/**
 * This component does not render anything to the DOM that you do not explicitly render
 * yourself. 
 * 
 * ## Example Usage
 * 
 * This example allows you to go through all questions you've completed with some navigation
 * buttons.
 * 
 * ```
 * <Paginated
 *     get={getQuestions}
 *     pageIndex={0}
 *     resultsPerPage={50}
 *     queryParams={{
 *         completed: 'Completed',
 *     } as DepaginatedQueryParams<GetQuestionQueryParams>} // you need to specify this for TS
 * >
 *     {(responseData: Partial<QuestionResponseData>) => responseData.map(question => (
 *         <Question fullQuestion={question} />
 *     ))}
 * </Paginated>
 * <button onClick={() => setPageIndex(i => i - 1)}>go back</button>
 * <button onClick={() => setPageIndex(i => i + 1)}>go forward</button>
 * ```
 */
const Paginated = <D, Q>({
    count,
    children,
    get,
    pageIndex,
    resultsPerPage,
    queryParams,
}: PaginatedProps<D, Q>) => {
    const getNodes = children;
    const [responseData, setResponseData] = React.useState<D | null>(null);

    React.useEffect(() => {
        if (pageIndex >= 0 && pageIndex * resultsPerPage < count) {
            get({
                ...queryParams,
                skip: resultsPerPage * pageIndex,
                take: resultsPerPage,
            } as Q).then(rd => {
                setResponseData(rd);
            });
        }
    }, [pageIndex, resultsPerPage, queryParams]);

    return <>
        {responseData ? getNodes(responseData) : null}
    </>;
}

export default Paginated;