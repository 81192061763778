import * as React from 'react';
import './DisplayStyle.scss';

// TODO: read https://www.typescriptlang.org/docs/handbook/declaration-merging.html
declare module 'react' {
    interface HTMLAttributes<T> {
        variant?: string;
    }
}

export type DisplayProps = React.PropsWithChildren<{
    variant?: 'default' | 'raised' | 'inset' | 'header',
    title?: string,
}> & React.HTMLAttributes<HTMLDivElement>;

export const RaisedDisplay: React.FC<Omit<DisplayProps, 'variant'>> = ({
    children,
    ...props
}) =>
    <Display variant="raised" {...props}>{children}</Display>;

export const InsetDisplay: React.FC<Omit<DisplayProps, 'variant'>> = ({
    children,
    ...props
}) =>
    <Display variant="inset" {...props}>{children}</Display>;

export const HeaderDisplay: React.FC<Omit<DisplayProps, 'header'>> = ({
    children,
    ...props
}) =>
    <Display variant="header" {...props}>{children}</Display>

/**
 * We use regions a lot in our designs, this is supposed to make this more 
 * consistent by providing some default styling etc.
 * 
 * - change `variant` to change the styling of this `Display`, default is just
 *   a clean outline. This module also contains some aliases, e.g. `RaisedDisplay`
 *   which sets `variant = 'raised'` so use those if you like!
 * 
 * If you specify your own `className`, default sizing will be omitted (as 
 * well as some other things potentially). If you want to explicitly override
 * any other styling, use the `style` prop -- it is applied last.
 */
const Display: React.FC<DisplayProps> = ({
    variant = 'default',
    className,
    children,
    title,
    ...props
}) => {
    if (!className) {
        className = 'display display-defaults';
    } else {
        className = 'display ' + className.trim();
    }

    return <div className={className} variant={variant} {...props}>
        {title && <div className="title">{title}</div>}
        {children}
    </div>
}

export default Display;