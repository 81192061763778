import {Link} from 'react-router-dom';
import React, {ReactNode} from 'react';
import './Reports.scss';
import {getReports, getUserByPk} from '../../services/loaders';
import {ReportModel, Result} from '../../types';
import useUserContext from '../../hooks/useUserContext';
import UserIcon, {UserIconProps, UserIconSuspense} from '../../components/UserIcon';
import Display from '../../components/Display';
import FancyPaginated from '../../components/Paginated/FancyPaginated';

export function reportId(reporterId: string, reporteeId: string): string {
    return `${reporterId}_${reporteeId}`;
}

export type ReportSummaryProps = {
    reporterId: string,
    reporteeId: string,
    reason: string,
    createdAt: string,
}

/**
 * 
 * @param Object an instance of ReportModel
 * @returns A card with the summary of the report
 */
export const ReportSummaryCard: React.FC<ReportSummaryProps> = ({
    reason, reporteeId, reporterId
}) => {
    const [reporterIcon, setReporterIcon] = React.useState<ReactNode>(<UserIconSuspense />);

    React.useEffect(() => {
        getUserByPk(reporteeId)
            .then(userResponseData => {
                setReporterIcon(
                    <UserIcon {...UserIconProps.fromFullUserResponseDataResult(userResponseData)} />
                )
            });
    }, [])

    return (
        <Link
            className="view-report-link"
            to={{ pathname: `/report/${reportId(reporterId, reporteeId)}` }}
        >
            <Display className="report-summary">
                <div className="report-id">
                    {reportId(reporterId, reporteeId)}
                </div>
                <div className="report-summary-details">
                    <div className="user-summary-container">
                        {reporterIcon}
                    </div>
                    {reason}
                </div>
            </Display>
        </Link>
    )
};

/**
 * @param filter Indicates whether reports should be filtered
 * by userId. Should be false for admin routes and true for 
 * non admin routes
 * @returns List of all reports as tsx
 */
export const Reports: React.FC<{
    adminView: boolean
}> = ({ adminView }) => {
    const userContext = useUserContext();

    const [count, setCount] = React.useState<number>(5);

    return (
        <FancyPaginated
            count={count}
            get={getReports}
            resultsPerPage={5}
            queryParams={
                adminView
                    ? {}
                    : { reporterIds: userContext.loggedInUser?.id }
            }
        >
            {result => {
                
                    const reports = Result.unwrapOr(result, {reports: [] as ReportModel[]}).reports;
                    if (result.tag === 'OK') {
                        setCount(result.value.count);
                    }
                    return <>
                        <Display variant="header">
                            <div className='reports-header'>
                                <h2>{!adminView ? 'MY REPORTS' : 'REPORTS'}</h2>
                                <p>View {!adminView ? 'my' : 'all'} sumbitted reports</p>
                            </div>
                        </Display>
                        {
                            reports && reports.map((report, i) => (
                                <div key={i} className='reports-map-div'>
                                    <ReportSummaryCard {...report} />
                                </div>
                            ))
                        }
                    </>;
                }
            }
        </FancyPaginated>
    )
};

export default Reports