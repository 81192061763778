import React from 'react';
import {sleep} from '../util';

export default function useAnimation(
    ref: React.MutableRefObject<HTMLElement | null>,
    className: string,
    duration: number
): () => void {
    return () => {
        ref.current?.classList.add(className);
        sleep(duration).then(() => {
            ref.current?.classList.remove(className);
        })
    }
}