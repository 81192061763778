import React from 'react';
import './SubmitTopicAppeal.scss';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import SubmitButton from '../../components/SubmitButton';
import {postTopicAppeal} from '../../services/actions/topicAppeal';
import LabelledField from '../../components/LabelledField';
import Display from '../../components/Display';
import HoverDropDown, {HoverDropDownDisplay} from '../../components/HoverDropDown';
import DisplayPositioner from '../../components/DisplayPositioner';
import {UseStateReturnType} from '../../types';
import {sleep} from '../../util';

type Trilean = boolean | undefined

type SubmitTopicAppealFormProps = {
    isValidUriHandles: UseStateReturnType<Trilean>,
    uriHandles: UseStateReturnType<string>,
    onSubmit: (_: {
        name: string,
        icon: string,
        description: string,
        reason: string,
    }) => void,
}

const SubmitTopicAppealForm: React.FC<SubmitTopicAppealFormProps> = ({
    isValidUriHandles: [isValidUri, setIsValidUri],
    uriHandles: [uri, setUri],
    onSubmit
}) => (
    <Formik
        initialValues={{
            name: '',
            icon: '',
            description: '',
            reason: '',
        }}
        validationSchema={Yup.object({
            name: Yup.string()
                .required('Enter a topic name')
                .min(3, 'Must be cat least 3 chararacters pwease')
                .matches(/^[a-zA-Z0-9_\-]*$/, 'Cat-tains invalid characters'),
            icon: Yup.string()
                .required('choose an icon')
                .test('is-valid-icon', 'Icon is not valid', () => isValidUri),
            description: Yup.string()
                .required('Enter a description for the topic'),
            reason: Yup.string()
                .required('Why is this topic needed???')
        })}
        onSubmit={onSubmit}
        validate={values => {
            if (values.icon !== uri) {
                setIsValidUri(undefined);
                sleep(500).then(() => {
                    setIsValidUri(ivu => {
                        if (ivu === undefined) {
                            return true;
                        }
                        return ivu;
                    })
                })
            }
            setUri(values.icon);
            
        }}
    >
        {({ values }) => (
            <Form className=''>
                <LabelledField
                    name="name"
                    labelText="Name"
                    labelSubtext="A short, unique identifier for this topic"
                    placeholder="Cats"
                />

                <LabelledField
                    as="textarea"
                    name="description"
                    labelText="Description"
                    labelSubtext="Describe what this topic is all about"
                    placeholder="meow meow meow... meow meow... meowmeowmeow"
                />
                <LabelledField
                    name="icon"
                    labelText="Icon url"
                    labelSubtext="A valid URI for this topic's image"
                    placeholder="http://www.educat.dev/example.jpg"
                    fieldIcon={
                        <HoverDropDown
                            className="icon-url-field-icon"
                            content={
                                <HoverDropDownDisplay justify="left">
                                    <img
                                        src={uri}
                                        onError={e => {
                                            e.preventDefault();
                                            setIsValidUri(false);
                                        }}
                                    />
                                </HoverDropDownDisplay>
                            }
                        >
                            <div
                                className="validity-indicator"
                                variant={(() => {
                                    switch (isValidUri) {
                                        case false:
                                            return 'invalid';
                                        case undefined:
                                            return 'maybe-invalid';
                                        case true:
                                            return 'valid';
                                    }
                                })()}
                            />
                        </HoverDropDown>
                    }
                />
                <LabelledField
                    as="textarea"
                    name="reason"
                    labelText="Reason"
                    labelSubtext="Information for the admin evaluating your appeal about why this topic is needed"
                    placeholder="meowmeow meow meow meow... meow meow!"
                />
                <SubmitButton>Submit</SubmitButton>
            </Form>
        )}
    </Formik>
)

type ValuesType = {
    name: string;
    icon: string;
    description: string;
    reason: string;
}

/**
 * @returns Form with field sumbit a topic appeal
 */
const SubmitTopicAppeal: React.FC = () => {
    const [isValidUri, setIsValidUri] = React.useState<Trilean>(true);
    const [uri, setUri] = React.useState('');
    const [submitSuccess, setSubmitSuccess] = React.useState(false);
    const [submitMsg, setSubmitMsg] = React.useState('');

    return <DisplayPositioner className="submit-topic-appeal-page">
        <Display variant="header">
            <h2 className="submit-topic-appeal-title">SUBMIT TOPIC APPEAL</h2>
        </Display>
        {
            submitMsg !== ''
                ? <Display className={'submit-message-display ' + (submitSuccess ? 'success' : 'failure')}>
                    {submitMsg}
                </Display>
                : null
        }
        <Display>
            <SubmitTopicAppealForm
                isValidUriHandles={[isValidUri, setIsValidUri]}
                uriHandles={[uri, setUri]}
                onSubmit={async values => {
                    const res = await postTopicAppeal({
                        name: values.name,
                        topicIcon: values.icon,
                        description: values.description,
                        reason: values.reason
                    });
                    if (res.tag === 'OK') {
                        setSubmitSuccess(true);
                        setSubmitMsg('Appeal submitted!');
                    } else {
                        if (res.error?.fields![0][0] === 'name') {
                            setSubmitMsg(`Topic with name '${values.name}' already exists`);
                        } else {
                            setSubmitMsg(`${res.error?.message!}: ${res.error?.fields}`);
                        }
                        setSubmitSuccess(false);
                    }
                }}
            />
        </Display>
    </DisplayPositioner>
}

export default SubmitTopicAppeal;
