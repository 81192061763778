import {faPaperclip} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import Modal, {ModalProps} from '../Modal';
import './ShareModal.scss';

type ShareElement = 'profile' | 'report' | 'question'

type ShareModalProps = {
    link: string,
    elementType: ShareElement
} & ModalProps;

/**
 * Returns a component which allows users to copy links
 * @param link The link you want to share
 * @param elementType Type of element you want to share
 * eg: Questions | Report | Profile etc 
 */
const ShareModal: React.FC<ShareModalProps> = ({
    link, elementType, style, ...props
}) => {
    const [copied, setCopied] = React.useState(false);

    function copyToClipboard() {
        navigator.clipboard.writeText(link);
        setCopied(true);
    }

    return (
        <Modal
            style={{ maxWidth: '700px', width: '100%' }}
            {...props}
        >
            <div className='share-modal-body'>
                <h2>Copy this link to share this {elementType} with others!</h2>
                <div className='share-elements'>
                    <FontAwesomeIcon className="paperclip" icon={faPaperclip} />
                    <div className='share-link'>{link}</div>
                    <button
                        className='copy-btn'
                        onClick={() => copyToClipboard()}
                        variant={copied ? 'copied' : 'not-copied'}
                    >{
                            copied
                                ? 'Copied!'
                                : 'Copy'
                        }</button>
                </div>
            </div>
        </Modal>
    )
};

export default ShareModal;