import Modal, {ModalProps} from '../Modal';
import SubmitButton from '../SubmitButton';
import './DeleteModal.scss';

export type DeleteModalProps = {
    cb: () => Promise<void>,
    elementType: string,
    modalKey: number | string,
} & ModalProps;

const DeleteModal: React.FC<DeleteModalProps> = ({
    cb, elementType, modalKey, style, ...props
}) => {
    return (
        <Modal 
            key={modalKey}
            style={{ maxWidth: '700px', width: '100%' }}
            {...props}
        >
            <div className='delete-modal-body'>
                <h2> Are you sure you want to delete this {elementType}?</h2>
                <div className='delete-options'>
                   <SubmitButton invert className='yes' onClick={() => cb()}>Yes</SubmitButton>
                   <SubmitButton className='no'>No</SubmitButton>
                </div>
            </div>
        </Modal>
    )
};

export default DeleteModal;