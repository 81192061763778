import React from 'react';
import {Field, FieldProps, useField} from 'formik';
import './LabelledField.scss';

export type LabelledFieldLabelProps = {
    labelText?: string,
    labelSubtext?: string,
    htmlFor?: string,
};

/**
 * Just the label part of the `LabelledField`, so that you can label things that are not textareas
 * or inputs with the same style.
 */
export const LabelledFieldLabel: React.FC<LabelledFieldLabelProps> = ({ labelText, labelSubtext, htmlFor }) => (
    labelText || labelSubtext
        ? <div className="labelled-field-label-container">
            {labelText ? <label className="label-text" htmlFor={htmlFor}>{labelText}</label> : <></>}
            {labelSubtext ? <div className="label-subtext">{labelSubtext}</div> : <></>}
        </div>
        : <></>
)

const Input: React.FC<FieldProps> = ({ field, form, ...props }) => {
    return <input {...field} {...form} {...props} />;
}

const Textarea: React.FC<FieldProps> = ({ field, form, ...props }) => {
    return <textarea {...field} {...form} {...props} />;
}

export type LabelledFieldProps = {
    name: string,
    id?: string,
    as?: 'textarea' | 'input',
    fieldIcon?: React.ReactElement<any, any> | null,
    monospace?: boolean,
} & React.InputHTMLAttributes<HTMLInputElement>
    & LabelledFieldLabelProps;

/**
 * TODO: docs
 * TODO: make textarea scale in height
 */
const LabelledField: React.FC<LabelledFieldProps> = ({ 
    labelText, 
    labelSubtext, 
    className, 
    fieldIcon,
    monospace = false,
    as = 'input',
    ...props 
}) => {
    const [, meta] = useField(props);

    const hasError = meta.touched && meta.error;

    return <div className="labelled-field-container">
        <LabelledFieldLabel {...{ labelText, labelSubtext, htmlFor: props.name}} />
        <div className={'field-container ' + className}>
            {fieldIcon}
            <Field
                className={
                    'field-inner ' 
                    + (fieldIcon ? 'with-field-icon ' : '')
                    + (monospace ? 'monospace ' : '')
                }
                variant={hasError ? 'error' : 'valid'}
                component={(() => {
                    switch (as) {
                        case 'input':
                            return Input;
                        case 'textarea':
                            return Textarea;
                    }
                })()}
                {...props}
            />
        </div>
        <div className="error" variant={hasError ? 'visible' : 'hidden'}>
            {meta.error || ''}
        </div>
    </div>;
}

export default LabelledField;