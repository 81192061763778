import axios from "axios";
import {Response, TopicAppealModel, TopicAppealResponseData} from "../../types";
import {apiUri, getDefaultAxiosConfig} from "../axiosUtils";

/**
 * 
 * @param body Object containing attributes needed to create appeal
 * @returns ApiResult containing data object or error sent by server
 */
export async function postTopicAppeal(body: Partial<TopicAppealModel>) {
    const { data } = await axios.post<Response<TopicAppealResponseData>>(
        apiUri('topic-appeal'),
        { ...body },
        getDefaultAxiosConfig<Partial<TopicAppealModel>>()
    );

    return Response.assumeSuccess(data);
}

/**
 * Sends a patch request to update the approved status of a topic appeal to approved
 * @param id the topic appeal id
 * @returns true if the request suceeds otherwise false
 */
export async function approveTopicAppeal(id: string): Promise<boolean> {
    const { data } = await axios.patch<Response<void>>(
        apiUri('topic-appeal', id),
        {},
        getDefaultAxiosConfig<void>()
    );

    const result = Response.assumeSuccess(data);
    if (result.tag === 'ERR') {
        return false;
    }
    return true;
}