import React from "react";
import './TopicChat.scss';
import {Link, useLocation, useParams} from "react-router-dom";
import Message from "./Message";
import useUserContext from "../../hooks/useUserContext";
import MessageBox from "./MessageBox";
import useSocket from "../../hooks/useSocket";
import Serde from "../../types/serde";
import {getTopicByFullname} from "../../services/loaders";
import {MessageModel, MessageResponseData} from "../../types";
import {faReply} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TopicChat: React.FC = () => {
    const location = useLocation();
    const userContext = useUserContext();

    const { topicName } = useParams<{ topicName: string }>();
    const [topic, setTopic] = React.useState(topicName);

    const { socket } = useSocket();
    const [messages, setMessages] = React.useState<MessageModel[]>([]);
    const [description, setDescription] = React.useState<string | null>(null);
    const [topicNotFound, setTopicNotFound] = React.useState<boolean>(false);

    const messagesEndRef = React.useRef<HTMLDivElement>(null);
    // set up topic
    React.useEffect(() => {
        if (topic && !topicNotFound) {
            socket?.emit('join', { roomName: topic });
        }
        socket?.on('message', m => {
            const mrd = m as MessageResponseData;
            const dm: MessageModel = Serde.deserializeAttributes<MessageModel, ['createdAt']>(mrd, ['createdAt']);
            setMessages((oldMessages) => [...oldMessages, { ...dm }]);
        });
    }, [socket]);

    // validate topic name
    React.useEffect(() => {
        if (topicName !== topic) {
            setTopic(topicName);
        }

        if (topic) {
            getTopicByFullname(topic).then((responseData) => {
                if (responseData.tag === 'OK' && topic) {
                    setDescription(responseData.value.description);
                    setTopicNotFound(false);
                } else {
                    setTopicNotFound(true);
                }
            })
        }
    }, [location]);

    // leave room when we change page
    React.useEffect(() => {
        return () => {
            socket?.emit('leave', { roomName: topic })
        };
    }, [location]);

    React.useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [messages]);
    return (
        <>
            {userContext.loggedInUser && description ?
                <div className="chat-page">
                    <div className="topic-messages">
                        <div className="message-page-header">
                            <div className="message-title">
                                <Link to={{ pathname: `/topics/${topicName}` }}>
                                    <FontAwesomeIcon icon={faReply} /> go back
                                </Link>
                                <h1>{topicName}</h1>
                            </div>
                        </div>
                        <div className="messages">
                            <div className="welcome-message">
                                <p>
                                    This is the chat for the {topicName} topic! Welcome and please be nice :)
                                </p>
                            </div>
                            {messages.map((message, index) => {
                                return (
                                    <Message key={index} {...message} userId={userContext.loggedInUser!.id} />
                                )
                            })}
                            <div ref={messagesEndRef} />
                        </div>
                        <MessageBox topicName={topicName} />
                    </div>
                    {/* https://stackoverflow.com/questions/37620694/how-to-scroll-to-bottom-in-react */}
                </div>
                : topicNotFound ? "not exist" : "loading"
            }
        </>
    );
}

export default TopicChat;