import React from 'react';
import {documentHeight} from '../util';

/**
 * A hook to allow you to run some logic when a user scrolls to the **bottom** of 
 * some element
 * @param [outerRef, innerRef] either `[document, window]` for window-based scrolling
 * or an outer/inner ref where outer is the element with `overflow-y: scroll` and inner
 * is a wrapper around all scrollable elements
 * @param tripwireHeight how high up the tripwire is placed -- this is in `ref`
 * heights. So if the full height is 3x the scrollable region height, then a
 * `tripwireHeight` of 0.5 would trigger 5/6ths of the way down.
 * 
 * ```
 * +----------------+ 
 * |    viewable    | 
 * +----------------+
 * |                |
 * |     hidden     |
 * |                |
 * |                | <---- trips when this is visible
 * +----------------+
 * ```
 * @param cb what should happen when we trip -- you probably want to move the scrollbar
 * up or something... you also probably want to `sleep` for a bit and use an `async` cb
 * here instead, the tripwire is reset after this cb returns
 * 
 * ## example usage
 * 
 * ```ts
 * const outerRef = React.useRef<HTMLDivElement | null>(null);
 * const innerRef = React.useRef<HTMLDivElement | null>(null);
 * 
 * useScrollTwipwire([outerRef, innerRef], 0.5, async () => {
 *              console.log('tripwire tripped!');
 *              await sleep(500);
 *              console.log('resetting tripwire...');
 * });
 * 
 * return <div style={{ overflowY: 'scroll' }} ref={outerRef}>
 *              <div ref={innerRef}>
 *                  {...}
 *              </div>
 * </div>
 * ```
 * 
 * // TODO: test, fix docs to include info about async being bad!
 */
export default function useScrollTwipwire<Outer extends HTMLElement, Inner extends HTMLElement>(
    [outerRef, innerRef]:
        [React.MutableRefObject<Outer | null>, React.MutableRefObject<Inner | null>]
        | [Document, Window],
    tripwireHeight: number,
    cb: () => Promise<void> | void,
) {
    type OuterRef = React.MutableRefObject<Outer | null>;
    type InnerRef = React.MutableRefObject<Inner | null>;
    
    const [tripped, setTripped] = React.useState(false);

    const outer = () => outerRef instanceof Document ? outerRef : outerRef?.current;
    const inner = () => innerRef instanceof Window ? innerRef : innerRef?.current;

    const shouldTrip = outerRef instanceof Document
        ? () => {
            const maxScrollBottom = documentHeight(document);
            const scrollBottom = (window.innerHeight + window.scrollY);
            return maxScrollBottom - window.innerHeight * tripwireHeight <= scrollBottom;
        }
        : () => {
            // safe because this must be the first overload 
            if (!(outerRef as OuterRef).current || !(innerRef as InnerRef).current) {
                return false;
            }

            // safe because of above guard
            const outerRefCurrent = (outerRef as OuterRef).current as Outer;
            const innerRefCurrent = (innerRef as InnerRef).current as Inner;

            // whew -- that's enough typescript! time for some logic
            const maxScrollBottom = innerRefCurrent.offsetHeight;
            const scrollBottom = outerRefCurrent.offsetHeight + outerRefCurrent.scrollTop;
            return maxScrollBottom - outerRefCurrent.offsetHeight * tripwireHeight <= scrollBottom;
        }

    React.useEffect(() => {
        const fn = () => {
            if (shouldTrip()) {
                setTripped(true);
            }
        };
        outer()?.addEventListener('scroll', fn);

        return () => outer()?.removeEventListener('scroll', fn);
    }, []);

    React.useEffect(() => {
        if (tripped) {
            const maybePromise = cb();
            if (maybePromise !== undefined) {
                maybePromise.then(() => setTripped(false));
            } else {
                setTripped(false);
            }
        } else {
            if (shouldTrip()) {
                setTripped(true);
            }
        }
    }, [tripped]);
}