import axios from "axios";
import {ApiResult, PatchQuestionRequestBody, Response} from "../../types";
import {apiUri, getDefaultAxiosConfig} from "../axiosUtils";

/**
 * Used currently only to change the upvotes/downvotes of a given question
 */
export async function patchQuestion(id: string, body: PatchQuestionRequestBody): Promise<ApiResult<void>> {
    const { data } = await axios.patch<Response<void>>(
        apiUri('question', id),
        body,
        getDefaultAxiosConfig()
    );

    return Response.assumeSuccess(data);
};

export async function deleteQuestion(id: string) {
    const { data } = await axios.delete<Response<void>>(
        apiUri('question', id),
        getDefaultAxiosConfig()
    );

    return Response.assumeSuccess(data);
}