import React from 'react';
import useModal from '../../hooks/useModal';
import ShareModal from '../ShareModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShareFromSquare} from '@fortawesome/free-solid-svg-icons';

const SmallShareButton: React.FC<{ questionId: string }> = ({ questionId }) => {
    const shareButtonRef = React.useRef<HTMLButtonElement | null>(null);
    const pathArray = window.location.href.split('/'); // TODO: fix
    const shareLink = `${pathArray[0]}//${pathArray[2]}/question/${questionId}`;
    const [, setShareModalVis] = useModal(
        <ShareModal
            key={`share-modal-${questionId}`}
            link={shareLink}
            elementType='question'
            doNotHideOnClick={[shareButtonRef]}
        />
    )

    return (
        <button className='small-button-container'
            onClick={() => setShareModalVis(true)}
            ref={shareButtonRef}
        >
            <FontAwesomeIcon icon={faShareFromSquare} />
        </button>
    )
}

export default SmallShareButton

