import {enumerate} from "../../util";
import {useDrag, useDrop} from "react-dnd";
import React, {forwardRef, useImperativeHandle} from 'react';
import {
    FitgQuestionBodyModelWithoutAnswer,
    FitgQuestionElementModelWithoutAnswer,
    FitgQuestionElementTag,
    UseStateReturnType
} from "../../types";

const ItemTypes = {
    FITG_BLOCK: 'fitg-block',
}

type BlockItem = {
    text: string,
};

type FitgInputProps = {
    key: string,
    index: number,
    draggingHandles: UseStateReturnType<string | null>,
    answersHandles: UseStateReturnType<(string | null)[]>,
};

const FitgInput: React.FC<FitgInputProps> = ({
    index,
    answersHandles: [answers, setAnswers],
    draggingHandles: [, setDragging],
}) => {
    const answer = answers[index];

    const [{ isOver }, dropRef] = useDrop(() => ({
        accept: ItemTypes.FITG_BLOCK,
        drop: () => {
            setDragging(value => {
                setAnswers(answers => {
                    if (value) {
                        answers[index] = value;
                    }
                    return answers;
                });
                return value;
            });
        },
        collect: (monitor) => {
            const item: BlockItem = monitor.getItem();
            if (item) {
                setDragging(item.text);
            }
            return {
                isOver: !!monitor.isOver(),
            }
        },
    }));

    return answer === null
        ? <div
            key={index.toString()}
            className="fitg-input"
            variant={isOver ? "over" : "not-over"}
            ref={dropRef}
        />
        : <div
            key={index.toString()}
            className="fitg-block"
            variant={isOver ? "over" : "not-over"}
            ref={dropRef}
        >
            {answer}
        </div>;
}

/**
 * return the number of elements that are INPUT types.
 */
function getInputCount(elements: FitgQuestionElementModelWithoutAnswer[]): number {
    return elements.filter(el => el.tag === FitgQuestionElementTag.INPUT).length;
}

const useElements = (
    body: FitgQuestionBodyModelWithoutAnswer
): [React.ReactNode[], UseStateReturnType<(string | null)[]>] => {
    const [answers, setAnswers] = React.useState(Array(getInputCount(body.elements)).fill(null));
    React.useEffect(() => {
        setAnswers(Array(getInputCount(body.elements)).fill(null));
    }, [body.elements]);

    const [dragging, setDragging] = React.useState<string | null>(null);

    let nodes: React.ReactNode[] = [];
    let inputIndex = 0;
    for (let i = 0; i < body.elements.length; ++i) {
        const el = body.elements[i];
        switch (el.tag) {
            case (FitgQuestionElementTag.TEXT):
                nodes.push(<div key={i.toString()} className="fitg-text">{el.text}</div>);
                break;
            case (FitgQuestionElementTag.INPUT):
                nodes.push(
                    <FitgInput
                        key={i.toString()}
                        index={inputIndex}
                        answersHandles={[answers, setAnswers]}
                        draggingHandles={[dragging, setDragging]}
                    />
                );
                inputIndex++;
                break;
        }
    }

    return [nodes, [answers, setAnswers]];
}

const FitgBlock: React.FC<{ text: string, key: string }> = ({
    text,
}) => {
    const [_, dragRef] = useDrag(() => ({
        type: ItemTypes.FITG_BLOCK,
        item: (): BlockItem => {
            return { text };
        },
    }), [text]);
    return <div
        className="fitg-block"
        ref={dragRef}
    >
        {text}
    </div>;
}

/**
 * Generate a keyed list of 'blocks' frmo the question body
 */
function useBlocks(body: FitgQuestionBodyModelWithoutAnswer): React.ReactNode[] {
    const generateNodes = (bv: string[]) => enumerate(bv)
        .map(([i, text]) => <FitgBlock key={i.toString()} text={text} />);

    const [nodes, setNodes] = React.useState<React.ReactNode[]>(generateNodes(body.blocks));
    React.useEffect(() => setNodes(generateNodes(body.blocks)), [body.blocks]);

    return nodes;
}

export type FillInTheGapsQuestionBodyProps = {
    body: FitgQuestionBodyModelWithoutAnswer,
};

export interface FillInTheGapsQuestionRef {
    getAnswer: () => (string | null)[] | null,
}

const FillInTheGapsQuestionBody = forwardRef<FillInTheGapsQuestionRef, FillInTheGapsQuestionBodyProps>(({
    body,
}, ref) => {
    const [elements, [answers,]] = useElements(body);
    const blocks = useBlocks(body);

    useImperativeHandle(ref, () => ({
        getAnswer: () => {
            if (answers.includes(null)) {
                return null;
            }
            return answers;
        }
    }));

    return <div className="fitg-body">
        <div className="fitg-elements">{elements}</div>
        <div className="fitg-blocks">{blocks}</div>
    </div>;
});

export default FillInTheGapsQuestionBody;