import React from 'react';
import Question from '../../components/Question';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {getQuestionById} from '../../services/loaders/';
import {Full, QuestionModelWithoutAnswer} from '../../types';
import './Question.scss';
import {useParams} from 'react-router-dom';
import BadRequestErrorDisplay from '../../components/BadRequestErrorDisplay';
import '../../styles/animations.scss';

const FullQuestion: React.FC = () => {
    const { id } = useParams();
    const [question, setQuestion] = React.useState<Full<QuestionModelWithoutAnswer> | null>(null)

    React.useEffect(() => {
        if (!id) {
            return;
        }

        getQuestionById(id).then(res => {
            if (res.tag === 'OK') {
                setQuestion(res.value);
            } else {
                setQuestion(null);
            }
        })
    }, []);

    return <>{
        id && question
            ? <DndProvider backend={HTML5Backend}>
                <div className='full-question-body'>
                    <div className='question-container'>
                        <Question fullQuestion={question} />
                    </div>
                </div>
            </DndProvider>
            : <BadRequestErrorDisplay errorCode={404} message="Question does not exist!" />
    }</>

};

export default FullQuestion;