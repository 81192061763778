import React from 'react';
import {requireProperties, tryGetProperty} from '../util';
import Serde from './serde';
import arrayShuffle from "array-shuffle";

export type UseStateReturnType<T> = [T, React.Dispatch<React.SetStateAction<T>>];
export type SetStateAction<T> = React.Dispatch<React.SetStateAction<T>>;

/**
 * Structure of the ApiResult
 */
export type Result<T, E> = {
    tag: 'OK',
    value: T,
} | {
    tag: 'ERR',
    error: E,
};

/**
 * Return type of functions which make api calls
 * to the backend
 */
export type ApiResult<T> = Result<T, ErrorResponseBody | null>;

export type StatusCode = 206 | 404 | 418 | 500 | 400;

/**
 * # `Result` API
 * 
 * A `Result<T, E>` is similar to Rust's `Result<T, E>` or Scala's `Either<T1, T2>`.
 * Rust doesn't handle errors 
 */
export namespace Result {
    export function ok<T, E>(value: T): Result<T, E> {
        return {
            tag: 'OK',
            value
        }
    }

    export function err<T, E>(error: E): Result<T, E> {
        return {
            tag: 'ERR',
            error
        }
    }

    export function isOk<T, E>(result: Result<T, E>): boolean {
        return result.tag === 'OK';
    }

    export function isErr<T, E>(result: Result<T, E>): boolean {
        return result.tag === 'ERR';
    }

    /**
     * change the value if it is OK, otherwise do nothing
     */
    export function map<T, E, U>(result: Result<T, E>, fn: (value: T) => U): Result<U, E> {
        if (result.tag === 'ERR') {
            return Result.err(result.error);
        } else {
            return Result.ok(fn(result.value));
        }
    }

    /**
     * unwrap the result or return a default value `or`
     */
    export function unwrapOr<T extends U, E, U>(result: Result<T, E>, or: U): U {
        if (result.tag === 'OK') {
            return result.value;
        } else {
            return or;
        }
    }
}

export type Thin<T> = Partial<T> | undefined;

/**
 * Require all first-level nested attributes, and all attributes
 */
export type Full<T> = { [key in keyof T]: Required<T[key]> };

// NOTES TODO: separate to new file
// every single type here ends with Model, unless it is an enum or enum-like type.
// this is to avoid any kind of conflict with props or components.
// tagged union tags always end with Tag

/**
 * required params for a paginated endpoint -- skip n, then take starting from n + 1 until n + 1 + take
 */
export interface PaginatedQueryParams {
    skip: number,
    take: number,
};

export interface PaginatedResponseData {
    count: number,
};

/**
 * Status options for changing status
 */
export type UserStatus = 'ONLINE' | 'AWAY' | 'DND' | 'OFFLINE';

export namespace UserStatus {
    /**
     * converts a status to a CSS color that represents this status,
     * e.g. `Online` will be roughly green
     */
    export function color(status: UserStatus): string {
        switch (status) {
            case 'ONLINE':
                return 'green';
            case 'AWAY':
                return 'orange';
            case 'OFFLINE':
                return 'grey';
            case 'DND':
                return 'red';
        }
    }
}

/**
 * User model as represented in the datatbase
 */
export interface UserModel {
    id: string,
    username: string,
    email: string,
    password: string,
    name: string,
    bio: string,
    profileIcon: string,
    status: UserStatus,
    isAdmin: boolean,
}

export namespace UserModel {
    export const KEYS_WITHOUT_PASSWORD: (
        'id' |
        'username' |
        'email' |
        'name' |
        'bio' |
        'profileIcon' |
        'status' |
        'isAdmin'
    )[] = [
            'id',
            'username',
            'email',
            'name',
            'bio',
            'profileIcon',
            'status',
            'isAdmin',
        ];

    export const KEYS: (keyof UserModel)[] = [
        'id',
        'username',
        'email',
        'name',
        'bio',
        'profileIcon',
        'status',
        'password',
        'isAdmin',
    ]
}

export type TinyTopicModel = {
    name: string,
    questionsAnswered: number
};

export type ExtendedUserResponseData = UserResponseData & {
    topics: TinyTopicModel[]
};

export namespace ExtendedUserResponseData {
    /**
     * Try convert this into an `ExtendedUserResponseData`, with full type checking in place, if invalid
     * return `null`
     */
    export function tryFromUserResponseData(ud: UserResponseData): ExtendedUserResponseData | null {
        if (ud.hasOwnProperty('topics')) {
            const ex = ud as ExtendedUserResponseData;
            if (
                ex.topics instanceof Array
                && ex.topics.filter(t => requireProperties(t, ['name', 'questionsAnswered'])).length === ex.topics.length
            ) {
                return ex;
            }
        }
        return null;
    }
}

export interface ReportModel {
    reporterId: string,
    reporteeId: string,
    reason: string,
    createdAt: string,
}

/**
 * User type which is returned as a result of search request.
 * Cannot use Partial<UserModel> as some fields need to be
 * guaranteed to appear
 */
export interface SearchUser {
    name: string,
    username: string,
    bio?: string,
    profileIcon?: string,
}

/**
 * Topic type which is returned as a result of search request.
 * Cannot use Partial<TopicModel> as some fields need to be
 * guaranteed to appear
 */
export interface SearchTopic {
    name: string,
    description?: string,
    createdAt: string,
}

/**
 * Question type which is returned as a result of search request.
 * Cannot use Partial<TopicModel> as some fields need to be
 * guaranteed to appear
 */
export interface SearchQuestion {
    title: string,
    topic: Partial<TopicModel>,
    author: Partial<UserModel>,
    createdAt: string,
    id: string,
}

/**
 * Topics as represented in the database
 */
export interface TopicModel {
    createdAt: Date,
    description: string,
    id: string,
    name: string,
    topicIcon: string,
    users: number,
    questions: number,
    following: boolean,
}

export namespace TopicModel {
    export const KEYS: (keyof TopicModel)[] = [
        'id',
        'name',
        'description',
        'createdAt',
        'users',
        'questions',
        'topicIcon',
        'following',
    ];
}

/**
 * Identifies whether a question is multiple choice or 
 * fill in the gaps type
 */
export enum QuestionBodyTag {
    MULTIPLE_CHOICE = 0,
    FILL_IN_THE_GAPS = 1,
}

export interface McQuestionBodyModel {
    tag: QuestionBodyTag.MULTIPLE_CHOICE,
    question: string,
    options: string[],
    answer: number[],
}

export namespace McQuestionBodyModel {
    export const KEYS: (keyof McQuestionBodyModel)[] = [
        'tag',
        'question',
        'options',
        'answer',
    ]

    export const KEYS_WITHOUT_ANSWER: (keyof McQuestionBodyModelWithoutAnswer)[] = [
        'tag',
        'question',
        'options',
    ]
}

export enum FitgQuestionElementTag {
    TEXT = 0,
    INPUT = 1,
}

export type FitgQuestionElementModel = {
    tag: FitgQuestionElementTag.TEXT,
    text: string,
} | {
    tag: FitgQuestionElementTag.INPUT,
    answer: string,
};

/**
 * Methods to format fill in the gaps questions sent by the server
 */
export namespace FitgQuestionElementModel {
    export function text(text: string): FitgQuestionElementModel {
        return {
            tag: FitgQuestionElementTag.TEXT,
            text,
        }
    }

    export function input(answer: string): FitgQuestionElementModel {
        return {
            tag: FitgQuestionElementTag.INPUT,
            answer,
        }
    }
}

export interface FitgQuestionBodyModel {
    tag: QuestionBodyTag.FILL_IN_THE_GAPS,
    elements: FitgQuestionElementModel[],
    blocks: string[],
}

export namespace FitgQuestionBodyModel {
    export const KEYS: (keyof FitgQuestionBodyModel)[] = [
        'tag',
        'elements',
        'blocks',
    ]
}

export namespace FitgQuestionBodyModel {
    /**
     * A simple filter on the blocks of this element, so that we return a list of 
     * all the blocks that would be required to make this question validatable
     * 
     * safety: this makes the explicit guarantee that only `self.elements` will be 
     * accessed -- this is only structured as such to indicate method scope
     */
    export function requiredBlocks(self: FitgQuestionBodyModel): string[] {
        const blocks = (self
            .elements
            .filter(el => el.tag === FitgQuestionElementTag.INPUT) as { answer: string }[])
            .map(el => el.answer);

        let result: string[] = [];
        new Set(blocks).forEach(block => {
            result.push(block);
        });

        return arrayShuffle(result);
    }
}

export type QuestionBodyModel = McQuestionBodyModel | FitgQuestionBodyModel;

export type McQuestionBodyModelWithoutAnswer = Omit<McQuestionBodyModel, 'answer'>;

// duplicate required for type inference
export type FitgQuestionElementModelWithoutAnswer = {
    tag: FitgQuestionElementTag.TEXT,
    text: string,
} | {
    tag: FitgQuestionElementTag.INPUT,
};

export type FitgQuestionBodyModelWithoutAnswer
    = Omit<FitgQuestionBodyModel, 'elements'> & { elements: FitgQuestionElementModelWithoutAnswer[] };

export type QuestionBodyModelWithoutAnswer = McQuestionBodyModelWithoutAnswer | FitgQuestionBodyModelWithoutAnswer;

export interface TagModel {
    tag: {
        name: string,
    }
}

export namespace TagModel {
    export function fromString(name: string): TagModel {
        return {
            tag: {
                name,
            }
        }
    }
}

/**
 * Questions as represented in the database
 */
export interface QuestionModel {
    id: string,
    title: string,
    body: Partial<QuestionBodyModel>,
    createdAt: Date,
    topicId: string,
    authorId: string,
    tags: TagModel[],
    rating: Rating,
    overallRating: number,
    difficulty: number,
    // score: number, 
}

export type QuestionModelWithoutAnswer = Omit<QuestionModel, 'body'> & { body: Partial<QuestionBodyModelWithoutAnswer> };

export namespace QuestionModel {
    export const KEYS: (keyof QuestionModel)[] = [
        'id',
        'title',
        'body',
        'createdAt',
        'topicId',
        'authorId',
        'tags',
        'overallRating',
        'difficulty',
        // 'score',
    ];

    export function makeFull(self: Partial<QuestionModelWithoutAnswer>): Full<QuestionModelWithoutAnswer> | null {
        let required: Required<QuestionModelWithoutAnswer> | null = requireProperties(self, KEYS);
        if (!required) {
            return null;
        };
        if (required.body.tag === QuestionBodyTag.FILL_IN_THE_GAPS) {
            // TODO: technically unsafe but fine for now
            if (requireProperties(required.body, FitgQuestionBodyModel.KEYS)) {
                return required as Full<QuestionModelWithoutAnswer>;
            }
        } else if (required.body.tag === QuestionBodyTag.MULTIPLE_CHOICE) {
            if (requireProperties(required.body, McQuestionBodyModel.KEYS_WITHOUT_ANSWER)) {
                return required as Full<QuestionModelWithoutAnswer>;
            }
        }
        return null;
    }

    export function defaults(): Full<Omit<QuestionModel, 'body'>> {
        return {
            id: 'thisisnotanactualid',
            title: 'Title',
            createdAt: new Date(),
            topicId: 'thisisnotanactualid',
            authorId: 'thisisnotanactualid',
            tags: [],
            overallRating: 0,
            difficulty: 0,
            rating: 'NONE',
        }
    }
}

export type Rating = 'UPVOTE' | 'DOWNVOTE' | 'NONE';

export interface PatchQuestionRequestBody {
    rating: Rating,
}

export interface TopicAppealModel {
    id: string,
    name: string,
    description: string,
    topicIcon: string,
    createdAt: string,
    reason: string,
    approved: boolean,
    authorId: string,
}

export namespace TopicAppealModel {
    export const KEYS: (keyof TopicAppealModel)[] = [
        'id',
        'name',
        'description',
        'topicIcon',
        'createdAt',
        'reason',
        'approved',
        'authorId',
    ];
}

export type GetQuestionQueryParams = {
    completed?: 'ALL' | 'COMPLETED' | 'UNCOMPLETED',
    authorIds?: string[],
    topicIds?: string[],
    combination?: 'AND' | 'OR',
    sortBy?: 'createdAt' | 'updatedAt' | 'title' | 'score',
    sortOrder?: 'asc' | 'desc',
    tags?: string[],
} & Partial<PaginatedQueryParams>;

export namespace GetQuestionQueryParams {
    export function oldest(): GetQuestionQueryParams {
        return {
            sortBy: 'createdAt',
            sortOrder: 'asc',
        };
    }

    export function newest(): GetQuestionQueryParams {
        return {
            sortBy: 'createdAt',
            sortOrder: 'desc',
        }
    }

    export function worst(): GetQuestionQueryParams {
        return {
            sortBy: 'score',
            sortOrder: 'desc',
        }
    }

    export function best(): GetQuestionQueryParams {
        return {
            sortBy: 'score',
            sortOrder: 'desc',
        }
    }
}

export type QuestionResponseData = {
    questions: Partial<Serde.DeAttr<QuestionModel, 'createdAt'>>[],
} & PaginatedResponseData;

export type QuestionByIdResponseData = {
    question: Partial<Serde.DeAttr<QuestionModel, 'createdAt'>>,
}

export type PostQuestionBody = {
    title: string,
    topicId: string,
    authorId: string,
    body: QuestionBodyModel,
    tags: string[],
};

/**
 * response.data object sent by the server when a request to get a user
 * is made
 */
export type UserResponseData = {
    following: boolean,
    followerCount: number,
    reputation: number,
    user: Partial<Omit<UserModel, 'password'>>,
    userFollowingCount: number,
    userFollowingList: { id: string }[],
    topicFollowingList: { id: string }[],
};

export namespace UserResponseData {
    export const KEYS: (keyof UserResponseData)[] = [
        'userFollowingCount',
        'userFollowingList',
        'topicFollowingList',
        'followerCount',
        'following',
        'user',
        'reputation',
    ];

    // THIS IS REQUIRED FOR TYPE INFERENCE
    export const KEYS_WITHOUT_USER: (keyof UserResponseData)[] = [
        'userFollowingCount',
        'userFollowingList',
        'topicFollowingList',
        'followerCount',
        'following',
        'reputation',
    ];
}

export type DeleteUserResponseData = {
    user: UserModel;
};

export type GetTopicModeratorsResponseData = {
    moderators: Omit<UserModel, 'password'>[];
}

/**
 * response.data object sent by the server when a request to get a
 * topic is made
 */
export type TopicTopicNameResponseData = {
    topic: Partial<Serde.DeAttr<TopicModel, 'createdAt'>>
};

/**
 * Return type for getting all topics
 */
export type TopicResponseData = {
    /**
     * All the topics (I don't know why this is called `topics`)
     */
    topics: Partial<Serde.DeAttr<TopicModel, 'createdAt'>>[],
};

/**
 * response.data object sent by the server when a saerch request
 * is made
 */
export type SearchResponseData = {
    users: SearchUser[],
    topics: SearchTopic[],
    questions: SearchQuestion[]
};

/**
 * response.data object sent by server when update account
 * request is made
 */
export type UpdateUserResponseData = {
    user: UserModel
};

export type ReportResponseData = {
    reports: ReportModel[],
    count: number,
};

export namespace ReportResponseData {
    export const KEYS: (keyof ReportResponseData)[] = [
        'reports',
        'count',
    ];
}

export type TopicAppealResponseData = {
    appeals: Partial<Serde.DeAttr<TopicAppealModel, 'createdAt'>>[];
    count: number;
}

// TODO: rename
export type DeleteQueryStringParams = DeleteReportParams;

/**
 * Query params for get request to /api/search
 */
export type GetSearchQueryParams = {
    input: string,
    pagination?: PaginatedQueryParams,
    [key: string]: PaginatedQueryParams | string | undefined
};

export type GetReportQueryParams = {
    reporterIds?: string,
    reporteeIds?: string,
} & Partial<PaginatedQueryParams>

export type DeleteReportParams = {
    reporterId: string,
    reporteeId: string,
    [key: string]: string | undefined
}

export type HistoryResponseData = {
    count: number,
    questions: Partial<Serde.DeAttr<QuestionModelWithoutAnswer, 'createdAt'>>[],
} & PaginatedResponseData;

/**
 * Successful response sent by server
 */
export type QuestionScoreData = {
    userId: string,
    questionId: string,
    correct: boolean,
}

export interface SuccessResponse<T> {
    success: true,
    message?: string,
    data?: Partial<T>,
}

/**
 * Authentication response sent by server
 */
export interface AuthResponse {
    success: true,
    token: string,
    user: Partial<Omit<UserModel, 'password'>>,
}

/**
 * Error response details sent by server
 */
export interface ErrorResponseBody {
    statusCode: StatusCode,
    message: string,
    fields?: string[],
}

export namespace ErrorResponseBody {
    export function partialContent(): ErrorResponseBody {
        return {
            statusCode: 206,
            message: 'partial content',
        }
    }

    export function notFound(): ErrorResponseBody {
        return {
            statusCode: 404,
            message: 'not found',
        }
    }
}

/**
 * Error response sent by server
 */
export interface ErrorResponse {
    success: false,
    error: ErrorResponseBody,
}

/**
 * Possoble response types sent by the server
 */
export type Response<T> = SuccessResponse<T> | AuthResponse | ErrorResponse;

// TODO: write tests
/**
 * Methods to cast a server response into an ApiResult
 */
export namespace Response {
    export function isSuccessResponse<T>(response: Response<T>): boolean {
        return variant(response) === 'SUCCESS';
    }

    export function isAuthResponse<T>(response: Response<T>): boolean {
        return variant(response) === 'AUTH';
    }

    export function isErrorResponse<T>(response: Response<T>): boolean {
        return variant(response) === 'ERROR';
    }

    export function variant<T>(response: Response<T>): 'SUCCESS' | 'AUTH' | 'ERROR' {
        if (response.success === false) {
            return 'ERROR';
        } else if (response.hasOwnProperty('token')) {
            return 'AUTH';
        } else if (response.success === true) {
            return 'SUCCESS';
        } else {
            return 'ERROR'; // unreachable 
        }
    }

    /**
     * Casts server response to appropriate ApiResult
     * @param response Response sent by the server
     * @returns ApiResult<Thin<T>> where T is the type of response.data object
     */
    export function assumeSuccess<T>(response: Response<T>): ApiResult<Thin<T>> {
        return isSuccessResponse(response)
            ? Result.ok((response as SuccessResponse<T>).data)
            : isAuthResponse(response)
                ? Result.ok(response) as ApiResult<Thin<T>>
                : Result.err(
                    isAuthResponse(response)
                        ? null
                        : (response as ErrorResponse).error
                );
    }
}

export type LeaderboardElementModel
    = Omit<UserModel, 'password'> & ({ relative: number } | { total: number });

export type SaneLeaderboardElementModel = {
    user: Omit<UserModel, 'password'>,
    value: number,
}

export namespace LeaderboardElementModel {
    export const KEYS: (keyof LeaderboardElementModel)[] = [
        ...UserModel.KEYS_WITHOUT_PASSWORD,
    ];

    /**
     * Tries to convert this into a `SaneLeaderboardElementModel`. If it is not possible, return `null`
     */
    export function tryIntoSane(partial: Partial<LeaderboardElementModel>): SaneLeaderboardElementModel | null {
        const required = requireProperties(partial, LeaderboardElementModel.KEYS);
        if (!required) {
            return null;
        }

        const value = tryGetProperty(required, 'relative') as number
            || tryGetProperty(required, 'total') as number
            || null;

        if (!value) {
            return null;
        }

        return {
            user: required,
            value,
        }
    }
}


export interface LeaderboardResponseData {
    leaderboard: Partial<LeaderboardElementModel>[],
    count: number
}

export type GetLeaderboardQueryParams = {
    timeframe: 'ALL_TIME' | 'MONTH' | 'DAY',
    basedOn: 'RELATIVE' | 'TOTAL',
} & PaginatedQueryParams;

export interface MessageModel {
    authorId: string,
    username: string,
    profileIcon?: string,
    text: string,
    createdAt: Date,
}

export type MessageResponseData = Serde.DeAttr<MessageModel, 'createdAt'>;

export interface PostFollowTopicBody {
    followerId: string,
    topicId: string,
}