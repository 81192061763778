import React from 'react';
import {Link, LinkProps,} from "react-router-dom";
import './DropDownList.scss';

function renderElChildren(
    children: React.ReactNode,
    iconSrc?: string,
    iconDisplay?: string,
    icon?: React.ReactNode
) {
    return <>
        {renderIconIf(iconDisplay === 'left', icon)}
        {children}
    </>
}

function renderIconIf(condition: boolean, icon: React.ReactNode) {
    return condition ?
        icon === undefined
            ? <div className="icon" />
            : <div className="icon">{icon}</div>
        : <></>;
}

export type DropDownListElProps = React.PropsWithChildren<{
    iconDisplay?: 'left' | 'hidden',
    ref?: React.MutableRefObject<HTMLDivElement | null>,
    icon?: React.ReactNode,
}> & React.HTMLAttributes<HTMLDivElement>;

/**
 * To be used as the immediate child of `<DropDownList>`  
 * Can display an icon -- set the position with `iconDisplay` and set
 * the src with `iconSrc`
 */
export const DropDownListEl: React.FC<
    DropDownListElProps
> = ({ icon, iconDisplay, children, className, ...props }) => (
    <div className={`drop-down-list-el ${className}`} {...props}>
        {renderElChildren(children, '', iconDisplay, icon)}
    </div>
)

export type DropDownListLinkProps = Omit<DropDownListElProps, 'ref'> & LinkProps;

/**
 * To be used as the immediate child of `<DropDownList>`, renders
 * as a `<Link>` and can take any prop that a `<Link>` can
 */
export const DropDownListLink: React.FC<
    DropDownListLinkProps
> = ({ icon, iconDisplay, children, ...props }) => (
    <Link className="drop-down-list-el link" {...props}>
        {
            iconDisplay === 'left'
                ? icon === undefined
                    ? <div className="icon" />
                    : <div className="icon">{icon}</div>
                : <></>
        }
        {children}
    </Link>
)

/**
 * A nice default for filling a `DropDown`'s children. Used in conjunction with 
 * `<DropDownListEl>foo</DropDownListEl>` elements. e.g.
 * 
 * ```
 * <DropDown>
 *     <DropDownList>
 *         <DropDownListEl>item 1</DropDownListEl>
 *         <DropDownListEl>item 1</DropDownListEl>
 *     </DropDownList>
 * </DropDown>
 * ```
 */
const DropDownList: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    return <div className="drop-down-list">
        {children}
    </div>;
}
export default DropDownList;