import './Account.scss';
import {Form, Formik} from 'formik';
import React, {useState} from 'react';
import * as Yup from 'yup';
import SubmitButton from '../../components/SubmitButton';
import LabelledField from '../../components/LabelledField';
import {updateUserAccount} from '../../services/actions/user';

const ChangePassword: React.FC = () => {
    const [status, setStatus] = useState<{
        success: boolean,
        message: string,
    }>();

    return <Formik
        initialValues={{
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        }}
        validationSchema={Yup.object({
            currentPassword: Yup.string()
                .required('Enter your current password'),
            newPassword: Yup.string()
                .required('Enter a password!! nyaaa 🐱'),
            confirmPassword: Yup.string()
                .required('Meow meow! Re-enter your password')
                .oneOf([Yup.ref('newPassword')], 'Passwords do not match! Meow-ks me so angry nyaaa!! 🐱'),

        })}
        onSubmit={async (values, { resetForm }) => {
            const res = await updateUserAccount(
                { currentPassword: values.currentPassword, password: values.newPassword }
            )
            if (res.tag === 'OK') {
                setStatus({
                    success: true,
                    message: 'Password updated!'
                })
            } else {
                setStatus({
                    success: false,
                    message: 'Something went wrong :( Try again'
                })
            }
            resetForm();
        }}>
        <Form
            style={{ width: '100%' }}
            onChange={() => setStatus(undefined)}>
            <LabelledField labelText="Current Password" name="currentPassword" type="password" placeholder='Current' />
            <LabelledField labelText="New Password" name="newPassword" type="password" placeholder='New' />
            <LabelledField labelText="Confirm Password" name="confirmPassword" type="password" placeholder='Confirm' />
            <SubmitButton>Save Password</SubmitButton>
            {status && <div
                className="status-message"
                style={{
                    color: status.success ? 'green' : 'red',
                }}
            >
                {status.message}
            </div>}
        </Form>
    </Formik>
}

export default ChangePassword;