import {AxiosRequestConfig} from "axios";
import {getCookie} from "../context";

export type ParentEndpoint = 'account' | 'follow/topic' | 'follow/user' | 'login'
    | 'question' | 'register' | 'user' | 'topics' | 'search' | 'topic' | 'topic-appeal'
    | 'report' | 'question/history';

/**
 * 
 * @param parent Parent endpoint
 * @param params Any additional to add to the url
 * @returns Url with data separated by '/'
 * '/parent/param0/param1/...'
 */
export function apiUri(parent: ParentEndpoint, ...params: string[]): string {
    return '/api/' + parent + (params && params.length > 0
        ? '/' + params.join('/')
        : '');
};

/**
 * Convert an object into a query string. This includes the leading `?`.
 * You cannot have nested objects, but you may have arrays. Array are encoded the way 
 * the backend has decided. That is, an array of `foo` would be called `foos` and would
 * be passed as such
 * 
 * ```
 * queryParamsToString({
 *     foos: ['something', 'somethingelse'],
 * })
 * 
 * // becomes...
 * '?foos=something&foos=somethingelse'
 * ```
 */
export function queryParamsToString(data: any): string {
    if (!data) {
        return ''
    }
    function keyValue(key: any, value: any): string {
        return encodeURIComponent(key) + '=' + encodeURIComponent(value);
    }

    let result = [];
    for (let key of Object.keys(data)) {
        const value = data[key];
        if (value instanceof Array) {
            for (let subValue of value) {
                result.push(keyValue(key, subValue))
            }
        } else {
            result.push(keyValue(key, value));
        }
    }

    return '?' + result.join('&');
}

/**
 * Gets axios config object. Needs to have a generic type T so the returned object
 * is compatible with the body of the request
 * @returns Axios config which needs to be set on majority of the api calls
 */
export function getDefaultAxiosConfig<T>(): AxiosRequestConfig<T> {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + (getCookie('token=') || ''),
        },
        validateStatus: () => true
    };
    return config;
};
