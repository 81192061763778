import {Field, FieldArray, FieldArrayRenderProps, useField} from 'formik';
import React from 'react';
import useInputWidth from '../../hooks/useInputWidth';
import './BlockFields.scss'

// TODO: separate into two different components UneditableBlockField and BlockField

export type BlockFieldProps = ({
    uneditable?: boolean,
    name: string,
    arrayHelpers: FieldArrayRenderProps,
    index: number,
} | {
    uneditable: true,
    name?: undefined,
    arrayHelpers?: undefined,
    index?: undefined,
}) & {
    placeholder?: string,
    key: number | string,
} & PublicBlockFieldProps;

export type PublicBlockFieldProps = {
    className?: string,
};

/**
 * TODO: fix overflowing fields
 */
export const BlockField: React.FC<BlockFieldProps> = ({
    uneditable = false,
    name,
    placeholder,
    index,
    arrayHelpers,
    className,
}) => {
    const inputRef = React.useRef(null);
    const [inputWidth, inputWidthMatchingSpan] = useInputWidth(inputRef);

    if (uneditable) {
        return <div className={'uneditable-block-field ' + className || ''}>
            {placeholder}
        </div>;
    } else {
        return <div className="editable-block-field-container">
            {inputWidthMatchingSpan}
            <Field
                className={'editable-block-field ' + className || ''}
                name={name}
                innerRef={inputRef}
                style={{
                    width: `calc(${inputWidth}px + 1.5ch)`,
                }}
            />
            <button
                className="delete"
                type="button"
                onClick={() => {
                    arrayHelpers?.remove(index);
                }}
            >ｘ</button>
        </div>
    }
}

export type BlockFieldsProps = {
    uneditableBlocks?: string[],
    name: string,
    blocks: string[],
    placeholder?: string,
    blockFieldProps?: PublicBlockFieldProps,
};

/**
 * TODO: documentation
 */
export const BlockFields: React.FC<BlockFieldsProps> = ({
    uneditableBlocks = [],
    blocks,
    name,
    placeholder,
    blockFieldProps,
}) => {
    const [, meta] = useField(name);

    return <FieldArray
        name={name}
        render={arrayHelpers => (
            <>
                <div className="block-fields">
                    {
                        uneditableBlocks.map((v, i) => (
                            <BlockField
                                uneditable
                                key={(-1 - i).toString()}
                                placeholder={v}
                                {...blockFieldProps}
                            />
                        )).concat(
                            blocks.map((v, i) => (
                                <BlockField
                                    key={i}
                                    index={i}
                                    placeholder={placeholder}
                                    name={`${name}[${i}]`}
                                    arrayHelpers={arrayHelpers}
                                    {...blockFieldProps}
                                />)
                            )
                        )
                    }
                    <button
                        className="add-new-block"
                        type="button"
                        onClick={() => {
                            arrayHelpers.push('');
                        }}
                    >＋</button>
                </div>
                {/* TODO: this sucks */}
                {
                    <div className="error" style={{ margin: '5px 10px 5px 20px' }}>
                        {
                            meta.touched && meta.error
                                ? 'all blocks must have at least one character'
                                : ''
                        }
                    </div>
                }
            </>
        )}
    />
}

export default BlockFields;