import {UserContext} from "../../context";
import {postQuestion} from "../../services/actions/auth";
import {getTopicByFullname} from "../../services/loaders";
import {
    FitgQuestionBodyModel,
    FitgQuestionElementModel,
    FitgQuestionElementTag,
    Full,
    QuestionBodyTag,
    QuestionModel,
    Result
} from "../../types";
import arrayShuffle from 'array-shuffle';

export interface ParseError {
    index: number,
    message: string,
}

/**
 * Parse a fitg question descriptor into the minimum question body model
 * 
 * ```
 * 'Some text {some input} some more text'
 * ```
 * 
 * would be parsed into a question body model with `'some input'` as the only block, and
 * the various elements being made up from the following parts
 * 
 * ```
 * // using namespace FitgQuestionElementModel
 * [text('Some text '), input('some input'), text(' some more text')]
 * ```
 * 
 * backslash escapes are implemented so that all characters can be displayed.
 */
export function parseFitgQuestionDescriptor(desc: string): Result<FitgQuestionBodyModel, ParseError> {
    const BACKSLASH = '\\';

    if (desc.length < 3) {
        return Result.err({
            index: 0,
            message: 'needs to be at least 3 characters long'
        })
    }

    let escapeNext = false;
    let creating = desc[0] === '{'
        ? FitgQuestionElementTag.INPUT
        : FitgQuestionElementTag.TEXT;
    let buf = creating === FitgQuestionElementTag.TEXT ? desc[0] : '';
    let elements = [];
    let index = 1;
    let lastOpeningBraceIndex = 0;

    for (const c of desc.slice(1)) {
        if (escapeNext) {
            buf += c;
            escapeNext = false;
        } else if (c === BACKSLASH) {
            escapeNext = true;
        } else if (c === '{') {
            lastOpeningBraceIndex = index;
            if (buf.length > 0) {
                elements.push(FitgQuestionElementModel.text(buf));
            }
            buf = '';
            creating = FitgQuestionElementTag.INPUT;
        } else if (c === '}') {
            if (lastOpeningBraceIndex === index - 1) {
                return Result.err({
                    index,
                    message: 'this block needs to contain at least 1 character'
                })
            }
            if (creating === FitgQuestionElementTag.TEXT) {
                return Result.err({
                    index,
                    message: 'no matching \'{\' was found for this \'}\'',
                })
            }
            elements.push(FitgQuestionElementModel.input(buf));
            buf = '';
            creating = FitgQuestionElementTag.TEXT;
        } else {
            buf += c;
        }
        index++;
    }

    if (creating === FitgQuestionElementTag.INPUT) {
        return Result.err({
            index: lastOpeningBraceIndex,
            message: 'no matching \'}\' was found for this \'{\'',
        })
    } else /* TEXT */ {
        elements.push(FitgQuestionElementModel.text(buf));
    }

    // TODO: unsafe
    const blocks = FitgQuestionBodyModel.requiredBlocks({ elements } as FitgQuestionBodyModel);

    if (blocks.length == 0) {
        return Result.err({
            index: 0,
            message: 'question must have at least one input block',
        })
    }

    return Result.ok({
        tag: QuestionBodyTag.FILL_IN_THE_GAPS,
        elements,
        blocks,
    })
}

export const LOWERCASE_CHARS = 'abcdefghijklmnopqrstuvwxyz'.split('');

export const UPPERCASE_CHARS = LOWERCASE_CHARS.map(c => c.toUpperCase());

export const DIGIT_CHARS = '1234567890'.split('');

export const UNDERSCORE_HYPHEN_CHARS = ['-', '_'];

export const ALPHANUMERIC_CHARS = LOWERCASE_CHARS.concat(UPPERCASE_CHARS).concat(DIGIT_CHARS);

export type ParseSelectorSettings = {
    selectorType: string,
    maxSelectorCount: number,
    maxSelectorLength: number,
};

export function parseSelectorsFactory(settings: ParseSelectorSettings): (_: string) => Result<string[], string> {
    return function (selectorString: string): Result<string[], string> {
        const selectors = selectorString.split(/\s+/g).filter(selector => selector !== '');

        if (selectors.length > settings.maxSelectorCount) {
            return Result.err(`You cannot have more than ${settings.maxSelectorCount} ${settings.selectorType}s`);
        }

        let selectorSet = new Set();
        for (let selector of selectors) {
            if (selector.length > settings.maxSelectorLength) {
                const trimmedSelector = selector.length > settings.maxSelectorLength
                    ? selector.slice(0, settings.maxSelectorLength) + '...'
                    : selector;
                return Result.err(`${settings.selectorType} '${trimmedSelector}' is too long (max ${settings.maxSelectorLength} characters)`);
            }
            if (selectorSet.has(selector)) {
                return Result.err(`${settings.selectorType} '${selector}' appears more than once`);
            }
            selectorSet.add(selector);
        }

        return Result.ok(selectors);
    }
}

/**
 * Parses tags, returning an error string if the tags are not valid 
 * Used for testing purposes
 * aka
 * - they are too long (greater than `MAX_TAG_LENGTH`)
 * - there are duplicates
 * - there are too many tags (max `MAX_TAG_COUNT`)
 */
export const parseTags = parseSelectorsFactory({ maxSelectorCount: 5, selectorType: 'tag', maxSelectorLength: 20 }); 

export async function submitQuestion(
    question: Full<QuestionModel>,
    userContext: UserContext,
    values: { topic: string },
) {
    const topic = await getTopicByFullname(values.topic);
    if (topic.tag === 'OK' && userContext.loggedInUser) {
        question.topicId = topic.value.id;
        question.authorId = userContext.loggedInUser.id;
        return await postQuestion({ ...question, tags: question.tags.map(tag => tag.tag.name) });
    }
    return null;
}

/**
 * Convert incorrect and correct options to `[options, answer]`
 */
export function incorrectCorrectOptionsToOptionsAnswer(
    incorrectOptions: string[],
    correctOptions: string[]
): [string[], number[]] {
    // convert incorrectOptions and correctOptions into the right
    // format and shuffle them
    type Option = {
        value: string,
        variant: 'correct' | 'incorrect',
    };

    const taggedOptions = arrayShuffle(
        incorrectOptions.map(value => ({
            value,
            variant: 'incorrect',
        })).concat(
            correctOptions.map(value => ({
                value,
                variant: 'correct',
            }))
        ) as Option[]
    );

    const answer = taggedOptions
        .map((option, index) =>
            option.variant === 'correct' ? index : null
        )
        .filter(t => t !== null) as number[];

    const options = taggedOptions.map(option => option.value);

    return [options, answer];
}

export const nothingHereErr = <T,>(): Result<T, ParseError> => Result.err({
    index: 0,
    message: 'there\'s nothing here!',
});