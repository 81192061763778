import React from "react";

import {socket as io} from "../services/socket";
import {Socket} from "socket.io-client";
import useUserContext from "./useUserContext";

const useSocket = () => {
    const { authToken } = useUserContext();
    const [socket, setSocket] = React.useState<Socket | null>(null)

    React.useEffect(() => {
        if (authToken) {
            const s = io(authToken);
            s.connect();
            setSocket(s);
        }

        return () => {
            socket?.disconnect()
            console.log("Disconnected from socket");
        }
    }, [authToken]);

    React.useEffect(() => {
        // Don't remove me, im mostly useless,
        // but sometimes when im not here, messages come twice :(
        // console.logs may or may not also help, inconclusive.
        // Upon further testing, console.logs are in-fact very helpful

        console.log('updated useSocket::socket =', socket);
        console.log('socket.connected =', socket?.connected);
    }, [socket])

    return {
        socket
    };
}

export default useSocket;