import React from 'react';
import {forAll} from '../util';

/**
 * Returns a `MutableRefObject` that you can attach to some `HTMLElement`  
 * Sets up event listeners to wait for a click that is anywhere *other* than
 * on `ref.current`, and fires `onClick` when one occurs.
 * 
 * ```
 * const ref = useOutsideClick(() => console.log("outside click!"));
 * 
 * return <div id="button" ref={ref}>click me</div>
 * ```
 * 
 * In the above example, we log `"outside click!"` whenever the user clicks
 * something other than the `div` with id `#button`
 * 
 * TODO: why is ignoreRefs.length 3 when one element is passed [el, null, el]??
 */
function useExternalClick<T extends HTMLElement>(
    onClick: (e?: MouseEvent) => void,
    ignore?: React.MutableRefObject<HTMLElement | null>[],
): React.MutableRefObject<T | null> {
    const ref = React.useRef<T | null>(null);
    let ignoreRefs = ignore
        ? ignore
        : [];
    ignoreRefs.push(ref);

    React.useEffect(() => {
        const listener = (e: MouseEvent) => {
            if (
                forAll(ignoreRefs, ref => (
                    e.target instanceof Element // bad, but cleaner.
                    && (
                        ref.current !== null
                            ? !ref.current.contains(e.target)
                            : true
                    )
                ))
            ) {
                onClick(e);
            }
        }
        document.addEventListener('click', listener);
        return () => document.removeEventListener('click', listener);
    }, [])

    return ref;
}

export default useExternalClick;