import React from 'react';
import useModal from '../../hooks/useModal';
import LoginSignupModal from './LoginSignupModal';
import useWindowDimensions from '../../hooks/useWindowDimensions';

/**
 * @returns HTMLElement which shows login/signup modal when clicked
 */
const LoginSignupButton: React.FC = () => {
    const buttonRef = React.useRef<HTMLDivElement>(null);
    const [windowWidth,] = useWindowDimensions();
    const [modalVis, setModalVis] = useModal(<LoginSignupModal
        key="login-signup-modal-header"
        doNotHideOnClick={[buttonRef]}
    />);

    return <div
        className="header-element login-signup-button"
        style={{
            outline: modalVis
                ? '0px 1.5px 0px 1.5px solid rgba(0, 0, 0, 0.1)'
                : 'none'
        }}
        onClick={() => { setModalVis(true); }}
        ref={buttonRef}
    >
        {
            windowWidth > 600
                ? <>Log-in&nbsp;/&nbsp;Sign-up&nbsp;🚪</>
                : <div className="login-signup-button-reactive">
                    <div>Log-in</div>
                    <hr />
                    <div>Sign-up</div>
                </div>
        }

    </div>;
}

export default LoginSignupButton;