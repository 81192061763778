import React from 'react';
import './Header.scss';
import '../../styles/index.scss';
import Logo from '../Logo';
import UserPreview, {UserPreviewProps} from './UserPreview';
import LoginSignupButton from './LoginSignupButton';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {Link} from 'react-router-dom';
import SearchBar from '../SearchBar';
import useUserContext from '../../hooks/useUserContext';
import {UserIconProps} from '../UserIcon';
import NavigationDropdown from './NavigationDropdown';

const SMALL_WINDOW_WIDTH = 800;

export interface HeaderProps {
    user?: UserPreviewProps,
}

/**
 * The top-bar header with a search bar (soon)
 * 
 * `user` is optional as this will give the options to sign-in log-in if the `user`
 * prop is omitted.
 */
const Header: React.FC<HeaderProps> = () => {
    const [windowWidth, _] = useWindowDimensions();
    const userContext = useUserContext();
    const user = userContext.loggedInUser as UserIconProps | null;
    const userMeta = userContext.loggedInUserMeta as { reputation: number } | null;

    return <>
        <div className="header">
            <div className="header-element-positioner">
                <Link to="/" className="hoverable logo-link">
                    {windowWidth > 600 ?
                        <Logo displayText={windowWidth > SMALL_WINDOW_WIDTH} />
                        : <></>
                    }
                </Link>
                {
                    user
                        ? <>
                            <NavigationDropdown />
                            <SearchBar />
                            <UserPreview
                                {...userMeta}
                                {...user}
                                displayText={windowWidth > SMALL_WINDOW_WIDTH}
                            />
                        </>
                        : <LoginSignupButton />
                }
            </div>
        </div>
        <div className="header-height" />
    </>;
}

export default Header;