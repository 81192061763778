import axios from "axios";

import {apiUri, getDefaultAxiosConfig} from "../axiosUtils";
import {
    ApiResult,
    ErrorResponseBody,
    GetTopicModeratorsResponseData,
    Response,
    Result,
    TopicModel,
    TopicResponseData,
    TopicTopicNameResponseData
} from "../../types";
import {requireProperties} from "../../util";
import Serde from "../../types/serde";

/**
 * @param topicFullName Full name of topic to be fetched
 * @returns Result object constaining data sent fron the server
 * form which topic can be extracted via res.topic
 */
export async function getTopicByFullname(topicFullName: string): Promise<ApiResult<TopicModel>> {
    const { data } = await axios.get<Response<TopicTopicNameResponseData>>(
        apiUri('topic', topicFullName),
        getDefaultAxiosConfig<void>()
    );
    const result = Response.assumeSuccess(data);
    if (result.tag === 'ERR') {
        return Result.err(result.error);
    }
    if (!result.value?.topic) {
        return Result.err(ErrorResponseBody.partialContent());
    }
    const tPartial = result.value.topic;
    
    const tRequired = requireProperties({ ...{ users: 0, questions: 0 }, ...tPartial }, TopicModel.KEYS);
    if (!tRequired) {
        return Result.err(ErrorResponseBody.partialContent());
    }

    const topic = Serde.deserializeAttributes<TopicModel, ['createdAt']>(tRequired, ['createdAt']);
    return Result.ok(topic);
}

/**
 * Get all topics site-wide, this doesn't return some bollocks response data,
 * it actually unwraps it for you and returns the topic models
 */
export async function getAllTopics(): Promise<ApiResult<TopicModel[]>> {
    const { data } = await axios.get<Response<TopicResponseData>>(
        apiUri('topic'),
        getDefaultAxiosConfig<void>()
    );

    const result = Response.assumeSuccess(data);
    if (result.tag === 'OK') {
        if (!result.value?.topics) {
            return Result.err(ErrorResponseBody.partialContent());
        }

        return Result.ok(
            (result.value.topics
                .map(topic => requireProperties(topic, TopicModel.KEYS))
                .filter(topicOrNull => topicOrNull) as Serde.DeAttr<TopicModel, 'createdAt'>[])
                .map(topic => Serde.deserializeAttributes(topic, ['createdAt']))
        )
    } else {
        return Result.err(result.error);
    }
};

export async function getTopicModerators(topicFullName: string) {
    const { data } = await axios.get<Response<GetTopicModeratorsResponseData>>(
        apiUri('topic', topicFullName, 'moderator'),
        getDefaultAxiosConfig()
    );
    return Response.assumeSuccess(data);
}