import React from "react";
import "./styles/index.scss";
import {Navigate, Route, Routes, useLocation, useParams,} from "react-router-dom";
import Header from "./components/Header";
import Users from "./pages/Users";
import Account from "./pages/Account";
import UserContextProvider from "./context";
import Feed from "./components/Feed";
import Topics from "./pages/Topics";
import {ModalContextProvider} from "./context/ModalContext";
import Home from "./pages/Home";
import {PathContext, PathMeta} from "./context/PathContext";
import TopicsTopic from "./pages/TopicsTopic";
import TopicAppeal from "./pages/TopicAppeal";
import Reports from "./pages/Reports";
import SubmitTopicAppeal from "./pages/SubmitTopicAppeal";
import Create from "./pages/Create";
import QuestionPage from "./pages/Question";
import FeedPage from './pages/Feed';
import TopicChat from "./pages/TopicChat";
import ReportReport from "./pages/ReportReport";
import Leaderboard from "./pages/Leaderboard";
import useUserContext from "./hooks/useUserContext";
import UsersUsernameQuestions from "./pages/UsersUsernameQuestions";

// RouteProps type definition is terrible -- add props as you need
interface ValidRouteProps {
    path: ValidPath,
    element?: React.ReactNode | null,
    requiresLogIn?: boolean,
}

const ValidRoutes: React.FC<{ routes: ValidRouteProps[] }> = ({ routes }) => {
    const Page: React.FC<ValidRouteProps> = ({ element, path, requiresLogIn = true }) => {
        const location = useLocation();
        const params = useParams();
        const { loggedInUser } = useUserContext();
    
        React.useEffect(() => {
            document.title = ROUTES_META[path].getTitle(params);
        }, [location]);
    
        return <PathContext.Provider value={ROUTES_META[path]}>
            <Header />
            {
                !requiresLogIn || requiresLogIn && loggedInUser 
                    ? element
                    : <Navigate to="/" replace />
            }
        </PathContext.Provider>
    }

    return <Routes>
        <>
            {
                routes.map(props => (
                    <Route
                        path={props.path}
                        key={props.path}
                        element={<Page {...props} />}
                    />
                ))
            }
            <Route
                path="*"
                element={<Navigate to="/" replace />}
            />
        </>
    </Routes>
}

type ValidPath = '/'
    | '/topics'
    | '/feed'
    | '/create'
    | '/users/:username'
    | '/account'
    | '/topics/:topicName'
    | '/topics/:topicName/leaderboard'
    | '/topic-appeals'
    | '/topics/:topicName/chat'
    | '/reports'
    | '/report/:id'
    | '/submit-topic-appeal'
    | '/question/:id'
    | '/my-reports'
    | '/users/:username/questions';

const ROUTES_META: { [_ in ValidPath]: PathMeta } = {
    '/': {
        getTitle: () => 'Home',
    },
    '/topics': {
        getTitle: () => 'Topics',
    },
    '/feed': {
        getTitle: () => 'Feed',
    },
    '/create': {
        getTitle: () => 'Card Creator',
    },
    '/users/:username': {
        getTitle: params => `${params.username}'s Profile`,
    },
    '/account': {
        getTitle: () => 'Account',
    },
    '/topics/:topicName': {
        getTitle: params => `${params.topicName}`,
    },
    '/topics/:topicName/leaderboard': {
        getTitle: params => `${params.topicName} Leaderboard`,
    },
    '/topic-appeals': {
        getTitle: () => 'Topic Appeals'
    },
    '/topics/:topicName/chat': {
        getTitle: params => `${params.topicName} Chat`,
    },
    '/reports': {
        getTitle: () => 'Reports'
    },
    '/report/:id': {
        getTitle: () => `Full Report`
    },
    '/submit-topic-appeal': {
        getTitle: () => 'Submit Topic Appeal'
    },
    '/question/:id': {
        getTitle: () => 'View Question'
    },
    '/my-reports': {
        getTitle: () => 'My reports'
    },
    '/users/:username/questions': {
        getTitle: params => `${params.username}'s questions`
    }
};

const App = () => {
    return <>
        <UserContextProvider>
            <ModalContextProvider>
                {/* 
                    NOTE FOR CONTRIBUTORS
                    - you will notice that new routes are blocked from being added by TypeScript
                    - this is intentional
                    - please add the path to the `ValidPath` type. 
                    - this will then force you to add meta information about the path to `ROUTES_META` 
                */}
                <ValidRoutes
                    routes={[
                        {
                            path: '/',
                            element: <Home />,
                            requiresLogIn: false,
                        },
                        {
                            path: '/account',
                            element: <Account />,
                        },
                        {
                            path: '/users/:username/questions',
                            element: <UsersUsernameQuestions />,
                        },
                        {
                            path: '/users/:username',
                            element: <Users />,
                        },
                        {
                            path: '/create',
                            element: <Create />,
                        },
                        {
                            path: '/feed',
                            element: <FeedPage />,
                        },
                        {
                            path: '/topics',
                            element: <Topics />,
                        },
                        {
                            path: '/topics/:topicName',
                            element: <TopicsTopic />,
                        },
                        {
                            path: '/topics/:topicName/leaderboard',
                            element: <Leaderboard />,
                        },
                        {
                            path: '/topics/:topicName/chat',
                            element: <TopicChat />,
                        },
                        {
                            path: '/topic-appeals',
                            element: <TopicAppeal />,
                        },
                        {
                            path: '/reports',
                            element: <Reports adminView={true} />,
                        },
                        {
                            path: '/submit-topic-appeal',
                            element: <SubmitTopicAppeal />,
                        },
                        {
                            path: '/question/:id',
                            element: <QuestionPage />,
                        },
                        {
                            path: '/my-reports',
                            element: <Reports adminView={false} />,
                        },
                        {
                            path: '/report/:id',
                            element: <ReportReport />,
                        }
                    ]}
                />
            </ModalContextProvider>
        </UserContextProvider>
    </>
}

export default App;
