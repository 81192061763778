import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import Paginated, {PaginatedProps} from '.';
import {range} from '../../util';
import DisplayPositioner, {DisplayPositionerProps} from '../DisplayPositioner';
import './Paginated.scss';

export type FancyPaginatedProps<D, Q> = Omit<PaginatedProps<D, Q>, 'pageIndex' | 'children'>
    & {
        displayPositionerProps?: DisplayPositionerProps,
        children: (responseData: D, pageIndex: number) => React.ReactNode | string | React.ReactNode[] | null,
    }

// note: splitting all props like this is necessary for type inference
/**
 * A wrapper around `<Paginated>` that gives you some left right buttons
 */
const FancyPaginated = <D, Q>({
    count,
    children,
    get,
    resultsPerPage,
    queryParams,
    displayPositionerProps,
}: FancyPaginatedProps<D, Q>) => {
    const [pageIndex, setPageIndex] = React.useState(0);

    const [maxPage, setMaxPage] = React.useState(1)

    React.useEffect(() => {
        setMaxPage(Math.ceil(count / resultsPerPage) - 1);
        if (pageIndex < 0) {
            // clamp to 0
            setPageIndex(0);
        } else if (pageIndex > maxPage) {
            // clamp to maxPage
            setPageIndex(maxPage);
        }
    }, [count, pageIndex]);

    return <DisplayPositioner
        {...displayPositionerProps}
        className={'fancy-paginated ' + displayPositionerProps?.className || ''}
    >
        <Paginated
            count={count}
            get={get}
            pageIndex={pageIndex}
            resultsPerPage={resultsPerPage}
            queryParams={queryParams}
        >
            {(rd) => children(rd, pageIndex)}
        </Paginated>
        <div className="turn-page-buttons-positioner">
            <div className="turn-page-buttons-container">
                <button
                    className="turn-page-button"
                    onClick={() => {
                        if (pageIndex > 0) {
                            setPageIndex(i => i - 1);
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <>
                    {(() => {
                        const buttonCount = 7; // must be odd
                        const maxOffset = Math.floor(buttonCount / 2);
                        // < 0 means we would go to negative pages
                        const n = pageIndex - maxOffset;
                        const [from, to] = [pageIndex - maxOffset, pageIndex + maxOffset + 1].map(i => n < 0 ? i + (-n) : i);
                        const rng = range(from, to).filter(element => {
                            return element <= maxPage
                        });

                        if (rng.length < buttonCount && rng.includes(maxPage)) {
                            const diff = buttonCount - rng.length;
                            const [from, to] = [rng[0] - diff, rng[0]].map(i => i < 0 ? 0 : i);
                            rng.unshift(...range(from, to));
                        }

                        return rng.map(index => (
                            <button
                                className={`turn-page-button ` + (index === pageIndex ? 'current' : '')}
                                onClick={() => {
                                    if (index >= 0 && index <= maxPage) {
                                        setPageIndex(index)
                                    }
                                }}
                            >
                                {index}
                            </button>
                        ))
                    })()}
                </>
                <button
                    className="turn-page-button"
                    onClick={() => {
                        if (pageIndex < maxPage) {
                            setPageIndex(i => i + 1)
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            </div>
        </div>
    </DisplayPositioner>
}

export default FancyPaginated;