import {Form, Formik} from 'formik';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import SubmitButton from '../SubmitButton';
import {registerUser} from '../../services/actions';
import {saveLoggedInUserData} from './Login';
import useUserContext from '../../hooks/useUserContext';
import LoginSignupFormTitle from './LoginSignupFormTitle';
import LoginSignupImage from './LoginSignupImage';
import {AuthResponse} from '../../types';
import ModalContext from '../../context/ModalContext';
import LabelledField from '../LabelledField';
import HoverDropDown, {HoverDropDownDisplay} from '../HoverDropDown';

type ValuesType = {
    username: string;
    name: string;
    email: string;
    confirmEmail: string;
    password: string;
    confirmPassword: string;
}

/**
 * @returns Form with field needed to sign up
 */
const Signup: React.FC = () => {
    const userContext = useUserContext();
    const navigate = useNavigate();
    const { visHandles: [, setModalVis] } = React.useContext(ModalContext)

    /**
     * Sends signup request to server and saves user data
     * in successful response
     * @param values 
     */
    async function handleRegister(values: ValuesType) {
        const res = await registerUser(
            values.username,
            values.name,
            values.email,
            values.password
        );

        if (res.tag === 'OK') {
            saveLoggedInUserData(
                res.value as AuthResponse,
                userContext
            );
            navigate('/');
            setModalVis(false);
        } else {
            const fields = res.error?.fields;
            // TODO: show the user has duplicate username or email
        }
    };

    return <div className="row-flex-centered">
        <LoginSignupImage src="signupImage.png" />
        <Formik
            initialValues={{
                username: '',
                name: '',
                email: '',
                confirmEmail: '',
                password: '',
                confirmPassword: '',
            }}
            validationSchema={Yup.object({
                username: Yup.string()
                    .required('Enter a username UwU')
                    .min(3, 'Must be cat least 3 chararacters pwease')
                    .matches(/^[a-zA-Z0-9_\-]*$/, 'Cat-tains invalid characters'),
                name: Yup.string()
                    .required('State your name cuz'),
                password: Yup.string()
                    .required('Enter a password!! nyaaa 🐱')
                    .test('password-is-strong-enough', function (password) {
                        let errorMsg: null | string = null;
                        switch (true) {
                            case !/.{8,}/.test(password):
                                errorMsg =
                                    'Password must be at least 8 character long';
                                break;
                            case !/(?=.*?[A-Z])/.test(password):
                                errorMsg =
                                    'Password should cat-tain at least one capital letter';
                                break;
                            case !/(?=.*?[a-z])/.test(password):
                                errorMsg =
                                    'Password should cat-tain at least one lowercase letter';
                                break;
                            case !/(?=.*?[0-9])/.test(password):
                                errorMsg =
                                    'Password should cat-tain at least one digit (0-9)';
                                break;
                        };
                        if (errorMsg) {
                            return this.createError({
                                path: this.path,
                                message: errorMsg,
                            })
                        }
                        return true;
                    }),
                confirmPassword: Yup.string()
                    .required('Meow meow! Re-enter your password')
                    .oneOf([Yup.ref('password')], 'Passwords do not match! Meow-ks me so angry nyaaa!! 🐱'),
                email: Yup.string()
                    .required('Enter an email address meow!')
                    .email('Enter a valid email address pwease'),
                confirmEmail: Yup.string()
                    .required('Re-enter email pwease 🐱 if you would be so kwind UwU')
                    .test(
                        'emails-match',
                        'Emails do not meow-tch!',
                        function (confirmEmail) {
                            return this.parent.email === confirmEmail;
                        }
                    ),
            })}
            onSubmit={handleRegister}
        >
            {({ values }) => (
                <Form className="login-signup-form">
                    <LoginSignupFormTitle>Sign up</LoginSignupFormTitle>
                    <table>
                        <tbody>
                            <LabelledField placeholder="Enter username" name="username" />
                            <LabelledField placeholder="Enter name" name="name" />
                            <LabelledField placeholder="Enter email" name="email" />
                            <LabelledField placeholder="Confirm your email" name="confirmEmail" />
                            <LabelledField
                                fieldIcon={<PasswordStrengthIndicator password={values.password} />}
                                placeholder="Enter password"
                                name="password"
                                type="password"
                            />
                            <LabelledField placeholder="Confirm your Password" name="confirmPassword" type="password" />
                        </tbody>
                    </table>
                    <SubmitButton>Submit</SubmitButton>
                </Form>
            )}
        </Formik>
    </div>
}

/**
 * @param password Password entered by the user
 * @returns Bars indicating the strength of the password or an 
 * error message if password doesn't match minimum standards
 */
const PasswordStrengthIndicator: React.FC<{ password: string }> = ({ password }) => {
    const [strength, setStrength] = React.useState<0 | 1 | 2 | 3>(0);

    /**
     * Checks the validity of the password by checking if it matches
     * a set of regular expressions the determines strength based
     * on length
     */
    React.useEffect(() => {
        switch (true) {
            case password.length === 0:
                setStrength(0);
                break;
            case password.length <= 10:
                setStrength(1);
                break;
            case password.length > 10 && password.length <= 14:
                setStrength(2);
                break;
            case password.length > 14:
                setStrength(3);
                break;
        };
    }, [password]);

    let barClassName: string;
    switch (strength) {
        case 3:
            barClassName = 'good password-strength-indicator-bar';
            break;
        case 2:
            barClassName = 'moderate password-strength-indicator-bar';
            break;
        case 1:
            barClassName = 'weak password-strength-indicator-bar';
            break;
    }

    return (
        <HoverDropDown content={
            <HoverDropDownDisplay justify='left'>
                {(() => {
                    switch (strength) {
                        case 0:
                            return 'enter a password to view its strength';
                        case 1:
                            return 'your password is terrible :(';
                        case 2:
                            return 'your password is ok -- but it can be better!';
                        case 3:
                            return 'excellent password, good job :)';
                    }
                })()}
            </HoverDropDownDisplay>
        }>
            <div className='password-strength-indicator-bars'>
                {
                    Array(strength).fill(null)
                        .map((_, key) => <div className={barClassName} key={key} />)
                }
            </div>
        </HoverDropDown>
    )
};

export default Signup;