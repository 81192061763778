import React from 'react';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context';
import useModal from '../../hooks/useModal';
import useUserContext from '../../hooks/useUserContext';
import {updateStatus} from '../../services/actions';
import DropDown from '../DropDown';
import DropDownList, {DropDownListEl, DropDownListLink} from '../DropDownList';
import UserIcon, {UserIconProps} from '../UserIcon';
import StatusModal from './StatusModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faCircleHalfStroke,
    faClipboardQuestion,
    faFlag,
    faPencil,
    faPenToSquare,
    faRightFromBracket,
    faUser
} from '@fortawesome/free-solid-svg-icons';


export type UserPreviewProps = UserIconProps;

/**
 * Deletes all information about the logged in user 
 * from context, cookies and local storage
 * @param userContext obtained via useUserContext();
 */
export async function handleLogout(userContext: UserContext) {
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    document.cookie = 'userStatus='+userContext.loggedInUser?.status;
    try{
        await updateStatus('OFFLINE');
    } catch {
        //ignore (only happnens after account is deleted)
    }
    userContext.updateAuthToken(null);
    userContext.updateLoggedInUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('userMeta');
}

/**
 * @param UserPreviewProps 
 * @returns Dropdown with options for actions user can take which wraps
 * username and usericon
 */
const UserPreview: React.FC<UserPreviewProps> = (userIconProps) => {
    const statusButtonRef = React.useRef<HTMLDivElement | null>(null);

    const [, setStatusModalVis] = useModal(
        <StatusModal key="status-modal" doNotHideOnClick={[statusButtonRef]} />
    );

    const userContext = useUserContext();
    const navigate = useNavigate();

    const logoutUser = () => {
        handleLogout(userContext);
        navigate('/');
    }

    return <DropDown
        className="user-preview"
        settings={{ outlineOnSelect: 'none' }}
        wraps={
            <div style={{ paddingRight: '5px', height: '100%' }}>
                <UserIcon {...userIconProps} />
            </div>
        }
    >
        <DropDownList>
            <DropDownListLink
                iconDisplay="left"
                icon={<FontAwesomeIcon icon={faPenToSquare} />}
                to="/account"
            >
                Account
            </DropDownListLink>
            <DropDownListEl
                iconDisplay='left'
                icon={<FontAwesomeIcon icon={faCircleHalfStroke} />}
            >
                <span
                    onClick={() => setStatusModalVis(true)}
                    ref={statusButtonRef}
                    style={{ fontSize: 'inherit' }}
                >
                    Set Status
                </span>
            </DropDownListEl>
            <DropDownListLink
                iconDisplay="left"
                icon={<FontAwesomeIcon icon={faUser} />}
                to={`/users/${userIconProps.username}`}
            >
                Profile
            </DropDownListLink>
            <DropDownListLink
                iconDisplay="left"
                icon={<FontAwesomeIcon icon={faPencil} />}
                to="/create"
            >
                Create Cards
            </DropDownListLink>
            <DropDownListLink
                iconDisplay="left"
                icon={<FontAwesomeIcon icon={faClipboardQuestion} />}
                to={`/users/${userIconProps.username}/questions`}
            >
                My Cards
            </DropDownListLink>
            <DropDownListLink
                iconDisplay="left"
                icon={<FontAwesomeIcon icon={faFlag} />}
                to={`/reports`}
            >
                My Reports
            </DropDownListLink>
            <DropDownListEl
                iconDisplay="left"
                icon={<FontAwesomeIcon icon={faRightFromBracket} color='red'/>}
                style={{ color: 'rgb(240, 30, 30)' }}
                onClick={logoutUser}
            >
                Log out
            </DropDownListEl>
        </DropDownList>

    </DropDown>;
}

export default UserPreview;