import React, {createContext, ReactNode} from 'react';
import {Full, UserModel, UserResponseData} from '../types';
import {requireProperties} from '../util';

export type UserContext = {
    loggedInUser: Omit<UserModel, 'password'> | null;
    loggedInUserMeta: Omit<UserResponseData, 'user'> | null,
    authToken: string | null;
    // Partial<Full<T>> since we want all attributes to be optional and just
    // update the ones we can
    updateLoggedInUser: (userData: Partial<Full<UserResponseData>> | null) => void;
    updateAuthToken: (token: string | null) => void;
}

/**
 * 
 * @param cookieName name of the cookie to be retireved. Must be in the form 'name='
 * @returns Value of the cookie if exists or else null
 */
export function getCookie(cookieName: string) {
    const cookieArr = document.cookie.split(';');

    for (let i = 0; i < cookieArr.length; i++) {
        let cookie = cookieArr[i].trim();
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return null;
};

/**
 * Get a default value from localStorage or `null`
 */
function getDefaultFromLocalStorage<T>(key: string): T | null {
    const value = localStorage.getItem(key);
    if (!value) {
        return null;
    }
    try {
        return JSON.parse(value);
    } catch {
        return null;
    }
};

export const UserContext = createContext<UserContext>({
    loggedInUser: null,
    loggedInUserMeta: null,
    authToken: null,
    updateLoggedInUser: () => { },
    updateAuthToken: () => { },
});

/**
 * Provides the children of <UserContext> with userCOntext
 * Components outside <UserCOntext> will not be able to access logged in user and tokens
 */
const UserContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [loggedInUser, setLoggedInUser] = React.useState<Omit<UserModel, 'password'> | null>(
        getDefaultFromLocalStorage('user'),
    );
    const [authToken, setAuthToken] = React.useState<string | null>(getCookie('token='));
    const [loggedInUserMeta, setLoggedInUserMeta] = React.useState<Omit<UserResponseData, 'user'> | null>(
        getDefaultFromLocalStorage('userMeta'),
    );

    const updateLoggedInUser = (rd: Partial<Full<UserResponseData>> | null) => {
        setLoggedInUser(rd?.user || null);
        setLoggedInUserMeta(rd ? requireProperties(rd, UserResponseData.KEYS_WITHOUT_USER) : null);
    };

    const updateAuthToken = (token: string | null) => {
        setAuthToken(token);
    }

    return (
        <UserContext.Provider
            value={{
                loggedInUser,
                loggedInUserMeta,
                authToken,
                updateLoggedInUser,
                updateAuthToken,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export default UserContextProvider