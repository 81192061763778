import React from 'react';
import useModal from '../../hooks/useModal';
import {deleteQuestion} from '../../services/actions';
import DeleteModal from '../DeleteModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashCan} from '@fortawesome/free-solid-svg-icons';

const SmallDeleteButton: React.FC<{questionId: string}> = ({questionId}) => {
    const deleteQuestionRef =  React.useRef<HTMLButtonElement | null>(null);

    const [, setDeleteModalVis] = useModal(
        <DeleteModal 
            modalKey={`delete-question-modal-${questionId}`}
            cb={async () => await handleDeleteQuestion(questionId)}
            elementType='question'
            doNotHideOnClick={[deleteQuestionRef]}
        />
    )
    async function handleDeleteQuestion(questionId: string) {
        const res = await deleteQuestion(questionId);
        if (res.tag === 'OK') {
            setDeleteModalVis(false);
        };
    };

    return(
        <button 
            className='small-button-container'
            onClick={() => {
                setDeleteModalVis(true)
            }}
            ref={deleteQuestionRef}
        >
            <FontAwesomeIcon style={{ color: 'rgb(150, 50, 50)'}} icon={faTrashCan} />
        </button>
    )
};

export default SmallDeleteButton;