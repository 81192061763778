import React from 'react';
import {Form, Formik} from "formik";
import * as Yup from "yup";
import LoginSignupFormTitle from './LoginSignupFormTitle';
import SubmitButton from '../SubmitButton';
import LoginSignupImage from './LoginSignupImage';
import useUserContext from '../../hooks/useUserContext';
import {useNavigate} from 'react-router';
import {loginUser, updateStatus} from '../../services/actions';
import {getCookie, UserContext} from '../../context';
import {AuthResponse, Result, UserModel, UserStatus} from '../../types';
import ModalContext from '../../context/ModalContext';
import {requireProperties, stripTo} from '../../util';
import LabelledField from '../LabelledField';
import {getUserByPk} from '../../services/loaders';

/**
 * Saves user data to brower storage and context after successful authentication
 * @param data Response sent by server
 * @param userContext 
 */
export function saveLoggedInUserData(data: AuthResponse, userContext: UserContext) {
    document.cookie = "token=" + data.token + ";";
    userContext.updateAuthToken(data.token);
    const user = requireProperties(data.user, UserModel.KEYS_WITHOUT_PASSWORD);
    const userStatus = getCookie('userStatus=');
    if (user) {
        getUserByPk(user.id)
            .then(result => Result.map(result, user => {
                userContext.updateLoggedInUser(user);
                localStorage.setItem('user', JSON.stringify(user.user));
                localStorage.setItem(
                    'userMeta',
                    JSON.stringify(stripTo(user, [
                        'following',
                        'followerCount',
                        'reputation',
                        'userFollowingCount',
                        'userFollowingList',
                        'topicFollowingList'
                    ]))
                );
            }));
        if (userStatus) {
            //Safe as we only store valid statuses in cookies
            updateStatus(userStatus as UserStatus);
        }
    } else {
        userContext.updateLoggedInUser(null);
    }
}

// TODO: change this and Signup to use LabelledField instead (so that it is responsive)
const Login: React.FC = () => {
    const userContext = useUserContext();
    const navigate = useNavigate();
    const { visHandles: [, setModalVis] } = React.useContext(ModalContext);
    const [errorMsg, setErrorMsg] = React.useState('');

    return <div className="row-flex-centered">
        <Formik
            initialValues={{
                emailOrUsername: '',
                password: '',
            }}
            validationSchema={Yup.object({
                emailOrUsername: Yup.string()
                    .required('Username or email is required')
                    .min(3, 'Must be 3 characters or more')
                    .max(20, 'Username is too long'),
                password: Yup.string()
                    .required('Password is required'),
            })}
            onSubmit={values => {
                loginUser(values.emailOrUsername, values.password).then(res => {
                    if (res.tag === 'OK') {
                        saveLoggedInUserData(res.value as AuthResponse, userContext);
                        navigate('/');
                        setModalVis(false);
                    } else {
                        setErrorMsg('Invalid username or password');
                    };
                });
            }}
        >
            <Form>
                <LoginSignupFormTitle>Log in</LoginSignupFormTitle>
                <LabelledField name="emailOrUsername" placeholder="Enter a username or email" />
                <LabelledField name="password" placeholder="Enter password" type="password" />
                <div className='login-error-msg' >
                    {errorMsg && errorMsg}
                </div>
                <SubmitButton>Submit</SubmitButton>
            </Form>
        </Formik>
        <LoginSignupImage src="loginImage.png" />
    </div>;
}


export default Login;