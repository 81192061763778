import React from 'react';
import Display from '../../components/Display';
import {Form, Formik} from 'formik';
import {FitgQuestionBodyModel, Full, QuestionModel, Response, Result, TagModel, UseStateReturnType} from '../../types';
import {nothingHereErr, ParseError, parseFitgQuestionDescriptor, parseTags, submitQuestion} from './util';
import LabelledField, {LabelledFieldLabel} from '../../components/LabelledField';
import BlockFields from '../../components/BlockFields';
import * as Yup from 'yup';
import {tagsYupSchema, titleYupSchema, topicYupSchema} from './validation';
import QuestionPreview from './QuestionPreview';
import useUserContext from '../../hooks/useUserContext';
import SuccessModal from './SuccessModal';
import SomethingWentWrongModal from './SomethingWentWrongModal';
import useModal from '../../hooks/useModal';
import MetaFields from './MetaFields';

export type FitgQuestionCreateFormProps = {
    questionHandles: UseStateReturnType<Result<Full<QuestionModel>, ParseError>>,
    allTopicNames: string[],
}

const FitgQuestionCreateForm: React.FC<FitgQuestionCreateFormProps> = ({
    questionHandles: [question, setQuestion],
    allTopicNames,
}) => {
    // it's more effort and doesn't improve portability to include these lines in the parent component imo
    const userContext = useUserContext();
    const [successModalVis, setSuccessModalVis] = useModal(<SuccessModal />);
    const [somethingWentWrongModalVis, setSomethingWentWrongModalVis] = useModal(<SomethingWentWrongModal />);

    const validationSchema = Yup.object({
        title: titleYupSchema(),
        body: Yup
            .string()
            .required()
            .test('fitg-question-descriptor', 'invalid descriptor', function (value: string) {
                const result = parseFitgQuestionDescriptor(value);
                if (result.tag === 'OK') {
                    return true;
                }
                return this.createError({
                    path: this.path,
                    message: `at index: ${result.error.index} '${result.error.message}'`
                });
            }),
        blocks: Yup
            .array()
            .of(Yup.string().required())
            .notRequired(),
        topic: topicYupSchema(allTopicNames),
        tags: tagsYupSchema(),
    });

    return <>
        <Display className="create-form">
            <QuestionPreview question={question} />
            <Formik
                initialValues={{
                    title: '',
                    body: '',
                    blocks: [] as string[],
                    topic: '',
                    tags: '',
                }}
                validationSchema={validationSchema}
                onSubmit={values => {
                    if (question.tag === 'OK') {
                        submitQuestion(question.value, userContext, values)
                            .then(result => {
                                if (!result || !Response.assumeSuccess(result)) {
                                    setSomethingWentWrongModalVis(true);
                                } else {
                                    values.title = '';
                                    values.body = '';
                                    values.blocks = [];
                                    values.topic = '';
                                    setQuestion(nothingHereErr());
                                    setSuccessModalVis(true);
                                    // TODO: redirect the user 
                                }
                            });
                    }
                }}
                validate={values => {
                    const updateQuestionPreview = () => {
                        const questionBody = parseFitgQuestionDescriptor(values.body);
                        if (questionBody.tag === 'ERR') {
                            setQuestion(Result.err(questionBody.error));
                            return;
                        }

                        const tags = parseTags(values.tags || '');
                        if (tags.tag === 'ERR') {
                            // unreachable -- but keeping here in case i break something else
                            return;
                        }

                        questionBody.value.blocks = questionBody.value.blocks.concat(values.blocks);
                        setQuestion(Result.ok({
                            ...QuestionModel.defaults(),
                            title: values.title,
                            body: questionBody.value,
                            tags: tags.value.map(tag => TagModel.fromString(tag)),
                        }));
                    }

                    validationSchema.validate(values)
                        .then(() => updateQuestionPreview())
                        .catch(err => {
                            if (err.path === 'topic') {
                                updateQuestionPreview();
                            }
                        })
                }}
            >
                {({ values }) => <Form>
                    <LabelledField
                        name="title"
                        labelText="Title"
                        labelSubtext={'Enter a short title for your question'}
                        className="monospace-input"
                        placeholder="Title"
                    />
                    <LabelledField
                        name="body"
                        className="monospace-input"
                        labelText="Descriptor"
                        labelSubtext={'Look at the HELP section to find out how to format this descriptor'}
                        placeholder="You need at least {1} input field!"
                        as="textarea"
                    />
                    <LabelledFieldLabel labelText="Blocks" labelSubtext="Add more blocks -- make sure your question only has one answer!" />
                    <BlockFields
                        name="blocks"
                        uneditableBlocks={
                            question.tag === 'OK'
                                ? FitgQuestionBodyModel.requiredBlocks(question.value.body as FitgQuestionBodyModel)
                                : []
                        }
                        blocks={values.blocks}
                        placeholder="new block"
                    />
                    <MetaFields />
                    {/* make this say 'are you sure?' */}
                </Form>}
            </Formik>
        </Display>
    </>
}

export default FitgQuestionCreateForm;