import axios from "axios";
import {apiUri, getDefaultAxiosConfig} from "../axiosUtils";
import {DeleteUserResponseData, Response, UpdateUserResponseData, UserResponseData, UserStatus} from "../../types";
import {UpdateUserData} from "../../pages/Account/EditProfile";

export type StatusBody = {
    status: string
}

/**
 * Sends a patch request to the server to change user's status
 * @param status Status to set the user to
 * @returns ApiResult containing data sent from server.
 * User obtained via res.user
 */
export async function updateStatus(status: UserStatus) {
    const { data } = await axios.patch<Response<UserResponseData>>(
        apiUri('user', 'status'),
        { status: status },
        getDefaultAxiosConfig<StatusBody>()
    );
    return Response.assumeSuccess(data);

}

/**
 * 
 * @param userData Data to change about the user
 * @returns ApiResult containing the updated user
 */
export async function updateUserAccount(userData: UpdateUserData) {
    const { data } = await axios.patch<Response<UpdateUserResponseData>>(
        apiUri('account'),
        userData,
        getDefaultAxiosConfig<UpdateUserData>()
    );

    return Response.assumeSuccess(data);
}

/**
 * (admin only)
 * @param identifier username or id of the user to delete
 * @returns Api result containing delted user if successful
 * otherwise returns error response
 */
export async function deleteUser(identifier: string) {
    const { data } = await axios.delete<Response<DeleteUserResponseData>>(
        apiUri('user', identifier),
        getDefaultAxiosConfig<void>()
    );

    return Response.assumeSuccess(data);
}

/**
 * Follow a user by id
 * @param id the id of the user to follow
 */
export async function followUser(id: string) {
    return await axios.post(
        apiUri('follow/user'),
        { "followeeId": id },
        { ...getDefaultAxiosConfig() }
    );
}