import './Radio.scss';
import React from 'react';

export type RadioProps = React.PropsWithChildren<{
    options: string[],
    initialState?: string,
    onChange?: (state: string) => void,
}> & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

/**
 * `Radio` button (this is the same sort of meaning as the HTML meaning, 
 * I didn't invent it!)
 * 
 * - by default `Radio`s are row-flex with space-between, you can pass any
 *   style you want to them though with a `style` attribute
 * - set the options of the radio with the `options` prop. They should all
 *   be unique! If you have a use-case where they're not unique lmk (Oli -- duh)
 * 
 * ```
 * <Radio options={['option-1', 'option-2']}/>
 * ```
 * 
 * - For example, this radio displays with two toggleable options 
 * - The initial state is the first option, but you can set it with the `initialState`
 *   prop!
 * - `onChange` will be called whenever the active button changes, it takes a callback
 *   with the identifier for that button! you could do something like this to toggle a 
 *   page...
 * 
 * ```
 * const [inject, setInject] = React.useState(<></>);
 * 
 * return <>
 *     {inject}
 *     <Radio 
 *         options={['a', 'b']}
 *         onChange={(active: string) => {
 *              if (active === 'a') setInject(<div>a</div>);
 *              else setInject(<div>b</div>);
 *         }}
 *     />
 * </>
 * ```
 */
const Radio: React.FC<RadioProps> = ({
    options,
    initialState,
    onChange = () => { },
    style,
    ...props
}) => {
    if (options.length == 0) {
        options.push('button');
    }

    if (initialState === undefined || !options.includes(initialState)) {
        initialState = options[0];
    }

    const [active, setActive] = React.useState(initialState);
    React.useEffect(() => onChange(active), [active]);

    return <div
        className="radio"
        style={{ width: '100%', ...style }}
        {...props}
    >
        {
            options.map(optionString => (
                <div
                    className="radio-button"
                    variant={optionString === active ? 'active' : 'inactive'}
                    onClick={() => setActive(optionString)}
                    key={optionString}
                >
                    {optionString}
                </div>
            ))
        }
    </div>
}

export default Radio;

