import axios from "axios";
import {apiUri, getDefaultAxiosConfig} from "../axiosUtils";
import {AuthResponse, ErrorResponse, PostQuestionBody, Response, UserModel} from "../../types";
import {stripTo} from "../../util";

export type LoginData = {
    success: boolean,
    user: UserModel,
    token: string,
}

export type LoginReqData = {
    usernameOrEmail: string,
    password: string
}

/**
 * Makes post request to /api/login
 * @param usernameOrEmail username or email of user who is logging in
 * @param password Password of user who is logging in
 * @returns data = response from server = {success, user, token}
 */
export async function loginUser(usernameOrEmail: string, password: string) {
    const { data } = await axios.post<AuthResponse | ErrorResponse>(
        apiUri('login'),
        { usernameOrEmail: usernameOrEmail, password },
        getDefaultAxiosConfig<LoginReqData>()
    );

    return Response.assumeSuccess(data);
};

/**
 * Makes post request to /api/register
 * @param username Username of user who is registering
 * @param email Email of user who is registering 
 * @param password Password of user who is registering
 * @returns data = response from server = {success, user, token}
 */
export async function registerUser(username: string, name: string, email: string, password: string) {
    const { data } = await axios.post<AuthResponse | ErrorResponse>(
        apiUri('register'),
        { username, name, email, password },
        getDefaultAxiosConfig()
    );

    return Response.assumeSuccess(data);

}

/**
 * Create a new question
 * TODO: this should not be in auth oops
 */
export async function postQuestion(body: PostQuestionBody) {
    body = stripTo(body, ['title', 'body', 'topicId', 'authorId', 'tags']);

    const { data } = await axios.post<Response<void>>(
        apiUri('question'),
        body,
        getDefaultAxiosConfig<PostQuestionBody>(),
    );

    return data;
}