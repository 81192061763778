import axios from "axios";
import {apiUri, getDefaultAxiosConfig, queryParamsToString} from "../axiosUtils";
import {
    ApiResult,
    GetLeaderboardQueryParams,
    LeaderboardElementModel,
    LeaderboardResponseData,
    Response,
    Result,
    SaneLeaderboardElementModel,
    Thin
} from "../../types";
import {requireProperties} from "../../util";

type LeaderboardModel = {
    leaderboard: SaneLeaderboardElementModel[]
    count: number
}

/**
 * get the leaderboard and try and convert it into a nicer type than the one the API sends us
 * (vomit)
 * 
 * this takes two parameter groups so that it can be used as a `get` in `Paginated`
 * 
 * ```
 * <Paginated get={getLeaderboard('topic')}>{ ... }</Paginated>
 * ```
 */
export function getLeaderboard(topicName: string): (_: GetLeaderboardQueryParams) => Promise<ApiResult<LeaderboardModel>> {
    return async function (params: GetLeaderboardQueryParams): Promise<ApiResult<LeaderboardModel>> {
        const { data } = await axios.get<Response<LeaderboardResponseData>>(
            apiUri('topic', topicName, 'leaderboard', queryParamsToString(params)),
            getDefaultAxiosConfig<void>()
        );
    
        const result = Response.assumeSuccess(data);
        return mapLeaderboardResponseData(result);
    }
}

/**
 * map the ok variant into a leaderboard so long as it's full 
 */
export function mapLeaderboardResponseData(result: ApiResult<Thin<LeaderboardResponseData>>): ApiResult<LeaderboardModel> {
    if (result.tag === 'OK') {
        if (!result.value?.leaderboard) {
            return Result.err(null);
        }
        const leaderboard = result.value;

        const paginatedLeaderboard = {
            leaderboard: leaderboard.leaderboard?.map(element => {
                const required = requireProperties(element, LeaderboardElementModel.KEYS);
                if (!required) {
                    return null;
                }
                return LeaderboardElementModel.tryIntoSane(required);
            }).filter(el => el),
            count: leaderboard.count
        }

        return Result.ok(paginatedLeaderboard as LeaderboardModel);
    }
    return Result.err(null);
}