import React, {useState} from 'react';
import useUserContext from '../../hooks/useUserContext';
import {UserResponseData, UserStatus} from '../../types';
import ReportButton from './ReportButton';
import {followUser} from '../../services/actions/user';

const DEFAULT_BIO = 'User does not have a bio.';

/**
 * A panel displaying the User's main details
 * TODO: make this not Thin
 */
export const UserSummary: React.FC<UserResponseData> = ({ following, user, reputation }) => {
    const userContext = useUserContext();
    const [isFollowing, setIsFollowing] = useState(following);

    const handleFollowClick = async () => {
        if (user.id !== undefined) {
            followUser(user.id);
            setIsFollowing(!isFollowing);
        }
    }

    return (
        <div className="user-summary users-panel-container">
            <div className="pfp-container">
                <img
                    className="pfp"
                    src={user?.profileIcon}
                />
                <div className="status-aligner">
                    <div
                        className="status"
                        style={{
                            backgroundColor: UserStatus.color(user?.status
                                ? user.status
                                : 'OFFLINE'
                            )
                        }}
                    />
                </div>
            </div>
            <div>
                <div className="left-right">
                    <h1>{user?.name}</h1> 
                    <ReportButton reportee={user} />              
                </div>
                <h2 className="user-summary-subheading">@{user?.username}</h2>
                <h2 className="user-summary-subheading">🎓 {reputation}</h2>
            </div>
            {
                // If the user is not viewing their own page 
                (user?.id !== userContext.loggedInUser?.id) && (
                    <>
                        <button
                            className={'user-follow-button ' + (isFollowing ? 'following' : 'follow')}
                            onClick={handleFollowClick}
                        >
                            {isFollowing ? 'Following' : 'Follow'}
                        </button>
                    </>
                )
            }
            <div className="user-summary-separator" />
            <h2>Bio</h2>
            <p className="user-summary-text">
                {
                    user.bio
                        ? user.bio.length > 0 ? user.bio : DEFAULT_BIO
                        : DEFAULT_BIO
                }
            </p>
        </div>
    );
};
