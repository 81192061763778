import React from 'react';
import './Topics.scss';
import {Link} from 'react-router-dom';
import Display from '../../components/Display';
import {formatDate} from '../../util';
import {TopicModel} from '../../types';
import {getAllTopics} from '../../services/loaders/topic';
import Modal, {ModalProps} from '../../components/Modal';
import useModal from '../../hooks/useModal';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStickyNote, faUser} from '@fortawesome/free-solid-svg-icons';
import DropDown, {SortByWraps} from '../../components/DropDown';
import DropDownList, {DropDownListEl} from '../../components/DropDownList';
import ModalContext from '../../context/ModalContext';
import DisplayPositioner from '../../components/DisplayPositioner';

// StatsBox variable is used to format the stats in the topics page. It is used in the TopicSummary component.
const StatsBox: React.FC<{
    value: number,
    counters: [string, string],
} | {
    value: string,
    text: string
}> = (props) => {
    return <div className='stats-box'>
        <div className='stats-box-number'>
            {props.value}
        </div>
        <div className='stats-box-text'>
            {(() => {
                if (props.hasOwnProperty('counters')) {
                    const p = props as { value: number, counters: [string, string] };
                    return p.value === 1 ? p.counters[0] : p.counters[1];
                } else {
                    const p = props as { value: string, text: string };
                    return p.text;
                }
            })()}
        </div>
    </div>
}

// TopicSummary variable is used to format the topics in the topics page. It is used in the Sorting component.
const TopicSummary: React.FC<{ windowWidth: number, topic: TopicModel }> = ({ topic, windowWidth }) => {
    const to = `/topics/${topic.name}`;

    const TopicSummaryWide: React.FC = () => (
        <Link to={to} className="topic-summary" variant="wide">
            <img className="topic-icon" src={topic.topicIcon || '/profilepics/pfp-1.jpg'} />
            <div className="name-description">
                <div className="name">
                    {topic.name}
                </div>
                <div className="description">
                    {topic.description}
                </div>
            </div>
            <StatsBox
                value={topic.questions}
                counters={['question', 'questions']}
            />
            <StatsBox
                value={topic.users}
                counters={['user', 'users']}
            />
            <StatsBox
                value={formatDate(topic.createdAt)}
                text='created'
            />
        </Link>
    );

    const TopicSummaryNarrow: React.FC = () => (
        <Link to={to} className="topic-summary" variant="narrow">
            <div className="aside">
                <img className="topic-icon" src={topic.topicIcon || '/profilepics/pfp-1.jpg'} />
            </div>
            <div className="content">
                <h3 className="name">{topic.name}</h3>
                <div className="stats">
                    <div>
                        {topic.users} <FontAwesomeIcon icon={faUser} />
                    </div>
                    <div>|</div>
                    <div>
                        {topic.questions} <FontAwesomeIcon icon={faStickyNote} />
                    </div>
                    <div>|</div>
                    <div>
                        {`${topic.createdAt.getFullYear()}-${topic.createdAt.getMonth() + 1}-${topic.createdAt.getDate()}`}
                    </div>
                </div>
                <p className="description">
                    {topic.description}
                </p>
            </div>
        </Link>
    );

    return windowWidth > 830
        ? <TopicSummaryWide />
        : <TopicSummaryNarrow />;
}


type SortBy = 'alphabetical' | 'users' | 'questions' | 'date';

// Sorting function is used to fetch and manipulate the topics data from the database. It is used in the Topics component.
const TopicList: React.FC<{ sortBy: SortBy }> = ({ sortBy }) => {
    const [topics, setTopics] = React.useState<TopicModel[]>([]);
    const [windowWidth,] = useWindowDimensions();

    React.useEffect(() => {
        getAllTopics()
            .then(res => {
                if (res.tag === 'OK') {
                    setTopics(res.value);
                }
                // TOOD: show message indicating that we could not get topics
            })
    }, []);

    React.useEffect(() => {
        setTopics([...sort(topics, sortBy)]);
    }, [sortBy]);

    return <div className="topic-list" >
        {
            topics.map((topic, key) => (
                <TopicSummary
                    windowWidth={windowWidth}
                    topic={topic}
                    key={topic.id || key}
                />
            ))
        }
    </div>
}

// sort function is used to sort the topics in the topics page.
export function sort(topics: TopicModel[], sortBy: SortBy) {
    switch (sortBy) {
        case 'alphabetical':
            return topics.sort((a, b) => a.name.localeCompare(b.name || '') || 0);
        case 'users':
            return topics.sort((a, b) => (a.users || 0) - (b.users || 0));
        case 'questions':
            return topics.sort((a, b) => (a.questions || 0) - (b.questions || 0));
        case 'date':
            return topics.sort((a, b) => {
                if (a.createdAt && b.createdAt) {
                    return a.createdAt.getTime() - b.createdAt.getTime();
                } else {
                    return 0;
                }
            });
        default:
            return topics;
    }
}

// Topics component is used to display the topics in the topics page.
const Topics: React.FC = () => {
    const createButtonRef = React.useRef<HTMLButtonElement | null>(null);
    const [sortBy, setSortBy] = React.useState<SortBy>('users');
    const [, setAppealModalVis] = useModal(
        <TopicAppealModal key="topic-appeal-modal" doNotHideOnClick={[createButtonRef]} />
    );

    return (
        <DisplayPositioner className="topics-page">
            <Display className="sort-by-container">
                <div className="title-container">
                    <h2 className="topics-title">TOPICS</h2>
                    <button
                        className="create-appeal-button"
                        onClick={() => {
                            setAppealModalVis(true);
                        }}
                        ref={createButtonRef}
                    >
                        Create a new topic
                    </button>
                </div>
                <DropDown
                    wraps={<SortByWraps value={sortBy} />}
                    settings={{
                        scrollBarVisibility: 'hidden'
                    }}
                >
                    <DropDownList>
                        <DropDownListEl onClick={() => setSortBy('alphabetical')}>
                            Alphabetical
                        </DropDownListEl>
                        <DropDownListEl onClick={() => setSortBy('users')}>
                            Users
                        </DropDownListEl>
                        <DropDownListEl onClick={() => setSortBy('questions')}>
                            Questions
                        </DropDownListEl>
                        <DropDownListEl onClick={() => setSortBy('date')}>
                            Date Created
                        </DropDownListEl>
                    </DropDownList>
                </DropDown>
            </Display>
            <TopicList sortBy={sortBy} />
        </DisplayPositioner>
    )
};

export const TopicAppealModal: React.FC<ModalProps> = ({
    style,
    ...props
}) => {
    const { visHandles: [, setVis] } = React.useContext(ModalContext);

    return (
        <Modal
            {...props}
            className="topic-appeal-modal"
        >
            <h2>What is a topic appeal?</h2>
            <br />
            <p>
                If you would like to see a topic on educat
                which does not already exist, you can submit an appeal
                form. Your appeal will be reviewed by an admin before
                being accepted or rejected.
            </p>
            <br />
            <p>
                NOTE: If your appeal is successful, you will be
                responsible for moderating the topic. Failure
                to do so may result in termination of your account
            </p>
            <br />
            <Link
                className='proceed-to-appeal-button'
                to='/submit-topic-appeal'
                onClick={() => setVis(false)}
            >
                I understand, proceed
            </Link>
        </Modal>
    )
}

export default Topics

