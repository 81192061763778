import React from 'react';
import {useNavigate} from 'react-router-dom';
import {getSearchResults} from '../../services/loaders';
import {SearchResponseData} from '../../types';
import {enumerate} from '../../util';
import Input from '../Input';
import './SearchBar.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';

export type SearchBarProps = {};

/**
 * Formats which search results can be
 */
type SearchResult = {
    tag: 'nothing',
} | {
    tag: 'header',
    linkTo: string,
    text: string,
} | {
    tag: 'result',
    linkTo: string,
    text: string,
};


const SearchResultElement: React.FC<{
    className: string,
    text: string,
    link: string,
}> = ({
    className, text, link
}) => {
    const navigate = useNavigate();
    return (
        <div 
            className={className}
            onClick={() => navigate(link)}
        >
            {text}
        </div>
    )
}

/**
 * Methods to convert data into SearchResults
 */
export namespace SearchResult {
    export const nothing: () => SearchResult = () => ({ tag: 'nothing' });

    export function header(text: string, linkTo: string): SearchResult {
        return { 
            tag: 'header',
            text,
            linkTo
        };
    }

    export function result(text: string, linkTo: string): SearchResult {
        return {
            tag: 'result',
            linkTo,
            text,
        }
    }

    /**
     * Converts a SearchResult to a ReactNode
     * @param result SearchResult
     * @returns Formatted ReactNode representing a search result
     */
    export function intoReactNode(
        result: SearchResult,
        key?: string,
    ): React.ReactNode {
        let props = key === undefined ? { key } : {};

        switch (result.tag) {
            case 'nothing':
                return <div 
                    className="search-result-nothing" 
                    {...props}
                >
                    No search results!
                </div>;
            case 'header':
                return <SearchResultElement 
                    className='search-result-header'
                    text={result.text}
                    link={result.linkTo}
                />
            case 'result':
                return <SearchResultElement 
                    className='search-result-result'
                    text={result.text}
                    link={result.linkTo}
                />
        }
    }
}

/**
 * Converts raw data from server into SearchResults which can then be
 * transformed into a ReactNode
 * @param data response.data sent by server
 * @returns 
 */
export function formatSearchResults(data: SearchResponseData): SearchResult[] {
    if (!data.questions && !data.topics && !data.users) {
        return [SearchResult.nothing()];
    }

    function getHeader(header: string, link: string): SearchResult[] {
        return [SearchResult.header(header, link)]
    }
    function checkNullResults(mapResults: SearchResult[]) {
        if (mapResults.length === 0) {
            return [SearchResult.nothing()];
        }
        return mapResults
    }

    function getSectionResults(data: SearchResponseData)
    :SearchResult[] {

        let res = getHeader('Users', '/');
        let userResults = data.users.map(
            user => SearchResult.result(
                user.username, '/users/' + user.username
            )
        );
        res = res.concat(checkNullResults(userResults));

        res = res.concat(getHeader('Topics', '/topics'));
        let topicResults = data.topics.map(
            topic => SearchResult.result(
                topic.name, '/topics/' + topic.name
            )
        );
        res = res.concat(checkNullResults(topicResults));

        res = res.concat(getHeader('Questions', '/'));
        let questionResults = data.questions.map(
            question => SearchResult.result(
                question.title, '/question/' + question.id
            )
        );
        res = res.concat(checkNullResults(questionResults));
        return res;
    }
    const res = getSectionResults(data);
    return res;
}

/**
 * @param results Array of SearchResult
 * @returns HTMLElement containing List of ReactNodes
 */
const SearchResults: React.FC<{
    results: SearchResult[],
}> = ({ results }) => {
    const resultNodes = enumerate(results)
        .map(([i, value]) => (
            SearchResult.intoReactNode(value, i.toString())
        ))
    return <div className="search-results">
        {resultNodes}
    </div>;
}

/**
 * @returns Search bar which sends search requests 00ms after user
 * stops typing
 */
const SearchBar: React.FC<SearchBarProps> = ({ }) => {
    const [results, setResults] = React.useState<SearchResult[]>([]);
    const [focus, setFocus] = React.useState(false);

    return <div className="search-bar-positioner">
        <div className="search-bar-container">
            <div className="search-bar">
                <Input
                    onChange={async (text) => {
                        const data = await getSearchResults({input: text});
                        if(data.tag === 'OK'){
                            setResults(
                                formatSearchResults(
                                    data.value as SearchResponseData
                                )
                            )
                        }
                    }}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    updateDelay={500}
                    placeholder="search educat.dev"
                />
                <label 
                    className="magnifying-glass"
                >
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </label>
            </div>
            <div
                className="search-results-positioner"
                style={{
                    visibility: focus ? 'visible' : 'hidden',
                }}
            >
                <SearchResults results={results} />
            </div>
        </div>

    </div>
}

export default SearchBar;