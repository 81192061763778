import * as Yup from 'yup';
import {UserModel} from '../../types';
import SubmitButton from '../../components/SubmitButton';
import LabelledField from '../../components/LabelledField';
import {updateUserAccount} from '../../services/actions/user';
import {updateUserInfoBrowser} from '../../components/Header/StatusModal';
import useUserContext from '../../hooks/useUserContext';
import React from 'react';
import {Form, Formik} from 'formik';

interface Status {
    success: boolean,
    message: string,
}

export type UpdateUserData = {
    name?: string,
    bio?: string,
    currentPassword?: string,
    password?: string,
    profileIcon?: string
}

const EditProfile: React.FC<Omit<UserModel, 'password'>> = ({ ...user }) => {
    const userContext = useUserContext();

    const [status, setStatus] = React.useState<{
        success: boolean,
        message: string,
    }>();

    return <>
        <Formik
            initialValues={{
                name: user.name ? user.name : '',
                bio: user.bio ? user.bio : '',
            }}
            validationSchema={Yup.object({
                name: Yup.string()
                    .required('State your name cuz')
                    .min(3, 'Must be cat least 3 chararacters pwease')
                    .max(50, "Must be less than 50 characters")
                    .matches(/^[a-zA-Z0-9_\-]*$/, 'Cat-tains invalid characters'),
            })}
            onSubmit={values => {
                updateUserAccount({ name: values.name, bio: values.bio })
                    .then((res) => {
                        if (res.tag === 'OK') {
                            setStatus({
                                success: true,
                                message: 'Changes saved!'
                            });
                            updateUserInfoBrowser(res.value?.user as UserModel, userContext)
                        } else {
                            setStatus({
                                success: false,
                                message: 'Something went wrong :( Please try again.'
                            });
                        }
                    })
            }}
        >
            <Form
                style={{ width: '100%' }}
                onChange={() => setStatus(undefined)}>
                <LabelledField labelText="Name" name="name" />
                <LabelledField
                    as="textarea"
                    labelText="Bio"
                    name="bio"
                    id="bio"
                />
                <SubmitButton>Save Changes</SubmitButton>
                {status && <div
                    className="status-message"
                    style={{
                        color: status.success ? 'green' : 'red',
                    }}
                >
                    {status.message}
                </div>}
            </Form>
        </Formik>
    </>
}

export default EditProfile;