import React from 'react';
import Display from '../../components/Display';

const McHelpDisplay: React.FC = () => (
    <Display className="help" title="HELP">
        <div className="help-body">
            Create a multiple-choice question. Create some incorrect options by clicking the 
            '+' icon at in the form and at least one correct option by clicking the 
            <i>other</i> '+' icon. Then click 'Create'!
        </div>
    </Display>
)

export default McHelpDisplay;