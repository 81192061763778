import React from 'react';
import Question from '../../components/Question';
import {RaisedDisplay} from '../../components/Display';
import {Full, QuestionModel, QuestionModelWithoutAnswer, Result} from '../../types';
import {ParseError} from './util';

const QuestionPreview: React.FC<{ question: Result<Full<QuestionModel>, ParseError> }> = ({ question }) => (
    <div className="preview-container">{
        question.tag === 'OK'
            ? <Question fullQuestion={question.value as Full<QuestionModelWithoutAnswer>} />
            : <RaisedDisplay className="invalid-question-preview">
                invalid fields (no preview)
            </RaisedDisplay>
    }</div>
)

export default QuestionPreview;