import React from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';

/**
 * 
 * @param src source of image
 * @returns HTMLElement which displays the image if window is more than 500px
 * wide
 */
const LoginSignupImage: React.FC<{ src: string }> = ({ src }) => {
    const [windowWidth,] = useWindowDimensions();

    return <>{
        windowWidth > 550
            ? <div>
                <img src={src} className="login-signup-image" />
            </div>
            : <></>
    }</>
}
export default LoginSignupImage;