import React from 'react';
import './DropDown.scss';
import useExternalClick from '../../hooks/useExternalClick';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';

export type DropDownProps = React.PropsWithChildren<{}>
    & {
        wraps?: React.ReactNode | string,
        settings?: {
            arrowPos?: 'left' | 'right' | 'hidden',
            outlineOnSelect?: 'weak' | 'none',
            scrollBarVisibility?: 'visible' | 'hidden',
        },
    } & React.HTMLAttributes<HTMLDivElement>;

/**
 * displays a little sorting icon with some text -- ideal for the `wraps` attribute
 * @param value sets the text after the sorting icon thing :)
 */
export const SortByWraps: React.FC<{ value?: string }> = ({ value = 'sort' }) => (
    <span className="drop-down-sort-by-wraps">
        <FontAwesomeIcon icon={faBars} /> {value}
    </span>
)

/**
 * Displays a drop-down, that when clicked displays a specified menu,
 * - set `wraps` to the content that the should be displayed alongside the
 *   dropdown. This can either be a `string` (nice values will be set to
 *   make this look good), or it can be a `ReactNode` (as little styling
 *   as possible will be set)
 * - the element's children will be displayed on click. If you want a nice
 *   default -- use a `DropDownList`
 * - `<DropDown>` should be treated as a `<div>` in the document flow.
 * ```
 * <DropDown wraps="example drop-down">
 *     <DropDownList>
 *         <DropDownListEl>Option 1</DropDownListEl>
 *         <DropDownListEl>Option 2</DropDownListEl>
 *         <DropDownListEl>Option 3</DropDownListEl>
 *     </DropDownList>
 * </DropDown>
 * ```
 * 
 * TODO: shift left or right to keep on screen
 */
const DropDown: React.FC<DropDownProps> = ({
    children,
    settings = {},
    wraps = "",
    ...divProps
}) => {
    settings = {
        arrowPos: settings.arrowPos || 'right',
        outlineOnSelect: settings.outlineOnSelect || 'weak',
        scrollBarVisibility: settings.scrollBarVisibility || 'visible',
    };
    const wrapsNode: React.ReactNode = typeof wraps == 'string'
        ? <div className="default-wraps">
            <div style={{ fontSize: '13px' }}>
                {wraps}
            </div>
        </div>
        : wraps;

    const [clicked, setClicked] = React.useState(false);
    const menuRef = React.useRef<HTMLDivElement>(null);
    const menuChildrenRef = React.useRef<HTMLDivElement>(null);
    const dropDownRef = useExternalClick<HTMLDivElement>(() => {
        setClicked(false);
    });

    React.useEffect(() => {
        if (menuRef.current) {
            // TODO: maybe we should not have this clip at the bottom of the screen?
            const top = menuRef.current.getBoundingClientRect().top;
            const desiredMaxHeightPx = menuChildrenRef.current?.clientHeight || 200;
            const maxMaxHeightPx = document.documentElement.clientHeight - top;
            const maxHeightPx = desiredMaxHeightPx > maxMaxHeightPx
                ? maxMaxHeightPx
                : desiredMaxHeightPx;

            const s = menuRef.current.style;
            if (clicked) {
                s.maxHeight = `${maxHeightPx}px`;
                s.outlineWidth = '1px';
            } else {
                s.maxHeight = '0px';
                s.outlineWidth = '0px';
            }
        }
    }, [clicked])

    const arrow = <img
        src="/black-triangle.svg"
        className="arrow"
        style={{
            transform: clicked ? 'rotate(180deg)' : '',
        }}
    />;

    const menu = <div
        className="drop-down-children"
        ref={menuRef}
        style={{
            overflowY: settings.scrollBarVisibility === 'visible'
                ? 'scroll'
                : 'hidden'
        }}
    >
        <div ref={menuChildrenRef}>
            {children}
        </div>
    </div>;

    return <div ref={dropDownRef} {...divProps}>
        <div
            className="drop-down"
            onClick={() => {
                setClicked(true);
            }}
            style={{
                outlineColor: clicked && settings.outlineOnSelect != 'none'
                    ? 'rgba(0, 0, 0, 0.2)'
                    : '',
            }}
        >
            {settings.arrowPos === 'left' ? arrow : <></>}
            {wrapsNode}
            {settings.arrowPos === 'right' ? arrow : <></>}
        </div>
        <div className="menu-positioner">
            {menu}
        </div>
    </div>
}

export default DropDown;