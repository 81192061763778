import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import usePathMeta from '../../hooks/usePathMeta';
import useUserContext from '../../hooks/useUserContext';
import DropDown from '../DropDown';
import DropDownList, {DropDownListLink} from '../DropDownList';
import {faBookOpen, faFlag, faHouse, faList, faPray} from '@fortawesome/free-solid-svg-icons';

/**
 * @returns A Dropdown used to navigate the website
 */
const NavigationDropdown: React.FC = () => {
    const pathMeta = usePathMeta();
    const userContext = useUserContext();

    return (
        <DropDown
            className="navigation-drop-down"
            wraps={<div className="navigation-drop-down-wraps">{pathMeta.title}</div>}
            settings={{
                outlineOnSelect: 'none',
                scrollBarVisibility: 'hidden',
            }}
        >
            <DropDownList>
                <DropDownListLink
                    iconDisplay="left"
                    icon={<FontAwesomeIcon icon={faHouse} />}
                    to="/"
                >
                    Home
                </DropDownListLink>
                <DropDownListLink
                    iconDisplay="left"
                    icon={<FontAwesomeIcon icon={faBookOpen} />}
                    to="/feed"
                >
                    Feed
                </DropDownListLink>
                <DropDownListLink
                    iconDisplay="left"
                    icon={<FontAwesomeIcon icon={faList} />}
                    to="/topics"
                >
                    Topics
                </DropDownListLink>
                {
                    userContext.loggedInUser?.isAdmin &&
                    <>
                        <DropDownListLink
                            iconDisplay="left"
                            icon={<FontAwesomeIcon icon={faFlag} />}
                            to="/reports"
                        >
                            Reports
                        </DropDownListLink>
                        <DropDownListLink
                            iconDisplay="left"
                            icon={<FontAwesomeIcon icon={faPray} />}
                            to="/topic-appeals"
                        >
                            Topic Appeals
                        </DropDownListLink>
                    </>
                }
            </DropDownList>
        </DropDown>


    )
}

export default NavigationDropdown