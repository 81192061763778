import React from 'react';
import './TeamFooter.scss';

interface TeamMemberProps {
    imgSrc: string;
    name: string;
    role: string;
}

const TeamMember: React.FC<TeamMemberProps> = ({ imgSrc, name, role }) => (
    <div className="team-member-container">
        <img className="team-member-image" src={imgSrc}/>
        <div className="team-member-name">{name}</div>
        <div className="team-member-role">{role}</div>
    </div>
);


const TeamFooter: React.FC = () => (
    <div className="team-footer-container">
        <div className="team-footer-title">THE TEAM</div>
        <div className="team-members-container">
            <TeamMember imgSrc="./profilepics/pfp-1.jpg" name="Oli" role="Frontend Manager" />
            <TeamMember imgSrc="./profilepics/pfp-2.jpg" name="Shan" role="Chief Tester" />
            <TeamMember imgSrc="./profilepics/pfp-3.jpg" name="Min" role="MINutes" />
            <TeamMember imgSrc="./profilepics/pfp-4.jpg" name="Vishal" role="Diversity Leader" />
            <TeamMember imgSrc="./profilepics/pfp-5.jpg" name="Ronan" role="Social Sec" />
            <TeamMember imgSrc="./profilepics/pfp-6.jpg" name="Ceren" role="Authentication Lead" />

            <TeamMember imgSrc="./profilepics/pfp-9.jpg" name="Aidan" role="Backend Manager" />
            <TeamMember imgSrc="./profilepics/pfp-8.jpg" name="Melane" role="Backend" />

            <TeamMember imgSrc="./profilepics/pfp-7.jpg" name="Maayan" role="Manager" />
        </div>
    </div>
);

export default TeamFooter;