import React from 'react';
import {Link} from 'react-router-dom';
import Radio from '../../components/Radio';
import {Full, QuestionModelWithoutAnswer} from '../../types';
import {formatDate} from '../../util';

export interface QuestionPreview {
    id: string,
    date: Date,
    upvotes: number,
    description: string,
}

/**
 * A preview of a question displaying some information about the question and 
 * links to the question, creator and topic pages
 */
const CardPreview: React.FC<{ question: Full<QuestionModelWithoutAnswer> }> = ({ question }) => {
    const parentRef = React.useRef(null);
    const childRef = React.useRef(null);

    return (
        <Link to={`/question/${question.id}`} className="users-card-preview">
            {/* TODO: link this with the actual rating */}
            <div
                className="card-preview-upvotes"
                variant={question.overallRating >= 0 ? 'positive' : 'negative'}
            >
                {question.overallRating}
            </div>
            <div className="card-preview-title" ref={parentRef}>
                <div ref={childRef}>
                    {question.title}
                </div>
            </div>
            <div className="card-preview-date">
                {formatDate(question.createdAt)}
            </div>
        </Link>
    )
}

type UserRecentProps = {
    questionsCreated: Full<QuestionModelWithoutAnswer>[],
    questionsAnswered: Full<QuestionModelWithoutAnswer>[],
}

/**
 * A panel showing the user's recent questions that they have answered and created.
 * The component uses the Radio component which then changes state to invoke an API
 * call which fetches the data displayed
 */
export const UserRecent: React.FC<UserRecentProps> = ({ questionsCreated, questionsAnswered }) => {
    const [recentSection, setRecentSection] = React.useState("Answered");

    return (
        <div className="user-recent users-panel-container">
            <h1 className="users-panel-heading">RECENT</h1>
            <Radio
                options={["Answered", "Created"]}
                style={{
                    justifyContent: "flex-start",
                    gap: "10px",
                    margin: "10px 0"
                }}
                onChange={(active: string) => setRecentSection(active)}
            />
            <div className="users-card-preview-container">
                {
                    (
                        recentSection === 'Answered'
                            ? questionsAnswered
                            : questionsCreated
                    )?.map(question => (
                        <CardPreview key={question.id} question={question} />
                    ))
                }
                {
                    recentSection === 'Created'
                        ? <div className="see-all">
                            <Link to="questions">see all...</Link>
                        </div>
                        : null
                }
            </div>
        </div>
    )
}

export default UserRecent;