import React from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Feed from '../../components/Feed';
import {getTopicByFullname} from '../../services/loaders';
import '../../styles/index.scss';
import FeedHeader, {FeedHeaderFilters} from '../../components/FeedHeader';
import {GetQuestionQueryParams, Result, StatusCode, TopicModel} from '../../types';
import BadRequestErrorDisplay from '../../components/BadRequestErrorDisplay';
import DisplayPositioner, {StickySideDisplays} from '../../components/DisplayPositioner';
import Display from '../../components/Display';
import SubmitButton from '../../components/SubmitButton';
import ThinHr from '../../components/ThinHr';
import './TopicsTopic.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComments} from "@fortawesome/free-solid-svg-icons/faComments";
import {faTrophy} from '@fortawesome/free-solid-svg-icons';
import {followTopic} from '../../services/actions/topic';
import useUserContext from '../../hooks/useUserContext';

export type TopicsTopicProps = {};

const TopicsTopic: React.FC<TopicsTopicProps> = () => {
    const { topicName } = useParams();
    const [topic, setTopic] = React.useState<null | TopicModel>(null);
    const [errorCode, setErrorCode] = React.useState<StatusCode>(418);
    type SortBy = 'Oldest' | 'Newest' | 'Best' | 'Worst';
    const sortByOptions: SortBy[] = ['Oldest', 'Newest', 'Best', 'Worst'];
    const [filters, setFilters] = React.useState<GetQuestionQueryParams>({});
    const [feedHeaderFilters, setFeedHeaderFilters] = React.useState<FeedHeaderFilters>({
        sortBy: 'Newest',
        tags: [],
        usernames: [],
    });
    const { loggedInUser } = useUserContext();
    const navigate = useNavigate();

    function handleFollowClick() {
        if (loggedInUser && topic) {
            followTopic({ followerId: loggedInUser.id, topicId: topic.id })
                .then(result => Result.map(result, () => {
                    setTopic({ ...topic, following: !topic.following })
                }))
        }
    }

    // filter by author name

    React.useEffect(() => {
        let f: GetQuestionQueryParams;

        switch (feedHeaderFilters.sortBy as SortBy) {
            case 'Oldest':
                f = GetQuestionQueryParams.oldest();
                break;
            case 'Newest':
                f = GetQuestionQueryParams.newest();
                break;
            case 'Worst':
                f = GetQuestionQueryParams.worst();
                break;
            case 'Best':
                f = GetQuestionQueryParams.best();
                break;
        }


        setFilters({ ...f, tags: feedHeaderFilters.tags, authorIds: feedHeaderFilters.usernames });
    }, [feedHeaderFilters]);


    React.useEffect(() => {
        if (!topicName) {
            return;
        }
        getTopicByFullname(topicName).then(
            t => {
                if (t.tag === 'OK') {
                    setTopic(t.value);
                } else {
                    setErrorCode(t.error?.statusCode || 404);
                }
            }
        )
    }, []);

    return topic
        ? <DisplayPositioner
            aside={<>
                <Display>
                    <h2>{topic.name.toUpperCase()}</h2>
                    <div className="topic-aside-stats">
                        {topic.questions} questions | {topic.users} users
                    </div>
                    <p className="topic-aside-description">
                        {topic.description}
                    </p>
                    <button 
                        className={'topic-follow-button ' + (topic.following ? 'invert' : '')} 
                        onClick={() => {
                            handleFollowClick()
                        }}
                    >
                        {topic.following ? 'Following' : 'Follow'}
                    </button>
                </Display>
                <Display>
                    <Link to="leaderboard">
                        <FontAwesomeIcon icon={faTrophy} />
                        <span style={{ marginLeft: 10 }}>Leaderboard</span>
                    </Link>
                    <div style={{ height: 10 }} />
                    <Link to={`/topics/${topic.name}/chat`}>
                        <FontAwesomeIcon icon={faComments} />
                        <span style={{ marginLeft: 10 }}>Chat</span>
                    </Link>
                </Display>
                <StickySideDisplays>
                    <Display>
                        <p>
                            You can create questions on this topic, too! Set the
                            topic name to '{topic.name}'. To post here.
                        </p>
                        <ThinHr />
                        <SubmitButton invert onClick={() => navigate('/create')}>
                            Create Question
                        </SubmitButton>
                    </Display>
                </StickySideDisplays>
            </>}
        >
            <Feed
                feedHeader={
                    <FeedHeader
                        title={`topics/${topicName}`}
                        sortByOptions={sortByOptions}
                        filtersHandles={[feedHeaderFilters, setFeedHeaderFilters]}
                        filterByTags
                        filterByUsernames
                    />
                }
                filters={{
                    ...{
                        topicIds: [topic.id],
                        ...filters,
                    }
                }}
            />
        </DisplayPositioner>
        : <BadRequestErrorDisplay errorCode={errorCode} />
}

export default TopicsTopic;