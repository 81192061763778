import React from 'react';
import {Navigate} from 'react-router-dom';
import LoginSignupModal from '../../components/Header/LoginSignupModal';
import useModal from '../../hooks/useModal';
import useUserContext from '../../hooks/useUserContext';
import './Home.scss';
import HomeInfo from './HomeInfo';
import TeamFooter from './TeamFooter';


// import Button from '../../components/Button';
// import HomeHeader from './HomeHeader';
// import HomeInfo from './HomeInfo'
// import TeamFooter from './TeamFooter'

const HomeHeader: React.FC = () => {
    const loginButtonRef = React.useRef(null);
    const signupButtonRef = React.useRef(null);

    const [modalVis, setModalVis] = useModal(
        <LoginSignupModal
            key="login-signup-modal-home"
            doNotHideOnClick={[loginButtonRef, signupButtonRef]}
        />
    );

    return <div className="home-header">
        <div className="home-header-content">
            <div className="home-title">
                MEOW! WELCOME TO EDUCAT
            </div>
            <div className="home-description">
                <i>"A great place to learn... (long pause) with your friends *claps* *yawns*"</i> - Meowlane
                <br />
                <i>"It will never get done on time"</i> - Meowan
                <br />
                <i>"I am not coming into labs today actually, sorry"</i> - Purronan
                <br />
                <i>"Guys can someone please smile"</i> - Olifur
            </div>
            <div className="home-login-signup-container">
                <button
                    className="home-login-button"
                    ref={loginButtonRef}
                    onClick={() => setModalVis(true)}
                >
                    Log in
                </button>
                <button
                    className="home-signup-button"
                    ref={signupButtonRef}
                    onClick={() => setModalVis(true)}
                >
                    Sign up
                </button>
            </div>
        </div>
    </div>
}

const Home: React.FC = () => {
    const { loggedInUser } = useUserContext();

    if (loggedInUser) {
        return <Navigate replace to="/feed" />;
    }

    return <div className="home">
        <HomeHeader />
        <div className="home-info-list">
            <HomeInfo
                graphicSrc='chat.png'
                type="alt"
                description={
                    'Topic-based chat allows you to chat with other users who might be interested in the same'
                    + ' things as you. Questions can be shared with other users -- so perhaps you could ask'
                    + ' for some help on a question you got wrong :)'
                }
                title='Chat with other users'
            />
            <HomeInfo
                graphicSrc='feed-home.png'
                type="default"
                description={
                    'Follow users and topics, then build up a personalised feed. Every time a user you follow'
                    + ' posts a new questions, you\'ll see it in your feed! Don\'t worry -- you can filter your'
                    + ' feed as well -- only want posts by users you follow tagged \'cats\'? No problem.'
                    + ' Only want posts from tagged with \'rust\', \'tokio\' or \'rust-supremacy\'? No problem'
                    + ' either!'
                }
                title='A personalised feed'
            />
            <HomeInfo
                graphicSrc='question-home.png'
                type="alt"
                description={
                    'The questions on this site are built by people like you -- the community! Think you have'
                    + ' something interesting to share? Well, make some questions about it! We have lots of'
                    + ' different question formats, so you can choose whatever suits your question best. Doing'
                    + ' a question about french grammar? Maybe fill-in-the-gaps... What about history? Perhaps'
                    + ' multiple choice would do the trick :)'
                }
                title='Create questions of your own'
            />
            <HomeInfo
                graphicSrc='leaderboard-home.png'
                type="default"
                description={
                    'If you get enough questions right, you could climb up the weekly, monthly or even all-time'
                    + ' leaderboard! Imagine how great it would be to brag to your friends about how you\'re'
                    + ' #1 all time in \'rust-supremacy\'!'
                }
                title='Climb up the leaderboard'
            />
        </div>
        <TeamFooter />
    </div>
}
export default Home;