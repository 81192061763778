import React from 'react';
import {GetQuestionQueryParams, PaginatedQueryParams, SetStateAction} from '../../types';
import Question from '../Question';
import {getQuestions} from '../../services/loaders/question';


export type QuestionLoader = (count: number) => void;

/**
 * @returns `[questions, laodQuestions, reset]` where `questions` and
 * `setQuestions` are normal state handles and `loadQuestions` allows you to load
 * some more questions e.g. `loadQuestions(5)` will fetch some more questions and 
 * call `setQuestions` internally to update `questions`.
 *
 * This can be passed a custom question component to use instead of the default
 * `Question` component. This is done with the following:
 * 
 * `reset` allows you to reset the questionLoader to its inital state
 * 
 * NOTE: `loadQuestions` is not guaranteed to actually load anything, it will just
 * fail and do nothing if the questions are not available.
 * 
 * NOTE: this is unordered at the moment, loads will guarantee to load the specified
 * number of questions, and will guarantee to not overlap with other questions, but
 * will not guarantee questions be ordered exactly according to the filters provided
 * 
 * TODO: make this link to the API
*/
export default function useQuestionLoader(
    filters: GetQuestionQueryParams
): [React.ReactElement[], SetStateAction<React.ReactElement[]>, QuestionLoader, () => void] {
    const initialCommandQueue = {
        command: { skip: 0, take: 0 },
        dormant: true,
    };

    const [questions, setQuestions] = React.useState<React.ReactElement[]>([]);
    const [commandQueue, setCommandQueue] = React.useState<{
        command: PaginatedQueryParams,
        dormant: boolean,
    }>(initialCommandQueue);

    const loadQuestions = (count: number) => {
        setCommandQueue(cq => ({
            command: { skip: cq.command.skip, take: cq.command.take + count },
            dormant: false,
        }));
    }

    React.useEffect(() => {
        if (commandQueue.dormant) {
            return;
        }

        setCommandQueue(cq => ({
            command: { skip: cq.command.skip + cq.command.take, take: 0 },
            dormant: true,
        }));

        (async () => {
            let questionNodes: React.ReactElement[] = [];

            filters.skip = commandQueue.command.skip;
            filters.take = commandQueue.command.take;
            const qResult = await getQuestions(filters);
            if (qResult.tag === 'ERR') {
                return [];
            }

            for (let question of qResult.value.questions) {
                questionNodes.push(<Question
                    key={question.id}
                    fullQuestion={question}
                    />);
                }
                
            return questionNodes;
        })().then(questionNodes => {
            setQuestions(qs => {
                const keySet = new Set(qs.map(q => q.key));
                return qs.concat(questionNodes.filter(q => !keySet.has(q.key)));
            });
        });
    }, [commandQueue.dormant, commandQueue.command.skip, commandQueue]);

    const reset = () => {
        setQuestions([]);
        setCommandQueue({...initialCommandQueue});
    };

    return [questions, setQuestions, loadQuestions, reset];
}

