import "./Logo.scss";

export type LogoProps = {
    displayText?: boolean,
}

/**
 * Container-fitting logo component -- currently placeholder
 * 
 * Use `displayText={false}` for just the image
 */
const Logo: React.FC<LogoProps> = ({
    displayText = true,
}) => (
    <div className="logo">
        <div>
            🐱
            <div
                className="logo-text"
                style={displayText ? {} : { maxWidth: '0px', visibility: 'hidden' }}
            >
                educat.dev
            </div>
        </div>
    </div>
);

export default Logo;
