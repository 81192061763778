import React from 'react';
import './Feed.scss';
import {documentHeight, sleep} from '../../util';
import RawFeed from './RawFeed';
import useScrollTwipwire from '../../hooks/useScrollTripwire';
import useQuestionLoader from './useQuestionLoader';
import {GetQuestionQueryParams} from '../../types';

const RATE_LIMIT_INTERVAL = 20000; // in ms (of course)

export type FeedProps = {
    filters: GetQuestionQueryParams,
    feedHeader?: React.ReactElement<any, any> | null,
};

// TODO: consider performance impact of this (a.k.a not getting rid of old questions)
const Feed: React.FC<FeedProps> = ({ filters, feedHeader }) => {
    const [questions, , loadQuestions, resetQuestionLoader] = useQuestionLoader(filters);

    React.useEffect(() => {
        resetQuestionLoader();
        loadQuestions(5);
    }, [filters]);

    // keep loading until the page is full, so we can start loading more when we scroll
    React.useEffect(() => {
        if (window.innerHeight >= documentHeight(document) * 1.1) {
            loadQuestions(2);
        }
    }, [questions]);

    // there are extensive docs for this... triggers when we scroll to the bottom of 
    // the window in this case
    useScrollTwipwire([document, window], 0.5, () => {
        loadQuestions(50);
        return sleep(RATE_LIMIT_INTERVAL);
    });

    return <>
        {feedHeader}
        <RawFeed questions={questions} />
    </>;
}

export default Feed;
export { RawFeed, useQuestionLoader };