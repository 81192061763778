import axios from "axios";
import {ApiResult, Full, Response, Result, Thin, UserModel, UserResponseData} from "../../types";
import {requireProperties} from "../../util";
import {apiUri, getDefaultAxiosConfig} from "../axiosUtils";
import {getTopicByFullname, getTopicModerators} from "./topic";

/**
 * @param pk a primary key of the user to be fetched (either the username or Id)
 * @returns User object without password or undefined if user does not exist
 * user = {id, name, username, email, bio, proficIcon}
 */
export async function getUserByPk(pk: string): Promise<ApiResult<Full<UserResponseData>>> {
    const { data } = await axios.get<Response<UserResponseData>>(
        apiUri('user', pk),
        getDefaultAxiosConfig<void>()
    );

    const result = Response.assumeSuccess(data);
    return mapUserResponseData(result);
}

/**
 * make the ok varaiant of a ApiResult<Thin<UserResponseData>> full, or err
 */
export function mapUserResponseData(result: ApiResult<Thin<UserResponseData>>): ApiResult<Full<UserResponseData>> {
    if (result.tag === 'OK') {
        if (!result.value) {
            return Result.err(null);
        }

        const required = requireProperties(result.value, UserResponseData.KEYS);
        if (!required) {
            return Result.err(null);
        }

        const userRequired = requireProperties(required.user, UserModel.KEYS_WITHOUT_PASSWORD);
        if (!userRequired) {
            return Result.err(null);
        }

        required.user = userRequired;
        return Result.ok(required as Full<UserResponseData>);
    } else {
        return result;
    }
}

/**
 * @param user User to check moderation status of
 * @param topicFullName name of the topic 
 * @returns True if user is moderator of topic else false
 */
export async function isModeratorOf(user: Omit<UserModel, 'password'>, topicId: string) {
    let topicFullName = ''
    const topicRes = await getTopicByFullname(topicId);
    if (topicRes.tag === 'OK') {
        topicFullName = topicRes.value.name;
    };
    return isModeratorOfTopicName(user, topicFullName);
}

/**
 * Same as `isModeratorOf` but takes a topicName instead 
 */
export async function isModeratorOfTopicName(user: Omit<UserModel, 'password'>, topicName: string): Promise<boolean> {
    const res = await getTopicModerators(topicName);
    if (res.tag === 'OK') {
        if (res.value?.moderators) {
            return res.value.moderators.find(mod => mod.username === user.username)
                ? true
                : false
        }
    };
    return false;
}