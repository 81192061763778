import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react'
import Collapsible from 'react-collapsible';
import {Link} from 'react-router-dom';
import {approveTopicAppeal} from '../../services/actions/topicAppeal';
import {getUserByPk} from '../../services/loaders/user';
import {Full, Result, TopicAppealModel, UserResponseData} from '../../types';
import './TopicAppeal.scss'

const TopicAppealDisplay: React.FC<{ topicAppeal: TopicAppealModel }> = ({ topicAppeal }) => {
    const [userData, setUserData] = React.useState<null | Full<UserResponseData>>(null);
    const [approved, setApproved] = React.useState(topicAppeal.approved);

    React.useEffect(() => {
        getUserByPk(topicAppeal.authorId)
            .then(result => Result.map(result, userData => setUserData(userData)))
    }, []);

    return <div className="topic-appeal-display">
        <div className="top-bar">
            <div className="id">
                {topicAppeal.id}
            </div>
            <Link
                to={`/users/${userData?.user.username || topicAppeal.authorId}`}
                className="username"
            >
                <span className="submitted-by">submitted by:</span> {userData?.user.username || 'loading...'}
            </Link>
        </div>
        <div className="body-positioner">
            <div className="body">
                <div className="icon-with-name">
                    <img className="icon" src={topicAppeal.topicIcon} />
                    <div className="name">
                        {topicAppeal.name}
                    </div>
                </div>
                <Collapsible
                    trigger={<div className="subtitle">
                        <FontAwesomeIcon icon={faAngleDown} /> Topic Description
                    </div>}
                >
                    <p>
                        {topicAppeal.description}
                    </p>
                </Collapsible>
                <Collapsible
                    trigger={<div className="subtitle">
                        <FontAwesomeIcon icon={faAngleDown} /> Reason
                    </div>}
                >
                    <p>
                        {topicAppeal.reason}
                    </p>
                </Collapsible>
            </div>
            <div className="manage-buttons">
                <button
                    className={'approve ' + (approved ? 'approved' : '')}
                    onClick={() => {
                        approveTopicAppeal(topicAppeal.id)
                            .then(success => success ? setApproved(true) : undefined)
                    }}
                >
                {approved ? 'Approved' : 'Approve'}
            </button>
        </div>
    </div>
    </div >
}

export default TopicAppealDisplay;