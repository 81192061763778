import React from 'react';
import FancyPaginated from '../../components/Paginated/FancyPaginated';
import Question from '../../components/Question';
import {getQuestions} from '../../services/loaders/question';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import useUserContext from '../../hooks/useUserContext';
import FeedHeader from '../../components/FeedHeader';
import {GetQuestionQueryParams} from '../../types';
import {useParams} from 'react-router-dom';
import BadRequestErrorDisplay from '../../components/BadRequestErrorDisplay';

const UsersUsernameQuestions: React.FC = () => {
    const userContext = useUserContext();
    const { username } = useParams();

    const [count, setCount] = React.useState<number>(5);

    const [filters, setFilters] = React.useState<{
        sortBy?: string,
    }>({
        sortBy: 'Oldest',
    });

    const sortByParams = (() => {
        switch (filters.sortBy) {
            case 'Oldest':
                return GetQuestionQueryParams.oldest();
            case 'Newest':
                return GetQuestionQueryParams.newest();
        }
    })()

    return username
        ? <DndProvider backend={HTML5Backend}>
            <FancyPaginated
                count={count}
                resultsPerPage={4}
                get={getQuestions}
                queryParams={
                    userContext.loggedInUser
                        ? { authorIds: [userContext.loggedInUser?.id], ...sortByParams }
                        : {}
                }
            >
                {result => {
                    if (result.tag === 'OK') {
                        setCount(result.value.count);
                        return <>
                            <FeedHeader
                                filtersHandles={[filters, setFilters]}
                                sortByOptions={['Newest', 'Oldest']}
                                title={`${username}'s questions`}
                            />
                            {result.value.questions.map((question, key) => (
                                <Question key={key} fullQuestion={question} />
                            ))}
                        </>;
                    }
                    return null;
                }}
            </FancyPaginated>
        </DndProvider>
        : <BadRequestErrorDisplay errorCode={404} />;
}

export default UsersUsernameQuestions;