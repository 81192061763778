import {io} from 'socket.io-client';

const URI = process.env.NODE_ENV === 'production' ? '/' : 'localhost:5000/'

export const socket = (token: string) => {
    return io(URI, {
        query: {
            token
        },
        autoConnect: false,
    });
};