import React from 'react';
import {UserModel, UseStateReturnType} from '../../types';
import './Account.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faImage} from '@fortawesome/free-solid-svg-icons';
import Modal from '../../components/Modal';
import SubmitButton from '../../components/SubmitButton';
import useModal from '../../hooks/useModal';
import {updateUserAccount} from '../../services/actions/';
import {updateUserInfoBrowser} from '../../components/Header/StatusModal';
import useUserContext from '../../hooks/useUserContext';

function importProfilePics() {
    const profilePics = [];
    for (let i = 1; i <= 10; i++) {
        profilePics.push(`/profilepics/pfp-${i}.jpg`);
    }
    return profilePics;
}


const EditProfilePicModal: React.FC<{
    buttonRef: React.MutableRefObject<HTMLDivElement | null>,
    pfpHandles: UseStateReturnType<string>,
    user: Omit<UserModel, 'password'>,
}> = ({ buttonRef, user, pfpHandles: [pfp, setPfp] }) => {
    const [status, setStatus] = React.useState<{
        success: boolean,
        message: string,
    }>();
    const [tempPfp, setTempPfp] = React.useState<string>(user.profileIcon ? user.profileIcon : '/catidiot.jpg');
    const userContext = useUserContext();

    return <Modal key="edit-pfp-modal" doNotHideOnClick={[buttonRef]}>
        <div className="edit-pfp-modal">
            <form
                onSubmit={async (e) => {
                    e.preventDefault();
                    updateUserAccount({ profileIcon: tempPfp })
                        .then((res) => {
                            if (res.tag === 'OK') {
                                updateUserInfoBrowser(res.value?.user as UserModel, userContext);
                                setPfp(tempPfp);
                                setStatus({
                                    success: true,
                                    message: 'New profile picture saved!',
                                });
                            } else {
                                setStatus({
                                    success: false,
                                    message: 'Something went wrong. Please try again later.',
                                });
                            }
                        })
                }}
            >
                {/* <FormTitle>Change Profile Picture</FormTitle> TODO */}
                <div className="edit-pfp-modal-body">
                    {importProfilePics().map((pfp) => {
                        return (
                            <label key={pfp}>
                                <input
                                    type="radio"
                                    name="profileIcon"
                                    value={pfp}
                                    onChange={(e) => {
                                        setTempPfp(e.target.value);
                                        setStatus(undefined)
                                    }}
                                    checked={tempPfp === pfp}
                                />
                                <img className="pfp" src={pfp} />
                            </label>
                        )
                    })}
                </div>
                <SubmitButton>Save Picture</SubmitButton>
                {status && <div
                    className="status-message"
                    style={{
                        color: status.success ? 'green' : 'red',
                    }}
                >
                    {status.message}
                </div>}
            </form>
        </div>
    </Modal>
}

const EditProfilePic: React.FC<Omit<UserModel, 'password'>> = (user) => {
    const buttonRef = React.useRef<HTMLDivElement | null>(null);
    const [pfp, setPfp] = React.useState<string>(user.profileIcon ? user.profileIcon : '');
    const [modalVis, setModalVis] = useModal(<EditProfilePicModal
        pfpHandles={[pfp, setPfp]}
        buttonRef={buttonRef}
        user={user}
    />);

    return <div className="edit-pfp">
        <img className="pfp" src={pfp} />
        <div className="edit-pfp-button-positioner">
            <div
                className="edit-pfp-button"
                onClick={() => { setModalVis(true); }}
                ref={buttonRef}
            >
                <FontAwesomeIcon icon={faImage} size='2x' />
            </div>
        </div>
    </div>
}

export default EditProfilePic;