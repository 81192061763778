import React from 'react';

/**
 * Really weird serde module that prioritises readability of type declarations over anything else
 * 
 * Consider this 'JSON with dates'.
 * 
 * This is incredibly unsafe and should be seriously checked over whenever used. Especially avoid
 * `Serde.overrideParser`. 
 * 
 * This is managed by me (Oli) for this *specific* project. It is not meant to be my attempt at 
 * a generic `Serde` module -- it is supposed to only work for the types of responses we get from
 * educat's API. It is meant to emphasise clarity of type expression. I am not proud of some of 
 * the things in here. Under proper use it should never be break.
 * 
 * ```
 * const fooDataResponse = {
 *     a: 'something',
 *     b: '{ "a": "something" }',
 *     c: '5',
 * } as unknown as FooDataResponse;
 * 
 * // sometims you need to explicitly declare   \/\/\/\/\/\/\/ this bit... sadly.
 * const foo: Foo = Serde.deserializeAttributes<Foo, ['b', 'c']>(fooDataResponse, ['b', 'c']);
 * 
 * expect(foo).toEqual({
 *     a: 'something',
 *     b: { a: 'something' },
 *     c: 5,
 * })
 * ```
 */
namespace Serde {
    /**
     * indicates that a type can be safely parsed into a `T` --
     * the actual type of `De<T>` should always be a `string`, though.
     */
    export type De<T> = { __phantom: T };

    /**
     * indicates that certain attributes of a given type are deserializable
     */
    export type DeAttr<T, Keys extends keyof T> = Omit<T, Keys> & { [K in Keys]: De<T[K]> };

    let parsers: [(data: string) => boolean, (data: string) => unknown][] = [];

    /**
     * unsafe -- the assumption is that this parser will only match things that are 
     * valid `T`s when you write this. If this is not the case, it is your fault. Everything
     * will break.
     */
    export function overrideParser<T>(match: (data: string) => boolean, parse: (data: string) => T) {
        parsers.push([match, parse]);
    }

    /**
     * check if data will match a parser override and potentially break deserialization
     */
    export function matchesParserOverride(data: any): boolean {
        for (const [match,] of parsers) {
            if (match(data)) {
                return true;
            }
        }
        return false;
    } 

    /**
     * This will blindly attempt to deserialize dates. if `T` is a `string`, it will 
     * first check if it is a valid date, if it is -- we're parsing it
     */
    export function deserialize<T>(de: De<T>): T {
        const rawDe = de as unknown as string;
        for (const [match, parse] of parsers) {
            if (match(rawDe)) {
                return parse(rawDe) as T;
            }
        }
        return JSON.parse(rawDe) as T;
    }

    /**
     * safety: consumes `deattr` -- do not use it after this 
     */
    export function deserializeAttributes<T, Keys extends (keyof T)[]>(
        deattr: DeAttr<T, Keys[number]>,
        keys: [...Keys]
    ) {
        let result = deattr as Omit<T, Keys[number]> & { [K in Keys[number]]: T[K] };
        for (let key of keys) {
            // @ts-ignore
            result[key] = deserialize(deattr[key]);
        }
        return result;
    }
}

Serde.overrideParser<Date>(
    isoString => {
        try {
            return isoString === new Date(Date.parse(isoString)).toISOString();
        } catch {
            return false;
        }
    },
    data => new Date(Date.parse(data)),
);

export default Serde;