import React from 'react';
import {RaisedDisplay} from '../../components/Display';
import {Form, Formik} from 'formik';
import {Full, QuestionBodyTag, QuestionModel, Response, Result, TagModel, UseStateReturnType} from '../../types';
import {incorrectCorrectOptionsToOptionsAnswer, nothingHereErr, ParseError, parseTags, submitQuestion} from './util';
import LabelledField, {LabelledFieldLabel} from '../../components/LabelledField';
import BlockFields from '../../components/BlockFields';
import * as Yup from 'yup';
import {tagsYupSchema, titleYupSchema, topicYupSchema} from './validation';
import QuestionPreview from './QuestionPreview';
import useUserContext from '../../hooks/useUserContext';
import useModal from '../../hooks/useModal';
import SuccessModal from './SuccessModal';
import SomethingWentWrongModal from './SomethingWentWrongModal';
import MetaFields from './MetaFields';

export type McQuestionCreateFormProps = {
    questionHandles: UseStateReturnType<Result<Full<QuestionModel>, ParseError>>,
    allTopicNames: string[],
}

const McQuestionCreateForm: React.FC<McQuestionCreateFormProps> = ({
    questionHandles: [question, setQuestion],
    allTopicNames,
}) => {
    // it's more effort and doesn't improve portability to include these lines in the parent component imo
    const userContext = useUserContext();
    const [successModalVis, setSuccessModalVis] = useModal(<SuccessModal />);
    const [somethingWentWrongModalVis, setSomethingWentWrongModalVis] = useModal(<SomethingWentWrongModal />);

    const validationSchema = Yup.object({
        title: titleYupSchema(),
        incorrectOptions: Yup.array().of(Yup.string().required()).min(1).required(),
        correctOptions: Yup.array().of(Yup.string().required()).min(1).required(),
        topic: topicYupSchema(allTopicNames),
        tags: tagsYupSchema(),
    });

    return <>
        <RaisedDisplay className="create-form">
            <QuestionPreview question={question} />
            <Formik
                initialValues={{
                    title: '',
                    question: '',
                    incorrectOptions: [] as string[],
                    correctOptions: [] as string[],
                    topic: '',
                    tags: '',
                }}
                validationSchema={validationSchema}
                onSubmit={values => {
                    if (question.tag === 'OK') {
                        submitQuestion(question.value, userContext, values)
                            .then(result => {
                                if (!result || !Response.assumeSuccess(result)) {
                                    setSomethingWentWrongModalVis(true);
                                } else {
                                    values.title = '';
                                    values.question = '';
                                    values.incorrectOptions = [];
                                    values.correctOptions = [];
                                    values.topic = '';
                                    setQuestion(nothingHereErr());
                                    setSuccessModalVis(true);
                                }
                            });
                    }
                }}
                validate={values => {
                    const updateQuestionPreview = () => {
                        const [options, answer] = incorrectCorrectOptionsToOptionsAnswer(
                            values.incorrectOptions,
                            values.correctOptions,
                        );

                        const tags = parseTags(values.tags);
                        if (tags.tag === 'ERR') {
                            // unreachable -- but keeping here in case i break something else
                            return;
                        }

                        setQuestion(Result.ok({
                            ...QuestionModel.defaults(),
                            title: values.title,
                            body: {
                                tag: QuestionBodyTag.MULTIPLE_CHOICE,
                                question: values.question,
                                options,
                                answer,
                            },
                            tags: tags.value.map(tag => TagModel.fromString(tag)),
                        }));
                    }

                    validationSchema.validate(values)
                        .then(() => updateQuestionPreview())
                        .catch(err => {
                            if (err.path === 'topic') {
                                updateQuestionPreview();
                            }
                        })
                }}
            >
                {({ values }) => <Form>
                    <LabelledField
                        name="title"
                        labelText="Title"
                        labelSubtext={'Enter a short title for your question'}
                        className="monospace-input"
                        placeholder="Title"
                    />
                    <LabelledField
                        name="question"
                        labelText="Question"
                        labelSubtext={'Enter your question'}
                        className="monospace-input"
                        placeholder="Question"
                    />
                    <LabelledFieldLabel
                        labelText="Incorrect Options"
                        labelSubtext="Add options that are wrong"
                    />
                    <BlockFields
                        name="incorrectOptions"
                        blocks={values.incorrectOptions}
                        placeholder="new block"
                    />
                    <LabelledFieldLabel
                        labelText="Correct Options"
                        labelSubtext="The user gets the question right if they click one of these"
                    />
                    <BlockFields
                        name="correctOptions"
                        blocks={values.correctOptions}
                        placeholder="new block"
                    />
                    <MetaFields />
                    {/* TOOD: make this say 'are you sure?' */}
                </Form>}
            </Formik>
        </RaisedDisplay>
    </>
}

export default McQuestionCreateForm;