import React, {useRef, useState} from 'react';
import useLazyState from '../../hooks/useLazyState';
import SubmitButton from '../SubmitButton';
import UserIcon, {UserIconProps, UserIconSuspense} from '../UserIcon';
import FillInTheGapsQuestionBody, {FillInTheGapsQuestionRef} from './FillInTheGapsQuestionBody';
import MultipleChoiceQuestionBody, {MultipleChoiceQuestionRef} from './MultipleChoiceQuestionBody';
import './Question.scss';
import UpvoteDownvote from './UpvoteDownvote';
import ago from 's-ago';
import SmallShareButton from './SmallShareButton';
import {getTopicByFullname, getUserByPk, isModeratorOf} from '../../services/loaders';
import axios from 'axios';
import {getDefaultAxiosConfig} from '../../services/axiosUtils';
import {
    ApiResult,
    Full,
    QuestionBodyTag,
    QuestionModelWithoutAnswer,
    QuestionScoreData,
    Response,
    Thin
} from '../../types';
import useAnimation from '../../hooks/useAnimation';
import useUserContext from '../../hooks/useUserContext';
import SmallDeleteButton from './SmallDeleteButton';


export type QuestionProps = {
    fullQuestion: Full<QuestionModelWithoutAnswer>,
    rerender?: boolean,
    render?: boolean,
    renderDate?: boolean
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'className'>;

type TagProps = React.PropsWithChildren<{}> & Omit<React.HTMLAttributes<HTMLDivElement>, 'className'>;

const Tag: React.FC<TagProps> = ({ children, ...props }) => (
    <div className="tag" {...props}>
        {children}
    </div>
)

/**
 * 
 * @param questionId 
 * @param answer 
 * @returns 
 */

export async function submitAnswer(questionId: string, answer: (string | null)[] | (number | null)[]): Promise<ApiResult<Thin<QuestionScoreData>>> {
    const { data } = await axios.post(
        `/api/question/${questionId}/submit`,
        { answer: answer },
        getDefaultAxiosConfig()
    );
    return Response.assumeSuccess(data as Response<QuestionScoreData>);
};



/**
 * TODO: make these more responsive
 */
const Question: React.FC<QuestionProps> = ({
    fullQuestion,
    rerender = false,
    renderDate = true,
    ...divProps
}) => {
    type StatusMessage = {
        success: boolean,
        message: string,
    }
    const [topicName, setTopicName] = React.useState('???');
    const [[userIcon],] = useLazyState([<UserIconSuspense />, '???'], async () => {
        const userResponseData = await getUserByPk(fullQuestion.authorId);
        const userIconProps: UserIconProps = UserIconProps.fromFullUserResponseDataResult(userResponseData);
        const topicRes = await getTopicByFullname(fullQuestion.topicId);
        if (topicRes.tag === 'OK' && topicRes.value) {
            setTopicName(topicRes.value.name);
        }
        return [
            <UserIcon clickable {...userIconProps} />,
        ]
    });

    const mcqRef = useRef<MultipleChoiceQuestionRef>(null);
    const fitgRef = useRef<FillInTheGapsQuestionRef>(null);
    const [status, setStatus] = useState<StatusMessage>();
    const [disabled, setDisabled] = useState(false);
    const questionRef = React.useRef(null);
    const jiggleRed = useAnimation(questionRef, 'jiggle-red', 800);
    const spinGreen = useAnimation(questionRef, 'spin-green', 800);
    const ding = new Audio('/ding.mp3');
    const wrong = new Audio('/wrong.mp3');
    const userContext = useUserContext();
    const [isMod, setIsMod] = React.useState(false);
            
    React.useEffect(() => {        
        if (status === undefined) {
            return;
        }
        
        if (!status.success) {
            jiggleRed();
            wrong.play();
        } else {
            spinGreen();
            ding.play();
        }
        if (topicName !== '???') {
            isModeratorOf(userContext.loggedInUser!, topicName).then(
                res => {
                    setIsMod(res)
                }
            );
        }
        
    }, [status, topicName]);

    return <div className="question" ref={questionRef} {...divProps}>
        <div className="question-aside">
            <UpvoteDownvote fullQuestion={fullQuestion} />
            <div>
                <SmallShareButton questionId={fullQuestion.id} />
                {
                    (
                        fullQuestion.authorId === userContext.loggedInUser?.id ||
                        isMod
                    ) && <SmallDeleteButton questionId={fullQuestion.id} />
                } 
            </div>
        </div>
        <div className="question-body">
            <div className="question-top-bar">
                <div className="question-meta-positioner">
                    <div className="question-id">{topicName}: {fullQuestion.id}</div>
                    <div className="question-date">
                        {ago(fullQuestion.createdAt)}
                    </div>
                </div>
                <div className="question-title-pfp-positioner">
                    <div className="question-title">{fullQuestion.title}</div>
                    {userIcon}
                </div>
                <div className="tags">
                    {
                        fullQuestion.tags.slice(0, 5)
                            .map((tag, key) => {
                                return <Tag key={key}>
                                    {tag.tag.name}
                                </Tag>;
                            })
                    }
                </div>
            </div>
            {
                fullQuestion.body.tag === QuestionBodyTag.FILL_IN_THE_GAPS
                    ? <FillInTheGapsQuestionBody
                        body={fullQuestion.body}
                        ref={fitgRef}
                    />
                    : <MultipleChoiceQuestionBody body={fullQuestion.body} ref={mcqRef}
                    />
            }
            <SubmitButton
                onClick={() => {
                    const ref = fullQuestion.body.tag === QuestionBodyTag.FILL_IN_THE_GAPS
                        ? fitgRef
                        : mcqRef;
                    const answer = ref.current?.getAnswer();
                    if (answer !== undefined && answer !== null && answer[0] !== null) {
                        submitAnswer(fullQuestion.id, answer)
                            .then(res => {
                                if (res.tag == 'OK') {
                                    let correct = res.value?.correct;
                                    setStatus({
                                        success: correct ? true : false,
                                        message: correct ? 'Correct!' : 'Incorrect :(',
                                    });
                                    setDisabled(true);
                                } else {
                                    setStatus({
                                        success: false,
                                        message: 'Something went wrong :( Please try again.',
                                    });
                                }
                            })
                    } else {
                        setStatus({
                            success: false,
                            message: 'Please select an answer.',
                        });
                    }
                }}
                disabled={disabled}
            >
                Check
            </SubmitButton>
            {
                status && <div
                    className="status-message"
                    style={{
                        color: status.success ? 'green' : 'red',
                    }}
                >
                    {status.message}
                </div>
            }
        </div>
    </div>
}

export default Question;
