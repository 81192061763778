import './HoverDropDown.scss';
import React from 'react';
import Display from '../Display';

export type HoverDropDownProps = {
    content: React.ReactNode | null,
} & React.PropsWithChildren & React.HTMLAttributes<HTMLDivElement>;

const HoverDropDown: React.FC<HoverDropDownProps> = ({
    children,
    content,
    className,
    ...props
}) => {
    return <div className={'hover-drop-down ' + className} {...props}>
        {children}
        <div className='hover-drop-down-content-positioner'>
            {content}
        </div>
    </div>;
}

export type HoverDropDownDisplayProps = {
    justify?: 'right' | 'left' | 'center',
} & React.PropsWithChildren;

export const HoverDropDownDisplay: React.FC<HoverDropDownDisplayProps> = ({
    justify = 'center',
    children,
}) => {
    return (
        <div className="hover-drop-down-display-positioner" variant={justify}>
            <Display className="hover-drop-down-display">
                {children}
            </Display>
        </div>
    )
}

export default HoverDropDown;