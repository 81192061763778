import React from 'react';

/**
 * Enumerate over a list
 * @param list List to enumerate over
 * @returns List of [idx, element]
 */
export function enumerate<T>(list: T[]): [number, T][] {
    let i = 0;
    return list.map(t => [i++, t]);
}

/**
 * sleep function that can be awaited like so
 * 
 * ```ts
 * console.log('t = 0');
 * await sleep(1000);
 * console.log('t ~= 1000ms');
 * ```
 * 
 * this will pause asynchronous execution for 1000ms
 */
export function sleep(t: number): Promise<undefined> {
    return new Promise(r => setTimeout(r, t));
}

/**
 * The max possible document height -- if this is greater than the window
 * height, there is a scrollbar!
 */
export function documentHeight(document: Document): number {
    const body = document.body;
    const html = document.documentElement;
    return Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
    );
}

/**
 * forAll -- same as scala, maths etc. very obvious
 */
export function forAll<T>(array: T[], fn: (element: T) => boolean): boolean {
    return array.map(fn).filter(el => el === false).length === 0;
}

/**
 * functional exists (same as scala, maths, etc.) 
 */
export function exists<T>(array: T[], fn: (element: T) => boolean): boolean {
    for (let el of array) {
        if (fn(el)) {
            return true;
        }
    }
    return false;
}

/**
 * Format a date to the following format
 * 
 * ```
 * Mmm d$(d)?, yyyy  
 * ```
 * 
 * where `$(n)?` represents that `n` is optional (following Rust's macro syntax)
 */
export function formatDate(date: Date): string {
    const monthStrings = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    return monthStrings[date.getMonth()].slice(0, 3)
        + ' '
        + date.getDate()
        + ', '
        + date.getFullYear();
}

/**
 * Strips a type to some new keys. For example
 * 
 * ```typescript
 * const questionModel: QuestionModel = ...;
 * const questionPostBody: QuestionPostBody = stripTo(questionModel, ['title', 'body']); // this extends QuestionModel
 * ```
 */
export function stripTo<From, K extends (keyof From)[]>(
    obj: From,
    keys: readonly [...K],
): { [K in (typeof keys)[number]]: From[K] } {
    let stripped = {} as any;
    for (let key of keys) {
        stripped[key] = obj[key];
    }
    return stripped as ReturnType<typeof stripTo<From, K>>;
}

/**
 * require all the properties on a specific Partial. This is supposed to be used only for conversion
 * from `Partial<T> -> Required<T>` as opposed to for arbitrary data structures. Returns `null` if
 * any property is not present.
 * 
 * If you want to check properties exist that are not part of the type, use `tryGetProperty`
 */
export function requireProperties<From, K extends (keyof From)[]>(
    obj: Partial<From>,
    keys: readonly [...K],
): { [K in (typeof keys)[number]]: From[K] } | null {
    for (let key of keys) {
        if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
            continue;
        } else {
            return null;
        }
    }
    return obj as { [K in (typeof keys)[number]]-?: From[K] };
}

/**
 * Generate a range from `from` to `to - 1`, returns empty if `to` is less than `from`
 */
export function range(from: number, to: number): number[] {
    if (to < from) {
        return [];
    }
    return Array(to - from).fill(null).map((_, i) => i + from);
}

type StringLiteral<T> = T extends string
    ? string extends T
        ? never
        : T
    : never;

/**
 * Tries to access a property with the name `property` on `object`, if it exists, it will
 * return that value (you must typecsast the result yourself). If you want to require properties on a
 * `Partial` in a type-safe way, use `requireProperties` instead.
 */
export function tryGetProperty<T>(object: any, property: StringLiteral<T>): any | null {
    if (object.hasOwnProperty(property)) {
        return (object as { [P in StringLiteral<T>]: any })[property];
    }
}