import axios from "axios";
import {
    ApiResult,
    ErrorResponseBody,
    Full,
    HistoryResponseData,
    PaginatedResponseData,
    QuestionModel,
    QuestionModelWithoutAnswer,
    Response,
    Result,
    Thin
} from "../../types";
import Serde from "../../types/serde";
import {requireProperties} from "../../util";
import {apiUri, getDefaultAxiosConfig, queryParamsToString} from "../axiosUtils";

type GetHistoryReturnType = { questions: Full<QuestionModelWithoutAnswer>[] } & PaginatedResponseData;

/**
 * Get a user's history (look at the docs for /history)
 * This also filters the returned history to only include valid types
 */
export async function getHistory(
    { username, ...pagination }: { username: string, skip: number, take: number }
): Promise<ApiResult<GetHistoryReturnType>> {
    const { data } = await axios.get<Response<HistoryResponseData>>(
        apiUri('question/history', username) + queryParamsToString(pagination),
        getDefaultAxiosConfig(),
    );

    const result = Response.assumeSuccess(data);
    return mapHistoryResponseData(result);
}

/**
 * map a teh response data from getHistory so that it only contains valid values if it is OK
 */
export function mapHistoryResponseData(result: ApiResult<Thin<HistoryResponseData>>): ApiResult<GetHistoryReturnType> {
    if (result.tag === 'ERR' || !result.value?.questions) {
        return Result.err(ErrorResponseBody.notFound());
    }

    const required = requireProperties(result.value, ['questions', 'count']);
    if (required) {
        return Result.ok({
            questions: ((required
                .questions
                .filter(q => requireProperties(q, QuestionModel.KEYS)) as Serde.DeAttr<QuestionModelWithoutAnswer, 'createdAt'>[])
                .map(q => Serde.deserializeAttributes(q, ['createdAt']))
                .map(q => QuestionModel.makeFull(q))
                .filter(q => q) as Full<QuestionModelWithoutAnswer>[]),
            count: required.count
        });
    }

    return Result.err(ErrorResponseBody.partialContent());
}