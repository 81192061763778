import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import BadRequestErrorDisplay from '../../components/BadRequestErrorDisplay';
import Display from '../../components/Display';
import {REMOVE_ASIDE_AT} from '../../components/DisplayPositioner';
import DropDown, {SortByWraps} from '../../components/DropDown';
import DropDownList, {DropDownListEl} from '../../components/DropDownList';
import FancyPaginated from '../../components/Paginated/FancyPaginated';
import Toggle from '../../components/Toggle';
import UserIcon from '../../components/UserIcon';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {getLeaderboard} from '../../services/loaders/leaderboard';
import {SaneLeaderboardElementModel, UserModel} from '../../types';
import './Leaderboard.scss';

function formatLeaderboardElementValue(value: number): string {
    const [integral, real] = `${Math.floor(value * 100) / 100}`.split('.');
    return real && real.length > 0
        ? `${integral}.${real.slice(0, 2)}`
        : integral;
} 

export const LeaderboardListItem: React.FC<{ user: Omit<UserModel, 'password'>, value: number, rank: number }>
    = ({ user, value, rank }) => {
        return (
            <div className="leaderboard-user-preview">
                <div className="leaderboard-ranking-user-icon">
                    <div className="leaderboard-ranking">
                        {rank + 3}
                    </div>
                    <div className="leaderboard-user-icon">
                        <UserIcon {...user} />
                    </div>
                </div>
                <div className="leaderboard-value">
                    {formatLeaderboardElementValue(value)}
                </div>
            </div>
        );
    }

export const LeaderboardSorting = () => {
    const { topicName } = useParams();
    const [relative, setRelative] = React.useState<boolean>(false);
    const resultsPerPage = 15;
    const [windowWidth,] = useWindowDimensions();
    const [timeframe, setTimeframe] = React.useState<'ALL_TIME' | 'MONTH' | 'DAY'>('ALL_TIME');

    const [count, setCount] = React.useState<number>(16);

    if (!topicName) {
        return <BadRequestErrorDisplay errorCode={404} />;
    }

    return (
        <FancyPaginated
            count={count}
            get={getLeaderboard(topicName)}
            queryParams={{ timeframe, basedOn: relative ? 'RELATIVE' : 'TOTAL' }}
            resultsPerPage={10}
            displayPositionerProps={{
                gap: 0,
                className: 'leaderboard-positioner',
                aside: windowWidth > REMOVE_ASIDE_AT
                    ? <Display>
                        <h3>Leaderboard for {topicName}</h3>
                        <p style={{ marginTop: '10px' }}>
                            This is the leaderboard page for the {topicName} topic... 
                        </p>
                        <p>
                            You can view the leaderboard by the <b>total</b> number of 
                            questions answered, or by the number of questions answered <b>
                            adjusted</b> by their difficulty. 
                        </p>
                    </Display>
                    : undefined
            }}
        >
            
            {(result, pageIndex) => {

                if (result.tag === 'OK') {
                    setCount(result.value.count);
                    const createLeaderboardListItem = (el: SaneLeaderboardElementModel, pageIndex: number, index: number) => (
                        <LeaderboardListItem
                            key={el.user.id}
                            user={el.user}
                            value={el.value}
                            rank={pageIndex * resultsPerPage + index + 1}
                        />
                    );

                    return <>
                        <Display variant="header" className='leaderboard-header'>
                            <div className="header-with-toggle">
                                <Toggle onClick={() => setRelative(r => !r)} />
                                <h2>{relative ? 'ADJUSTED' : 'TOTAL'} LEADERBOARD</h2>
                            </div>
                            <DropDown
                                settings={{
                                    scrollBarVisibility: 'hidden',
                                    outlineOnSelect: 'none',
                                }}
                                wraps={<SortByWraps value={(() => {
                                    switch (timeframe) {
                                        case 'ALL_TIME':
                                            return 'All time';
                                        case 'MONTH':
                                            return 'Monthly';
                                        case 'DAY':
                                            return 'Daily';
                                    }
                                })()} />}
                            >
                                <DropDownList>
                                    <DropDownListEl onClick={() => setTimeframe('DAY')}>
                                        Daily
                                    </DropDownListEl>
                                    <DropDownListEl onClick={() => setTimeframe('MONTH')}>
                                        Monthly
                                    </DropDownListEl>
                                    <DropDownListEl onClick={() => setTimeframe('ALL_TIME')}>
                                        All time
                                    </DropDownListEl>
                                </DropDownList>
                            </DropDown>
                        </Display>
                        {
                            pageIndex === 0
                                ? <>
                                    <TopThree leaderboardElements={result.value.leaderboard} />
                                    <div className="leaderboard-user-previews">
                                        {result.value.leaderboard.slice(3).map((el, index) => createLeaderboardListItem(el, pageIndex, index))}
                                    </div>
                                </>
                                : <div className="leaderboard-user-previews">
                                    {result.value.leaderboard.map((el, index) => createLeaderboardListItem(el, pageIndex, index))}
                                </div>
                        }
                    </>;
                }
                return null;
            }}
        </FancyPaginated>
    );
};

type PodiumBoxProps = {
    rank: 1 | 2 | 3,
    leaderboardElement: SaneLeaderboardElementModel | undefined,
}

const PodiumBox: React.FC<PodiumBoxProps> = ({ rank, leaderboardElement }) => {
    const navigate = useNavigate();
    return <div
        className={`top-three-card ${leaderboardElement ? "top-three-filled" : ""}`}
        variant={`rank-${rank}`}
        onClick={() => {
            leaderboardElement && navigate(`/users/${leaderboardElement?.user.username}`)
        }}
    >
        <div className="top-three-user">
            { leaderboardElement &&
                <>
                    <div className="top-three-icon">
                        <UserIcon profileIcon={leaderboardElement?.user.profileIcon || '404-cat.jpg'} />
                    </div>
                    <div className="top-three-info">
                        <div className="top-three-name">
                            @{leaderboardElement.user.username}
                        </div>
                    </div>
                    <div className="top-three-stats-box">
                        <div className="top-three-stats-box-number">
                            {formatLeaderboardElementValue(leaderboardElement.value)}
                        </div>
                        <div className="top-three-stats-box-text">
                            Score
                        </div>
                    </div>
                </>
            }
        </div>
        <div className="top-three-info">
            <div className="top-three-rank">
                {rank}
            </div>
        </div>
    </div>
}

export const TopThree: React.FC<{ leaderboardElements: SaneLeaderboardElementModel[] }>
    = ({ leaderboardElements }) => {
        return (
            <div className="top-three">
                {/* <div className="top-three-header">
                    <h1 style={{ fontSize: 40 }}>Top 3</h1>
                </div> */}
                <div style={{ height: 40 }} />
                <div className="top-three-card-container">
                    <PodiumBox rank={2} leaderboardElement={leaderboardElements[1]} />
                    <PodiumBox rank={1} leaderboardElement={leaderboardElements[0]} />
                    <PodiumBox rank={3} leaderboardElement={leaderboardElements[2]} />
                </div>
            </div>
        );
    };

const Leaderboard = () => (
    <div className="leaderboard-container">
        {/* <div className="leaderboard-header">
            <h1 style={{ fontSize: 40 }}>LEADERBOARD</h1>
        </div> */}
        <LeaderboardSorting />
    </div>
);

export default Leaderboard;