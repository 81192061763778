import React from 'react';
import {UseStateReturnType} from '../types';

/**
 * returns a state that is initially set to `loading`, but is updated to `final`
 * when 
 */
function useLazyState<T>(loading: T, final: () => Promise<T>): UseStateReturnType<T> {
    const [state, setState] = React.useState(loading);

    React.useEffect(() => {
        final().then(value => {
            setState(value);
        });
    }, []);

    return [state, setState];
}

export default useLazyState;