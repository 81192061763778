import React, {forwardRef, useImperativeHandle} from 'react';
import {enumerate} from '../../util';
import {McQuestionBodyModelWithoutAnswer} from "../../types";

export type MultipleChoiceQuestionBodyProps = {
    body: McQuestionBodyModelWithoutAnswer,
};

export interface MultipleChoiceQuestionRef {
    getAnswer: () => (null | number)[],
}

const MultipleChoiceQuestionBody = forwardRef<MultipleChoiceQuestionRef, MultipleChoiceQuestionBodyProps>(({
    body,
}, ref) => {
    const [selected, setSelected] = React.useState<null | number>(null);

    useImperativeHandle(ref, () => ({
        getAnswer: () => {
            return [selected];
        }
    }));

    return <div className="mc-body">
        <div className="mc-question">{body.question}</div>
        <div className="mc-options">
            {
                enumerate(body.options).map(
                    ([i, option]) => <div
                        className="mc-option"
                        key={i.toString()}
                        onClick={() => setSelected(i)}
                        style={selected === i ? {
                            outline: '1px solid rgb(180, 180, 180)',
                            backgroundColor: 'rgb(220, 220, 220)',
                        } : {}}
                        variant={
                            i % 2 === 0
                                ? i === body.options.length - 1
                                    ? 'end'
                                    : 'left'
                                : 'right'
                        }
                    >
                        {option}
                    </div>
                )
            }
        </div>
    </div>;
});

export default MultipleChoiceQuestionBody;