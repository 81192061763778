import React from 'react';
import {UseStateReturnType} from '../types';

const ModalContext = React.createContext<{
    visHandles: UseStateReturnType<boolean>,
    modalContentHandles: UseStateReturnType<React.ReactNode>,
    modalRef: React.MutableRefObject<HTMLDivElement | null> | null,
    mainRef: React.MutableRefObject<HTMLElement | null> | null,
}>({
    visHandles: [false, () => { }],
    modalContentHandles: [<></>, () => { }],
    modalRef: null,
    mainRef: null,
});

/**
 * wrap this around your app to allow modals to be used in it, note that this wraps all your
 * content in a `main` tag, so you shouldn't put one somewhere else
 * 
 * TODO: give state to another component
 */
export const ModalContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [modalVis, setModalVis] = React.useState(false);
    const [modalContent, setModalContent] = React.useState<React.ReactNode>(<></>);
    const modalRef = React.useRef<HTMLDivElement | null>(null);
    const mainRef = React.useRef<HTMLElement | null>(null);

    React.useEffect(() => {
        const modalElement = modalRef?.current;
        const mainElement = mainRef?.current;
        if (modalElement && mainElement) {
            modalElement.style.display = modalVis ? 'block' : 'none';
            modalElement.setAttribute('aria-hidden', modalVis ? 'false' : 'true');
            mainElement.setAttribute('aria-hidden', modalVis ? 'true' : 'false');
        }
    }, [modalVis]);

    return <ModalContext.Provider
        value={{
            visHandles: [modalVis, setModalVis],
            modalContentHandles: [modalContent, setModalContent],
            modalRef,
            mainRef,
        }}
    >
        <main ref={mainRef}>
            {children}
        </main>
        <div id='modal' ref={modalRef} aria-describedby='modal-aria-description'>
            {modalContent}
        </div>
        <div id='modal-aria-description' className='aria-description'>
            This is a modal which overlays the main content of the page. It might contain a form
            or an alert. To hide the modal, click anywhere outside of it.
        </div>
    </ModalContext.Provider>;
}

export default ModalContext;