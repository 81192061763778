import React from 'react';

export interface CommunityPanelProps {
    followers: number,
    following: number,
    questionsCreated: number,
    questionsAnswered: number,
}

export const CommunityPanel: React.FC<CommunityPanelProps> = ({
    followers,
    following,
    questionsCreated,
    questionsAnswered,
}) => {
    return (
        <div className="small-users-panel users-panel-container">
            <h1 className="users-panel-heading">COMMUNITY</h1>
            <ul className="community-stats">
                <li className="follows-container">
                    <div className="user-stat">
                        {followers}
                        <span className="user-stat-tag">Followers</span>
                    </div>
                    <div className="user-stat">
                        <span className="user-stat-tag">Following</span>
                        {following}
                    </div>
                </li>
                <li className="user-stat">
                    {questionsCreated}
                    <span className="user-stat-tag">Questions Created</span>
                </li>
                <li className="user-stat">
                    {questionsAnswered}
                    <span className="user-stat-tag">Questions Answered</span>
                </li>
            </ul>
        </div>
    );
};
