import React from 'react';
import useDelayedState from '../../hooks/useDelayedState';

export type InputProps = {
    onChange?: (text: string) => void,
    updateDelay?: number,
} & Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'>;

/**
 * stateful input, use `onChange` for text input update callback
 * 
 * TODO: override default state, docs
 */
const Input: React.FC<InputProps> = ({
    onChange = async () => { },
    updateDelay = 0,
    ...inputProps
}) => {
    const [text, setText] = useDelayedState('', updateDelay);

    React.useEffect(() => {
        if (text){
            onChange(text)
        }
        
    }, [text]);

    return <input
        {...inputProps}
        onChange={(e) => {
            let targetValue = e.target.value;
            setText(targetValue);
        }}
    />;
}

export default Input;