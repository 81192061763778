import React from 'react';
import {StatusCode} from '../../types';
import Display from '../Display';
import './BadRequestErrorDisplay.scss';

/**
 * Returns the default error message for a given error code -- so you don't have to think :)
 */
export function getDefaultErrorMessage(errorCode: StatusCode): string {
    const table: { [K in StatusCode]: string } = {
        404: 'Resource not found',
        418: 'I\'m a teapot',
        206: 'Partial content',
        500: 'Internal Server Error',
        400: 'Validation error',
    } as const;
    return table[errorCode];
}

/**
 * An `img` displaying a httpcat! All props are passed through except `code`
 */
const HttpCat: React.FC<{ code: StatusCode } & React.HTMLAttributes<HTMLImageElement>> = ({
    code,
    ...props
}) => (
    <img {...props} src={`https://http.cat/${code}`} />
)

/**
 * Use this if something goes wrong with the API and you can't display the page at all 
 * for some reason -- use 418 (I'm a teapot) for loading.
 */
const BadRequestErrorDisplay: React.FC<{ message?: string, errorCode: StatusCode }> = ({
    errorCode,
    message = getDefaultErrorMessage(errorCode),
}) => {
    return <div className="bad-request-error-display-positioner">
        <Display className="bad-request-error-display">
            <h2>
                <span className="error-code">{errorCode}</span>
                <span className="message">{message}</span>
            </h2>
            <HttpCat code={errorCode} />
        </Display>
    </div>
}

export default BadRequestErrorDisplay;