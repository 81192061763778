import React from 'react'
import {Link} from 'react-router-dom';
import {TinyTopicModel} from '../../types';

/**
 * The user statistic of number of questions answered for a specific topic
 */
const UserTopicData: React.FC<TinyTopicModel> = ({ name, questionsAnswered }) => {
    return (
        <div className="user-topic-data">
            <Link className="topic-name" to={`/topics/${name}`}>
                {name}
            </Link>
            <div className="user-stat">
                {questionsAnswered}
                <span className="user-stat-tag">questions answered</span>
            </div>
        </div>
    )
}

/**
 * A panel that shows the user's top 4 topics by questions answered
 */
export const UserTopics: React.FC<{ topics: TinyTopicModel[] }> = ({ topics }) => {
    return (
        <div className="small-users-panel users-panel-container">
            <h1 className="users-panel-heading">TOPICS</h1>
            <div className="topic-list">
                {
                    topics.sort((a, b) => b.questionsAnswered - a.questionsAnswered)
                        .map(topic => {
                            return (
                                <UserTopicData key={topic.name} {...topic} />
                            )
                        })
                }
            </div>
        </div>
    );
}

export default UserTopics;