import React from 'react';
import useModal from '../../hooks/useModal';
import {UseStateReturnType} from '../../types';
import Display from '../Display';
import DropDown, {SortByWraps} from '../DropDown';
import DropDownList, {DropDownListEl} from '../DropDownList';
import LabelledField from '../LabelledField';
import Modal from '../Modal';
import {Form, Formik} from 'formik';
import {parseSelectorsFactory} from '../../pages/Create/util';
import * as Yup from 'yup';
import {selectorYupSchema} from '../../pages/Create/validation';
import SubmitButton from '../SubmitButton';
import './FeedHeader.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGear} from '@fortawesome/free-solid-svg-icons';

export type FeedHeaderFilters = {
    sortBy?: string,
    tags?: string[],
    usernames?: string[],
};

export type FeedHeaderProps = {
    title?: string,
    sortByOptions: string[],
    filtersHandles: UseStateReturnType<FeedHeaderFilters>
    leftComponent?: React.ReactNode,
    filterByUsernames?: boolean,
    filterByTags?: boolean,
};

const FeedHeader: React.FC<FeedHeaderProps> = ({
    title = 'FEED',
    sortByOptions,
    filtersHandles: [filters, setFilters],
    // final optional component to be rendered on the right side of the header, this
    // is used in the feed page to remove all filters (i.e. show all questions)
    leftComponent,
    filterByUsernames = false,
    filterByTags = false,
}) => {
    const showModalButtonRef = React.useRef(null);

    const parseTagsSettings = {
        selectorType: 'tag',
        maxSelectorCount: 20,
        maxSelectorLength: 20,
    };

    const parseUsernamesSettings = {
        selectorType: 'username',
        maxSelectorCount: 20,
        maxSelectorLength: 30,
    }

    const [modalVis, setModalVis] = useModal(
        <Modal
            key={`${title}-set-tags-modal`}
            doNotHideOnClick={[showModalButtonRef]}
            title='ADVANCED SETTINGS 🤓'
        >
            <Formik
                initialValues={{
                    tags: '',
                    usernames: '',
                }}
                validationSchema={Yup.object({
                    tags: selectorYupSchema(parseTagsSettings),
                    usernames: selectorYupSchema(parseUsernamesSettings),
                })}
                onSubmit={values => {
                    const tags = parseSelectorsFactory(parseTagsSettings)(values.tags);
                    const usernames = parseSelectorsFactory(parseUsernamesSettings)(values.usernames);
                    if (tags.tag === 'ERR' || usernames.tag === 'ERR') {
                        return;
                    }
                    setFilters({
                        ...filters,
                        tags: tags.value,
                        usernames: usernames.value
                    });
                }}
            >
                <Form>
                    {filterByTags &&
                        <LabelledField
                            name="tags"
                            labelText='Tags'
                            labelSubtext='Enter tags, separated by spaces: will search for questions that have tag sets with ALL these tags'
                            monospace
                        />
                    }
                    {filterByUsernames &&
                        <LabelledField
                            name="usernames"
                            labelText='Usernames'
                            labelSubtext='Enter usernames, separated by spaces'
                            monospace
                        />
                    }
                    <SubmitButton onClick={() => setModalVis(false)}>Apply</SubmitButton>
                </Form>
            </Formik>
        </Modal>
    );

    return <Display variant="header" className="feed-header">
        <h2 style={{ fontSize: 18 }}>{title}</h2>
        <div className="filters">
            {leftComponent}
            {
                filters.sortBy
                    ? (
                        <DropDown
                            wraps={<SortByWraps value={filters.sortBy} />}
                            settings={{
                                scrollBarVisibility: 'hidden',
                                arrowPos: 'hidden',
                                outlineOnSelect: 'none',
                            }}
                        >
                            <DropDownList>
                                {
                                    sortByOptions.map((sortBy, k) => (
                                        <DropDownListEl
                                            key={k}
                                            onClick={() => setFilters({ ...filters, sortBy: sortBy })}
                                        >
                                            {sortBy}
                                        </DropDownListEl>
                                    ))
                                }
                            </DropDownList>
                        </DropDown>
                    ) : null
            }
            {
                filterByTags || filterByUsernames
                    ? (
                        <button
                            ref={showModalButtonRef}
                            onClick={() => setModalVis(true)}
                            className="advanced-filters-button"
                        >
                            <FontAwesomeIcon icon={faGear} />
                        </button>
                    )
                    : null
            }
        </div>
    </Display>;
}

export default FeedHeader;