import '../../styles/index.scss';
import './UserIcon.scss';
import React from 'react';
import {ApiResult, Full, UserResponseData, UserStatus} from '../../types';
import {useNavigate} from 'react-router-dom';
import {stripTo} from '../../util';

/**
 * Displays a user's profile picture, with several optional decorations. For
 * example, specify the `username` to include the username, or the `status` 
 * to include a status indicator.
 */
export type UserIconProps = {
    profileIcon: string,
    username?: string,
    status?: UserStatus,
    reputation?: number,
    displayText?: boolean,
    clickable?: boolean,
};

export namespace UserIconProps {
    /**
     * Create a new UserIconProps from an `ApiResult<Full<UserResponseData>>` returns a default
     * value if not found (404 cat!) 
     */
    export function fromFullUserResponseDataResult(
        userResponseData: ApiResult<Full<UserResponseData>>
    ): UserIconProps {
        return userResponseData.tag === 'OK'
            ? UserIconProps.fromFullUserResponseData(userResponseData.value)
            : {
                username: '404',
                reputation: 404,
                status: 'OFFLINE',
                profileIcon: '/404-cat.jpg',
            }
    }

     /**
     * Create a new UserIconProps from an `ApiResult<Full<UserResponseData>>` returns a default
     * value if not found (404 cat!) 
     */
     export function fromFullUserResponseData(userResponseData: Full<UserResponseData>): UserIconProps {
        return {
            ...stripTo(userResponseData.user, ['profileIcon', 'username', 'status']),
            reputation: userResponseData.reputation,
        }
    }
}

/**
 * TODO: make this have the option to display more on hover and show bio
 * TODO: not responsive 
 */
const UserIcon: React.FC<UserIconProps> = ({
    profileIcon,
    username,
    status,
    reputation,
    displayText = true,
    clickable = false,
}) => {
    const navigate = useNavigate();

    const OptionalStatus: React.FC = () => status !== undefined
        ? <div
            className="status"
            style={{
                backgroundColor: UserStatus.color(status),
            }}
        />
        : <></>;

    const OptionalUsername: React.FC = () => username
        ? <div className="username">{username}</div>
        : <></>

    const OptionalReputation: React.FC = () => reputation
        ? <div className="reputation">🎓 {reputation}</div>
        : <></>;

    const OptionalUsernameReputation: React.FC = () => username || reputation
        ? <div className="username-reputation">
            <OptionalUsername />
            <OptionalReputation />
        </div>
        : <></>;

    return <div
        className={'user-icon ' + (clickable ? 'clickable' : '')}
        onClick={
            clickable
                ? () => navigate(`/users/${username}`)
                : () => { }
        }
    >
        <div className="pfp-status">
            <img className="pfp" src={profileIcon} />
            <OptionalStatus />
        </div>
        {displayText ? <OptionalUsernameReputation /> : null}
    </div>;
}

export const UserIconSuspense: React.FC = () => <UserIcon
    profileIcon="catidiot.jpg"
    username="???"
    status="OFFLINE"
    reputation={0}
/>;

export default UserIcon;