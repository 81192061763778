import React from 'react';
import useSocket from "../../hooks/useSocket";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';

export type MessageBoxProps = {
    topicName?: string;
}

const MessageBox: React.FC<MessageBoxProps> = ({topicName}) => {
    const [message, setMessage] = React.useState('');

    const [textareaHeight, setTextareaHeight] = React.useState(1);

    const { socket } = useSocket();

    const sendMessage = () => {
        if (message !== '' && topicName) {
            socket?.emit("message", {
                message: message,
                roomName: topicName
            });
            setMessage('');
        }
    }

    return (
        <div role="form" className="message-box">
            <label htmlFor="chat-input" className="sr-only">
                Chat message
            </label>
            <textarea
                id="chat-input"
                className="message-input"
                placeholder="Type your message here..."
                aria-label="Type your message here..."
                draggable={false}
                inputMode="text"
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        sendMessage();
                    }
                }}
                rows={textareaHeight}
                onChange={(e) => {
                    const maxRows = 5;

                    const textarea = e.target as HTMLTextAreaElement;
                    // Works for now, but doesn't handle any line wrapping
                    const rows = textarea.value.split(/\r*\n/).length;

                    if (rows > maxRows) {
                        setTextareaHeight(maxRows);
                    }
                    else if (rows < maxRows) {
                        setTextareaHeight(rows);
                    }

                    setMessage(e.target.value)
                }}
                value={message}
            />
            <button
                type="button"
                className="message-send-button"
                onClick={sendMessage}
            >
                <FontAwesomeIcon icon={faPaperPlane} />
            </button>
        </div>
    )
}

export default MessageBox;