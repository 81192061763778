import {useNavigate, useParams} from 'react-router-dom';
import React from 'react';
import useModal from '../../hooks/useModal';
import ShareModal from '../../components/ShareModal';
import {getReports, getUserByPk} from '../../services/loaders';
import {Full, ReportModel, Result, UserModel, UserResponseData} from '../../types';
import {deleteReport, deleteUser} from '../../services/actions';
import useUserContext from '../../hooks/useUserContext';
import UserIcon, {UserIconProps} from '../../components/UserIcon';
import DisplayPositioner, {REMOVE_ASIDE_AT, StickySideDisplays} from '../../components/DisplayPositioner';
import Display from '../../components/Display';
import ThinHr from '../../components/ThinHr';
import DeleteModal from '../../components/DeleteModal';
import {reportId} from '../Reports';
import './ReportReport.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHandPointRight} from '@fortawesome/free-solid-svg-icons';
import SubmitButton from '../../components/SubmitButton';
import useWindowDimensions from '../../hooks/useWindowDimensions';


/**
 * @returns A page displaying all details of the report
 */
const ReportReport: React.FC = () => {
    const [windowWidth,] = useWindowDimensions();

    const isAdmin = useUserContext().loggedInUser?.isAdmin;
    const [reporterData, setReporterData] = React.useState<Full<UserResponseData> | null>(null);
    const [reporteeData, setReporteeData] = React.useState<Full<UserResponseData> | null>(null);

    const { id } = useParams();
    const [reporterId, reporteeId] = id?.split('_') || ['', ''];

    const [report, setReport] = React.useState<ReportModel | null>(null);


    React.useEffect(() => {
        getReports({
            reporteeIds: reporteeId,
            reporterIds: reporterId
        }).then(res => {
            if (res.tag === 'OK') {
                const resReports = res.value?.reports;
                if (resReports) {
                    setReport(resReports[0])
                }
            }
        });
        getUserByPk(reporterId).then(userResponseData => Result.map(
            userResponseData,
            d => setReporterData(d)
        ));
        getUserByPk(reporteeId).then(userResponseData => Result.map(
            userResponseData,
            d => setReporteeData(d)
        ));
    }, []);
    

    const resolveReportHeader = (
        <Display>
            <h3>RESOLVE</h3>
        </Display>
    );

    return (
        <DisplayPositioner
            className="report-report-page"
            aside={
                isAdmin ? <>
                    {resolveReportHeader}
                    <StickySideDisplays>
                        <ResolveReportOptions 
                            reporter={reporterData?.user} 
                            reportee={reporteeData?.user}
                        />
                    </StickySideDisplays>
                </>
                    : undefined
            }
        >
            <Display className="report">
                <h2>REPORT</h2>
                <ThinHr />
                <div className="from-to-positioner">
                    <div className="from-to">
                        <div className="user-icon-container">
                            {reporterData && <UserIcon clickable {...UserIconProps.fromFullUserResponseData(reporterData)} />}
                        </div>
                    </div>
                    <FontAwesomeIcon className="wagging-hand" icon={faHandPointRight} />
                    <div className="from-to">
                        <div className="user-icon-container">
                            {reporteeData && <UserIcon clickable {...UserIconProps.fromFullUserResponseData(reporteeData)} />}
                        </div>
                    </div>
                </div>
                <h3 className="report-details-header">Report Details</h3>
                <p className="report-details">
                    {report?.reason}
                </p>
            </Display>
            {
                windowWidth < REMOVE_ASIDE_AT && isAdmin
                    ?   <ResolveReportOptions 
                            reporter={reporterData?.user} 
                            reportee={reporteeData?.user}
                        />
                    :   null
            }
        </DisplayPositioner>
    )
};

const ResolveReportOptions: React.FC<{
    reporter: Full<Omit<UserModel, 'password'>> | undefined,
    reportee: Full<Omit<UserModel, 'password'>> | undefined
}> = ({
    reporter,
    reportee
}) => {
    const shareButtonRef = React.useRef<HTMLButtonElement | null>(null);
    const deleteReporterRef = React.useRef<HTMLButtonElement | null>(null);
    const deleteReporteeRef = React.useRef<HTMLButtonElement | null>(null);
    const deleteReportRef = React.useRef<HTMLButtonElement | null>(null);

    const [errMsg, setErrMsg] = React.useState('');
    const navigate = useNavigate();

    // React.useEffect(() => { }, [reporter, reportee])
    const reporterId = reporter?.id || '';
    const reporteeId = reportee?.id || '';

    const [, setShareModalVis] = useModal(
        <ShareModal
            key={`share-modal-${
                reportId(
                    reporterId,
                    reporteeId
                )}`
            }
            link={window.location.href}
            elementType='report'
            doNotHideOnClick={[shareButtonRef]}
        />
    );
    const [, setDeleteReporterModalVis] = useModal(
        <DeleteModal
            modalKey={`delete-reporter-modal-${reportId(reporterId, reporteeId)}`}
            cb={async () => await handleDeleteUser(reporterId)}
            elementType='reporter'
            doNotHideOnClick={[deleteReporterRef]}
        />
    );
    const [, setDeleteReporteeModalVis] = useModal(
        <DeleteModal
            modalKey={`delete-reportee-modal-${reportId(reporterId, reporteeId)}`}
            cb={async () => handleDeleteUser(reporteeId)}
            elementType='reportee'
            doNotHideOnClick={[deleteReporteeRef]}
        />
    );
    const [, setDeleteReportModalVis] = useModal(
        <DeleteModal
            modalKey={`delete-report-modal-${reportId(reporterId, reporteeId)}`}
            cb={async () => await handleDeleteReport()}
            elementType='report'
            doNotHideOnClick={[deleteReportRef]}
        />
    );
        /**
     * Calls axios function to delete report with primary
     * key reprterId_reporteeId
     */
    async function handleDeleteReport() {
        const res = await deleteReport({
            reporteeId,
            reporterId,
        });

        if (res.tag === 'OK') {
            setDeleteReportModalVis(false);
            navigate('/reports');
        } else {
            setErrMsg(res.error?.message || '');
        }
    };

    /**
     * Calls axios function to delete user
     * @param Id id of user to delete
     */
    async function handleDeleteUser(Id: string) {
        const res = await deleteUser(Id);
        if (res.tag === 'OK') {
            setDeleteReporteeModalVis(false);
            setDeleteReporterModalVis(false);
            navigate('/reports');
        } else {
            setErrMsg(res.error?.message || '');
        }
    };

    return (
        <Display>
            <SubmitButton 
                invert
                onClick={() =>  setDeleteReportModalVis(true)}
                innerRef={deleteReportRef}
            >
                DELETE Report
            </SubmitButton>
            <ThinHr />
            <SubmitButton
                innerRef={deleteReporterRef}
                onClick={() => setDeleteReporterModalVis(true)}
            >
                {`DELETE Reporter (${reporter?.username})`}
            </SubmitButton>
            <SubmitButton
                innerRef={deleteReporteeRef}
                onClick={() => setDeleteReporteeModalVis(true)}
            >
                {`DELETE Reportee (${reportee?.username})`}
            </SubmitButton>
            <SubmitButton
                innerRef={shareButtonRef}
                onClick={() => setShareModalVis(true)}
            >
                SHARE Report
            </SubmitButton>
        </Display>
    );
}

export default ReportReport;