import DisplayPositioner, {StickySideDisplays} from "../../components/DisplayPositioner";
import useUserContext from "../../hooks/useUserContext";
import {getUserByPk} from "../../services/loaders";
import React from 'react';
import {Full, GetQuestionQueryParams, Result, UserResponseData} from "../../types";
import BadRequestErrorDisplay from "../../components/BadRequestErrorDisplay";
import Feed from "../../components/Feed";
import Display from "../../components/Display";
import ThinHr from "../../components/ThinHr";
import SubmitButton from "../../components/SubmitButton";
import {useNavigate} from "react-router-dom";
import FeedHeader, {FeedHeaderFilters} from "../../components/FeedHeader";
import './Feed.scss';
import Toggle from "../../components/Toggle";

const FeedPage: React.FC = () => {
    const { loggedInUser } = useUserContext();
    const navigate = useNavigate();
    const [userData, setUserData] = React.useState<null | Full<UserResponseData>>(null);

    const [filters, setFilters] = React.useState<GetQuestionQueryParams>({});

    const [followingTopicIds, setFollowingTopicIds] = React.useState<string[]>([]);
    const [followingUserIds, setFollowingUserIds] = React.useState<string[]>([]);

    const [shouldShowAllQuestions, seShouldShowAllQuestions] = React.useState<boolean>(true);

    type SortBy = 'Oldest' | 'Newest' | 'Best' | 'Worst';
    const sortByOptions: SortBy[] = ['Oldest', 'Newest', 'Best', 'Worst'];

    const [feedHeaderFilters, setFeedHeaderFilters] = React.useState<FeedHeaderFilters>({
        sortBy: 'Newest',
        tags: [],
    });

    React.useEffect(() => {
        if (loggedInUser) {
            getUserByPk(loggedInUser.id)
                .then(r => Result.map(r, userData => {
                    setUserData(userData);
                    setFollowingTopicIds(userData.topicFollowingList.map(t => t.id));
                    setFollowingUserIds(userData.userFollowingList.map(u => u.id));
                }))
        }
    }, []);

    React.useEffect(() => {
        let f: GetQuestionQueryParams;

        switch (feedHeaderFilters.sortBy as SortBy) {
            case 'Oldest':
                f = GetQuestionQueryParams.oldest();
                break;
            case 'Newest':
                f = GetQuestionQueryParams.newest();
                break;
            case 'Worst':
                f = GetQuestionQueryParams.worst();
                break;
            case 'Best':
                f = GetQuestionQueryParams.best();
                break;
        }

        if (shouldShowAllQuestions) {
            setFilters({ ...f, tags: feedHeaderFilters.tags })
        } else {
            setFilters({ ...f, tags: feedHeaderFilters.tags,
                authorIds: [...followingUserIds, userData!.user.id],
                topicIds: followingTopicIds,
                combination: 'OR' });
        }
    }, [feedHeaderFilters, shouldShowAllQuestions]);

    if (!userData) {
        return <BadRequestErrorDisplay errorCode={418} />;
    } else {
        return <DisplayPositioner
            className="feed-page"
            aside={<>
                <StickySideDisplays>
                    <Display>
                        <p>
                            Welcome to your feed, scroll through the questions you follow, or have a look at them all and discover something new!
                        </p>
                        <ThinHr />
                        <SubmitButton invert onClick={() => navigate('/create')}>
                            Create Question
                        </SubmitButton>
                    </Display>
                </StickySideDisplays>
            </>}
        >
            <Feed
                feedHeader={
                    <FeedHeader
                        title={`feed`}
                        sortByOptions={sortByOptions}
                        filtersHandles={[feedHeaderFilters, setFeedHeaderFilters]}
                        leftComponent={
                            <div className="toggle">
                                <span>Following</span>
                                <Toggle onClick={() => seShouldShowAllQuestions(!shouldShowAllQuestions)} />
                            </div>
                        }
                    />
                }
                filters={
                    {
                        ...filters,
                    }
                }
            />
        </DisplayPositioner>;
    }
}

export default FeedPage;