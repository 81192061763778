import axios from "axios";
import {
    ApiResult,
    ErrorResponseBody,
    Full,
    GetQuestionQueryParams,
    PaginatedResponseData,
    QuestionByIdResponseData,
    QuestionModel,
    QuestionModelWithoutAnswer,
    QuestionResponseData,
    Response,
    Result
} from "../../types";
import Serde from "../../types/serde";
import {requireProperties} from "../../util";
import {apiUri, getDefaultAxiosConfig, queryParamsToString} from "../axiosUtils";

/**
 * perform a GET on /question following the exact API spec.
 * Also maps the result to include on full questions
 */
export async function getQuestions(filters: GetQuestionQueryParams): Promise<ApiResult<{
    questions: Full<QuestionModelWithoutAnswer>[]
} & PaginatedResponseData>> {
    const { data } = await axios.get<Response<QuestionResponseData>>(
        apiUri('question') + queryParamsToString(filters),
        getDefaultAxiosConfig<void>(),
    );

    const result = Response.assumeSuccess(data);
    if (result.tag === 'ERR') {
        return Result.err(result.error);
    }
    if (!result.value?.questions) {
        return Result.err(null);
    }

    const required = result.value.questions
        .filter(q => requireProperties(q, QuestionModel.KEYS)) as Serde.DeAttr<QuestionModel, 'createdAt'>[];
    const deserialized = required
        .map(q => Serde.deserializeAttributes(q, ['createdAt']));
    const fullQuestions = deserialized
        .filter(q => QuestionModel.makeFull(q)) as Full<QuestionModelWithoutAnswer>[];

    return Result.ok({ questions: fullQuestions, count: result.value.count || 0 });
};

/**
 * get a question by id, returning only full questions and erroring on partials
 */
export async function getQuestionById(id: string): Promise<ApiResult<Full<QuestionModelWithoutAnswer>>> {
    const { data } = await axios.get<Response<QuestionByIdResponseData>>(
        apiUri('question', id),
        getDefaultAxiosConfig<void>()
    );

    const result = Response.assumeSuccess(data);
    if (result.tag === 'ERR') {
        return result;
    }
    if (!result.value?.question) {
        return Result.err(ErrorResponseBody.notFound());
    }

    const question = result.value.question;
    const required = requireProperties(question, QuestionModel.KEYS);
    if (!required) {
        return Result.err(ErrorResponseBody.partialContent());
    }
    const full = QuestionModel.makeFull(
        Serde.deserializeAttributes<Partial<QuestionModelWithoutAnswer>, ['createdAt']>(required, ['createdAt'])
    );
    if (full) {
        return Result.ok(full);
    }
    return Result.err(ErrorResponseBody.notFound());
}
