import React, {PropsWithChildren} from 'react';
import '../../styles/index.scss';
import './DisplayPositioner.scss';

/**
 * shorthand for using a div with the class defined by the mixin `space-displays-vertically` 
 * and nothing else 
 */
export const SpaceDisplaysVertically: React.FC<PropsWithChildren> = ({ children }) => (
    <div className="space-displays-vertically">
        {children}
    </div>
)

export const StickySideDisplays: React.FC<PropsWithChildren> = ({ children }) => (
    <div className="sticky-content">
        {children}
    </div>
)

export type DisplayPositionerProps = {
    aside?: React.ReactNode | null,
    asidePos?: 'right',
    className?: string,
    center?: boolean,
    maxWidth?: number,
    gap?: string | number,
} & React.PropsWithChildren;


/**
 * The width (in px) of the window at which the aside is removed
 */
export const REMOVE_ASIDE_AT = 750;

/**
 * This is designed to help you easily lay out `Display`s (or anything, really).
 * 
 * The `children` of this component will be rendered as it's 'main content' -- that is, they will
 * be centered on the page, you can add side panels with `aside`
 * 
 * You cannot have an aside if you have `center = true` 
 */
const DisplayPositioner: React.FC<DisplayPositionerProps> = ({
    aside = null,
    asidePos = 'right',
    children,
    className = '',
    center = false,
    gap = undefined,
    maxWidth
}) => {
    const Aside: React.FC = () => (
        <div className="aside-content">
            {aside}
        </div>
    );

    return <div className={'page-content ' + className + (center ? ' center' : '')}>
        <div
            className="display-positioner"
            variant={aside && !center ? 'with-aside' : 'without-aside'}
        >
            <div
                className={'main-content ' + (gap === undefined ? 'default-gap' : '')}
                style={{ maxWidth }}
            >
                {children}
            </div>
            {asidePos === 'right' && <Aside />}
        </div>
    </div>
}

export default DisplayPositioner;