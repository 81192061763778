import React from 'react';
import {Location, Params, useLocation, useParams} from 'react-router-dom';
import {PathContext, PathMeta} from '../context/PathContext';

export interface EvaluatedPathMeta {
    title: string,
}

function evaluatePathMeta(pathMeta: PathMeta, _location: Location, params: Params): EvaluatedPathMeta {
    return {
        title: pathMeta.getTitle(params)
    }
}

export default function usePathContext(): EvaluatedPathMeta {
    const location = useLocation();
    const params = useParams();
    const context = React.useContext(PathContext);
    const [pathMeta, setPathMeta] = React.useState(evaluatePathMeta(context, location, params));

    React.useEffect(() => {
        setPathMeta(evaluatePathMeta(context, location, params));
    }, [location]);

    return pathMeta;
}