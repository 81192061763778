import React from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

const LOADING_GIF_SRC = 'https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif';

export type RawFeedProps = {
    questions: React.ReactNode[],
};

const RawFeed: React.FC<RawFeedProps> = ({ questions }) => {
    return <DndProvider backend={HTML5Backend}>
        <div className="feed">
            {/* for some reason this literally doesn't work without separating
         this!! Would guess it's React trying to be clever... */}
            <div className="questions">
                {questions}
            </div>
            <img className="loading-gif" src={LOADING_GIF_SRC} />
        </div>
    </DndProvider>;
}

export default RawFeed;