import React, {PropsWithChildren, RefObject, useEffect, useRef, useState} from 'react';
import './HomeInfo.scss'

export interface HomeInfoProps {
    type: 'default' | 'alt';
    title: string;
    description: string;
    graphicSrc: string;
}

function useElementOnScreen(ref: RefObject<HTMLDivElement>) {
    const [isIntersecting, setIntersecting] = useState(true);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting);
            }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current!);
            }
        };
    }, []);
    return isIntersecting;
}

const AnimateIn: React.FC<PropsWithChildren> = ({ children }) => {
    const ref = useRef<HTMLDivElement>(null);
    const onScreen = useElementOnScreen(ref);
    return (
      <div
        ref={ref}
        style={{
            justifyContent: "center",
            opacity: onScreen ? 1 : 0,
            translate: onScreen ? "none" : "0 4rem",
            transition: "1000ms ease-in-out",
        }}
      >
        {children}
      </div>
    );
};

const HomeInfo: React.FC<HomeInfoProps> = ({ type, title, description, graphicSrc }) => {
    const containerType = type === 'default' ? 'home-info-container' : 'home-info-alt-container';
        
    return (
        <div className={containerType}>
            <AnimateIn>
                <div className="home-info-body">
                    {type === 'alt' && (
                        <img
                            className="home-info-graphic"
                            src={graphicSrc}
                        />
                    )}                
                    <div className="home-info-text">
                        <div className="home-info-title">
                            {title}
                        </div>
                        <div className="home-info-description">
                            {description}
                        </div>
                    </div>
                    {type === 'default' && (
                        <img
                            className="home-info-graphic"
                            src={graphicSrc}
                        />
                    )}
                </div>
            </AnimateIn>
        </div>
    );
};

export default HomeInfo;