import React from 'react';
import './SubmitButton.scss';

export type SubmitButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & React.PropsWithChildren<{
    invert?: boolean,
    innerRef?: React.MutableRefObject<HTMLButtonElement | null>,
    outerRef?:  React.MutableRefObject<HTMLDivElement | null>,
}>;

const SubmitButton: React.FC<SubmitButtonProps> = ({
    children,
    type = 'submit',
    className,
    invert = false,
    innerRef,
    outerRef,
    ...props
}) => {
    className = (invert ? 'invert' : 'normal') 
        + ' submit-button ' 
        + (className ? className : '');

    return <div className="submit-button-positioner" ref={outerRef}>
        <button type={type} ref={innerRef} className={className} {...props}>{children}</button>
    </div>
}

export default SubmitButton;