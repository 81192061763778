import React from 'react';
import {Link} from 'react-router-dom';
import UserIcon from "../../components/UserIcon";

export type MessageProps = {
    userId: string;
    authorId: string;
    username: string;
    profileIcon?: string;
    text: string;
    createdAt: Date;
}

const Message: React.FC<MessageProps> = (props) => {
    return (
        <div className={`message-container ${props.userId === props.authorId ? 'message-user' : ''}`}>
            <div className="profile-icon">
                {props.profileIcon ? <UserIcon profileIcon={props.profileIcon} />
                    : <UserIcon profileIcon="/profilepics/pfp-1.jpg" />
                }
            </div>
            <div className="message-content">
                <div className="message-header">
                    <div className="author-name">
                        <Link to={`/users/${props.username}`}>
                            {props.username}
                        </Link>
                    </div>

                    <div className="created-at">
                        {
                            props.createdAt.getDate() === new Date().getDate()
                                ? 'Today'
                                : props.createdAt.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: '2-digit' })
                        }
                        {' '}
                        {props.createdAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </div>
                </div>
                <div className="message-body">
                    {props.text.split('\n').map((line, i) => (
                        <p key={i}>
                            {line}
                            <br />
                        </p>
                    ))}
                </div>
            </div>
        </div >
    );
}

export default Message;