import React from 'react';
import {getTopicAppeals} from '../../services/loaders/topicAppeal';
import FancyPaginated from '../../components/Paginated/FancyPaginated';
import useUserContext from '../../hooks/useUserContext';
import {Navigate} from 'react-router-dom';
import TopicAppealDisplay from './TopicAppealDisplay';
import Display from '../../components/Display';

const TopicAppeal: React.FC = () => {
    const userContext = useUserContext();
    const [count, setCount] = React.useState(4);
    if (!userContext.loggedInUser?.isAdmin) {
        return <Navigate to="/" replace />;
    }
    return (
        <FancyPaginated
            get={getTopicAppeals}
            count={count}
            queryParams={{}}
            resultsPerPage={4}
        >
            {result => {
                if (result.tag === 'ERR') {
                    return null;
                }

                setCount(result.value.count);

                return <>
                    <Display variant="header">
                        <h2>TOPIC APPEALS</h2>
                    </Display>
                    {result.value.topicAppeals.map(topicAppeal => (
                        <TopicAppealDisplay topicAppeal={topicAppeal} key={topicAppeal.id} />
                    ))}
                </>;
            }}
        </FancyPaginated>
    )
}

export default TopicAppeal;