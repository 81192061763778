import React from 'react'
import './Users.scss'
import {CommunityPanel} from './CommunityPanel'
import {UserSummary} from './UserSummary'
import UserRecent from './UserRecent'
import UserTopics from './UserTopics'
import {useLocation, useParams} from 'react-router-dom'
import {
    ExtendedUserResponseData,
    Full,
    PaginatedResponseData,
    QuestionModelWithoutAnswer,
    Result,
    UserResponseData
} from '../../types'
import {getUserByPk} from '../../services/loaders'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {getQuestions} from '../../services/loaders/question'
import {getHistory} from '../../services/loaders/history'

// copied constants from Users.scss
const MEDIUM_SCREENS = 1100;
const SMALL_SCREENS = 730;

/**
 * The main Users page component
 */
const Users: React.FC = () => {
    const [hasError, setHasError] = React.useState(false);
    const [userData, setUserData] = React.useState<Full<UserResponseData> | null>(null);
    type QuestionsWithCount = {
        questions: Full<QuestionModelWithoutAnswer>[],
    } & PaginatedResponseData | null;
    const [questionsCreated, setQuestionsCreated] = React.useState<QuestionsWithCount>(null);
    const [questionsAnswered, setQuestionsAnswered] = React.useState<QuestionsWithCount>(null);

    const [windowWidth,] = useWindowDimensions();
    const { username } = useParams();
    const location = useLocation();

    React.useEffect(() => {
        if (!username) {
            return;
        }

        getUserByPk(username).then(ud => {
            const udUnwrapped = Result.unwrapOr(ud, null as Full<UserResponseData> | null);
            setUserData(udUnwrapped);

            if (!udUnwrapped?.user) {
                return;
            }

            getQuestions({
                authorIds: [udUnwrapped.user.id],
                skip: 0,
                take: 3,
            }).then(qd => {
                setQuestionsCreated(Result.unwrapOr(qd, null as QuestionsWithCount));
            });

            getHistory({
                username: udUnwrapped.user.username,
                skip: 0,
                take: 3,
            }).then(hd => {
                setQuestionsAnswered(Result.unwrapOr(hd, null as QuestionsWithCount));
            })
        });

    }, [location])

    const Topics: React.FC = () => <UserTopics
        topics={
            userData
                ? ExtendedUserResponseData.tryFromUserResponseData(userData)?.topics.slice(0, 5) || []
                : []
        }
    />;

    const Community: React.FC = () => <CommunityPanel
        followers={userData?.followerCount || 0} // TODO: WARNING name changes may break this
        following={userData?.userFollowingCount || 0} // TODO: followingCount
        questionsCreated={questionsCreated?.count || 0}
        questionsAnswered={questionsAnswered?.count || 0}
    />;

    const Recent: React.FC = () => <UserRecent
        questionsCreated={questionsCreated?.questions || []}
        questionsAnswered={questionsAnswered?.questions || []}
    />;

    const PageContent: React.FC<{
        userData: UserResponseData,
    }> = ({ userData }) => {
        if (windowWidth < SMALL_SCREENS) {
            return <div className="users-page-content-small">
                <UserSummary {...userData} />
                <Community />
                <Topics />
                <Recent />
            </div>
        } else if (windowWidth < MEDIUM_SCREENS && windowWidth > SMALL_SCREENS) {
            return <div className="users-page-content-medium">
                <UserSummary {...userData} />
                <div className="panels">
                    <Community />
                    <Topics />
                    <Recent />
                </div>
            </div>
        } else {
            return <div className="users-page-content">
                <UserSummary {...userData} />
                <div className="panels">
                    <div>
                        <Community />
                        <Topics />
                    </div>
                    <Recent />
                </div>
            </div>
        }
    };

    return <>{
        hasError || !userData
            ? <div>User not found</div>
            : <div className="users-page">
                <PageContent userData={userData} />
            </div>
    }</>;
}

export default Users;