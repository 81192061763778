import React from 'react';
import {Full, QuestionBodyTag, QuestionModel, Result, UseStateReturnType} from '../../types';
import './Create.scss';
import {RaisedDisplay} from '../../components/Display';
import {nothingHereErr, ParseError} from './util';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import FitgQuestionCreateForm from './FitgQuestionCreateForm';
import DropDown from '../../components/DropDown';
import DropDownList, {DropDownListEl} from '../../components/DropDownList';
import {getAllTopics} from '../../services/loaders/topic';
import {useLocation} from 'react-router-dom';
import McQuestionCreateForm from './McQuestionCreateForm';
import DisplayPositioner, {REMOVE_ASIDE_AT, StickySideDisplays} from '../../components/DisplayPositioner';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import McHelpDisplay from './McHelpDisplay';
import FitgHelpDisplay from './FitgHelpDisplay';

const Header: React.FC<{ questionBodyTagHandels: UseStateReturnType<QuestionBodyTag> }> = ({
    questionBodyTagHandels: [questionBodyTag, setQuestionBodyTag],
}) => (
    <RaisedDisplay className="page-content-header">
        <div className="page-content-title">CREATE A QUESTION</div>
        <DropDown
            wraps={<span className="drop-down-wraps">{(() => {
                switch (questionBodyTag) {
                    case QuestionBodyTag.FILL_IN_THE_GAPS:
                        return 'fill in the gaps';
                    case QuestionBodyTag.MULTIPLE_CHOICE:
                        return 'multiple choice'
                }
            })()}</span>}
            settings={{
                scrollBarVisibility: 'hidden',
            }}
        >
            <DropDownList>
                <DropDownListEl
                    onClick={() => setQuestionBodyTag(QuestionBodyTag.FILL_IN_THE_GAPS)}
                >
                    fill in the gaps
                </DropDownListEl>
                <DropDownListEl
                    onClick={() => setQuestionBodyTag(QuestionBodyTag.MULTIPLE_CHOICE)}
                >
                    multiple choice
                </DropDownListEl>
            </DropDownList>
        </DropDown>
    </RaisedDisplay>
)

const Create: React.FC = () => {
    const [question, setQuestion] = React.useState<Result<Full<QuestionModel>, ParseError>>(nothingHereErr());
    const [questionBodyTag, setQuestionBodyTag] = React.useState(QuestionBodyTag.FILL_IN_THE_GAPS);
    const [allTopicNames, setAllTopicNames] = React.useState<string[]>([]);
    const [windowWidth,] = useWindowDimensions();

    React.useEffect(() => {
        getAllTopics().then(result => {
            if (result.tag === 'OK' && result.value) {
                const ret = result.value
                    .map(t => t?.name)
                    .filter(t => t) as string[];
                setAllTopicNames(ret);
            }
        })
    }, [useLocation()]);

    React.useEffect(() => setQuestion(nothingHereErr()), [questionBodyTag]);

    const helpDisplay = (() => {
        switch (questionBodyTag) {
            case QuestionBodyTag.FILL_IN_THE_GAPS:
                return <FitgHelpDisplay />;
            case QuestionBodyTag.MULTIPLE_CHOICE:
                return <McHelpDisplay />;
        }
    })();

    return <DndProvider backend={HTML5Backend}>
        <DisplayPositioner
            className="create-page"
            aside={
                <StickySideDisplays>
                    {helpDisplay}
                </StickySideDisplays>
            }
        >
            <Header questionBodyTagHandels={[questionBodyTag, setQuestionBodyTag]} />
            {(() => {
                switch (questionBodyTag) {
                    case QuestionBodyTag.FILL_IN_THE_GAPS:
                        return <FitgQuestionCreateForm
                            questionHandles={[question, setQuestion]}
                            allTopicNames={allTopicNames}
                        />;
                    case QuestionBodyTag.MULTIPLE_CHOICE:
                        return <McQuestionCreateForm
                            questionHandles={[question, setQuestion]}
                            allTopicNames={allTopicNames}
                        />;
                }
            })()}
            {(windowWidth < REMOVE_ASIDE_AT) && helpDisplay}
        </DisplayPositioner>
    </DndProvider>;
}

export default Create;