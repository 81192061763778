import {QuestionModelWithoutAnswer, Rating} from '../../types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSortDown, faSortUp} from "@fortawesome/free-solid-svg-icons";
import {patchQuestion} from "../../services/actions/question";

/**
 * Formats the number of upvotes to a string, concatinating k or m if the number is large enough,
 * returning 1dp.
 *
 * @returns the formatted string
 */
const formatVoteCount = (upvotes: number): string => {
    if (upvotes >= 1000000 || upvotes <= -1000000) {
        return `${(upvotes / 1000000).toFixed(1)}m`;
    } else if (upvotes >= 1000 || upvotes <= -1000) {
        return `${(upvotes / 1000).toFixed(1)}k`;
    } else {
        return upvotes.toString();
    }
}

const UpvoteDownvote: React.FC<{ fullQuestion: QuestionModelWithoutAnswer }> = ({ fullQuestion }) => {
    const [ratingUpvotes, setRatingUpvotes] = React.useState<{
        rating: Rating,
        upvotes: number,
    }>({
        rating: fullQuestion.rating,
        upvotes: fullQuestion.overallRating
    })

    React.useEffect(() => {
        if (ratingUpvotes.rating !== fullQuestion.rating) {
            patchQuestion(fullQuestion.id, {
                rating: ratingUpvotes.rating
            }).then(() => {
                fullQuestion.rating = ratingUpvotes.rating;
                fullQuestion.overallRating = ratingUpvotes.upvotes;
            }).catch(() => {
                setRatingUpvotes({
                    rating: fullQuestion.rating,
                    upvotes: fullQuestion.overallRating
                });
            });
        }
    }, [ratingUpvotes]);



    /**
     * Handles the vote button being clicked, and sets the state accordingly.
     * This state will then be used to render the correct ui, and send the vote state to the api.
     *
     * @param voted The vote type that was clicked
     */
    const handleRating = ((newRating: Rating) => {
        const value = (r: Rating) => ({
            'UPVOTE': 1,
            'NONE': 0,
            'DOWNVOTE': -1,
        }[r])

        setRatingUpvotes(old => {
            if (old.rating === newRating) {
                newRating = 'NONE';
            }
            return {
                rating: newRating,
                upvotes: old.upvotes + (value(newRating) - value(old.rating))
            };
        });
    });

    const QuestionVoteArrow: React.FC<React.PropsWithChildren<{ active: boolean, direction: 'up' | 'down' }>> = ({
        active,
        direction,
        children
    }) => <div className={
        `question-vote-arrow `
        + (active ? 'active ' : '')
        + direction
    }>
            {children}
        </div>


    return <div className="question-vote">
        <QuestionVoteArrow direction='up' active={ratingUpvotes.rating === 'UPVOTE'}>
            <FontAwesomeIcon 
                className="question-vote-arrow-svg" 
                icon={faSortUp} 
                onClick={() => handleRating('UPVOTE')} 
            />
        </QuestionVoteArrow>
        <div className="question-vote-count" aria-label="vote count">
            {formatVoteCount(ratingUpvotes.upvotes)}
        </div>
        <QuestionVoteArrow direction='down' active={ratingUpvotes.rating === 'DOWNVOTE'}>
            <FontAwesomeIcon 
                className="question-vote-arrow-svg" 
                icon={faSortDown} 
                onClick={() => handleRating('DOWNVOTE')} 
            />
        </QuestionVoteArrow>
    </div>;
}

export default UpvoteDownvote;
