import axios from "axios";
import {
    ApiResult,
    ErrorResponseBody,
    Full,
    GetReportQueryParams,
    ReportResponseData,
    Response,
    Result
} from "../../types";
import {requireProperties} from "../../util";
import {apiUri, getDefaultAxiosConfig, queryParamsToString} from "../axiosUtils";

/**
 * 
 * @param params Object of type GetReportParams (optional)
 * @returns if reportedId and reporteeId is specified, 
 * an array with a single report is returned otherwise
 *  all reports are returned (wrapped in a ApiResult)
 */
export async function getReports(params: GetReportQueryParams): Promise<ApiResult<Full<ReportResponseData>>> {
    const { data } = await axios.get<Response<ReportResponseData>>(
        apiUri('report', queryParamsToString(params)),
        getDefaultAxiosConfig<void>()
    );

    const result = Response.assumeSuccess(data);

    if (result.tag === 'ERR') {
        return result;
    } 

    if (!result.value) {
        return Result.err(ErrorResponseBody.notFound());
    }

    const required = requireProperties(result.value, ReportResponseData.KEYS);
    if (!required) {
        return Result.err(ErrorResponseBody.partialContent());
    }

    return Result.ok(required);
}
