import {faX} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import ModalContext from '../../context/ModalContext';
import useExternalClick from '../../hooks/useExternalClick';
import './Modal.scss';

export type ModalProps = React.PropsWithChildren<{
    hideOnExternalClick?: boolean,
    doNotHideOnClick?: React.MutableRefObject<HTMLElement | null>[],
    title?: string,
} & React.HTMLAttributes<HTMLDivElement>>;

/**
 * You will probably need some boilerplate to properly set up your modal.
 * It's not much though...  
 * 
 * The simplest modal setup is like this:
 * 
 * ```
 * const [_modalVis, setModalVis] = useModal(<>show on start</>);
 * React.useEffect(() => setModalVis(true), []);
 * ```
 * 
 * This will display a modal right away when we load our component, but never
 * hide it. This is where this component comes in: it provides some closing
 * logic and centers the modal, let's do something more complicated...
 * 
 * If we want to have it show when we click a button, we have to add some 
 * more boilerplate. We have to
 * 
 * - create a reference to the button that shows the modal
 * - indicate to the modal that it should not hide when we click this button,
 *   if we don't do this, then the modal will show when we click the button,
 *   but then hide instantly, since it also thinks this is a click outside of
 *   it! We do this by passing the button to a list of all the elements that 
 *   should not hide the modal.
 * 
 * ```
 * const showModalButtonRef = React.useRef<HTMLButtonElement | null>(null);
 * const [, setModalVis] = useModal(
 *      <Modal doNotHideOnClick={[showModalButtonRef]}>
 *      	clicked button <br/> click anywhere to close...
 *      </Modal>
 * );
 * return <button onClick={() => setModalVis(true)}>show modal</button>
 * ```
 * 
 * ## Important Note 
 * 
 * The `<Modal>` component is injected *above* your component. This means that 
 * if you keep track of state related to your modal in a wrapper component, 
 * React will not rerender your modal. In this example, we are unlikely to 
 * see `bar` as the modal text...
 * 
 * ```ts
 *  // BAD
 *  const Foo: React.FC = () => {
 *  	const [modalText, setModalText] = React.useState('foo');
 *  	const [, setModalVis] = useModal(<Modal>{modalText}</Modal>);
 *  
 *  	React.useEffect(() => setModalText('bar'), []);
 *  	React.useEffect(() => setModalVis(true));
 *  
 *  	return <></>;
 *  }
 *  
 *  // GOOD: couple state with modal
 *  const FooModal: React.FC = () => {
 *  	const [modalText, setModalText] = React.useState('foo');
 *  	
 *  	React.useEffect(() => setModalText('bar'), []);
 *  
 *  	return <Modal>{modalText}</Modal>;
 *  }
 *  
 *  const Foo: React.FC = () => {
 *  	const [, setModalVis] = useModal(<FooModal />);
 *  	
 *  	React.useEffect(() => setModalVis(true));
 *  
 *  	return <></>;
 *  }
 * ```
 * 
 * TODO: add close button accessibility
 */
const Modal: React.FC<ModalProps> = ({
    children,
    hideOnExternalClick = true,
    doNotHideOnClick = [],
    title,
    ...props
}) => {
    const { visHandles: [modalVis, setModalVis] } = React.useContext(ModalContext);

    const modalRef = useExternalClick<HTMLDivElement>(
        hideOnExternalClick
            ? () => setModalVis(false)
            : () => { },
        doNotHideOnClick
    );

    return <div
        className="modal-positioner"
        style={{
            opacity: modalVis ? '100%' : '0%',
        }}
    >
        <div className="modal-container">
            <div ref={modalRef}>
                <div className="close-modal-button-positioner">
                    <button className="close-modal-button" onClick={() => setModalVis(false)}>
                        <FontAwesomeIcon icon={faX} />
                    </button>
                </div>
                {
                    title
                        ? <div className="modal-title" >
                            <h1>{title}</h1>
                        </div>
                        : null
                }
                <div
                    className="modal"
                    variant={title ? 'has-title' : ''}
                    {...props}
                >
                    {children}
                </div>
            </div>
        </div>
    </div>;
}

export default Modal;