import React from 'react';
import {ParseSelectorSettings, parseSelectorsFactory} from './util';
import * as Yup from 'yup';
import didYouMean from 'didyoumean';

didYouMean.threshold = 0.95;

export function topicYupSchema(allTopicNames: string[]) {
    return Yup
        .string()
        .required()
        .test('topic-exists', 'topic does not exist', function (value: string) {
            if (allTopicNames.includes(value)) {
                return true;
            }

            let maybeTheyMeant = didYouMean(value, allTopicNames);
            if (!maybeTheyMeant) {
                return this.createError({
                    path: this.path,
                    message: `no topic '${value}'`,
                })
            }
            maybeTheyMeant = maybeTheyMeant instanceof Array
                ? maybeTheyMeant.slice(0, 3)
                : [maybeTheyMeant];
            maybeTheyMeant = maybeTheyMeant as string[];
            maybeTheyMeant.map(t => `'${t}'`).join(' or maybe ');

            return this.createError({
                path: this.path,
                message: `no topic '${value}'... did you mean ${maybeTheyMeant}?`
            })
        })
        .required()
}

export function titleYupSchema() {
    return Yup
        .string()
        .required('You need a title smh smh smh')
        .min(1)
        .max(25, 'Pwease meowk your title nice and concise nya ;)!')
}

export function selectorYupSchema(settings: ParseSelectorSettings) {
    const parse = parseSelectorsFactory(settings);

    return Yup
        .string()
        .test('selectors-are-valid', 'invalid selectors', function (value) {
            if (!value) {
                return true;
            }

            const selectors = parse(value);
            if (selectors.tag === 'OK') {
                return true;
            }

            return this.createError({
                path: this.path,
                message: selectors.error,
            })
        })
}

export function tagsYupSchema() {
    return selectorYupSchema({
        maxSelectorCount: 5,
        maxSelectorLength: 20,
        selectorType: 'tag',
    })
}